import axios from "@/utils/AxiosInstance.js"
import CacheHandler from "@/utils/CacheHandler";
import router from "../../router";
import moment from "moment";
import { store } from "@/store/index.js";

export const scorecardDefinitions = {
  namespaced: true,
  state: {

    scorecardsDefinitionsEditorListItemLoadingErrorStatus: false,
    scorecardsDefinitionsEditorListItemLoadingError: "",
    scorecardsDefinitionsEditorListItemLoadingStatus: "notLoading",
    scorecardsDefinitionsEditorListItem: [],

    scorecardDefinitionsListLoadingErrorStatus: false,
    scorecardDefinitionsListLoadingError: "",
    scorecardDefinitionsListLoadingStatus: "notLoading",
    scorecardDefinitionsList: [],

    scorecardDefinitionsDataLoadingErrorStatus: false,
    scorecardDefinitionsDataLoadingError: "",
    scorecardDefinitionsDataLoadingStatus: "notLoading",
    scorecardDefinitionsData: [],

    scorecardDefinitionNewIdLoadingErrorStatus: false,
    scorecardDefinitionNewIdLoadingError: "",
    scorecardDefinitionNewIdLoadingStatus: "notLoading",
    scorecardDefinitionNewId: [],

    scorecardDefinitionProcessingStatus: 0,
    updateProcessFlagsLoadingStatus: 'notLoading',

    originalCategoryInfo: {},
    originalSubcategoryInfo: {},

    scorecardsDefinitionsEditorListLoadingErrorStatus: false,
    scorecardsDefinitionsEditorListLoadingError: "",
    scorecardsDefinitionsEditorListLoadingStatus: "notLoading",
    scorecardsDefinitionsEditorList: [],

  },
  getters: {
    scorecardDefinitionsListLoadingErrorStatus: state => state.scorecardDefinitionsListLoadingErrorStatus,
    scorecardDefinitionsListLoadingError: state => state.scorecardDefinitionsListLoadingError,
    scorecardDefinitionsListLoadingStatus: state => state.scorecardDefinitionsListLoadingStatus,
    scorecardDefinitionsList: state => state.scorecardDefinitionsList,

    scorecardsDefinitionsEditorListItemLoadingErrorStatus: state => state.scorecardsDefinitionsEditorListItemLoadingErrorStatus,
    scorecardsDefinitionsEditorListItemLoadingError: state => state.scorecardsDefinitionsEditorListItemLoadingError,
    scorecardsDefinitionsEditorListItemLoadingStatus: state => state.scorecardsDefinitionsEditorListItemLoadingStatus,
    scorecardsDefinitionsEditorListItem: state => state.scorecardsDefinitionsEditorListItem,

    scorecardDefinitionsDataLoadingErrorStatus: state => state.scorecardDefinitionsDataLoadingErrorStatus,
    scorecardDefinitionsDataLoadingError: state => state.scorecardDefinitionsDataLoadingError,
    scorecardDefinitionsDataLoadingStatus: state => state.scorecardDefinitionsDataLoadingStatus,
    scorecardDefinitionsData: state => state.scorecardDefinitionsData,

    scorecardDefinitionNewIdLoadingErrorStatus: state => state.scorecardDefinitionNewIdLoadingErrorStatus,
    scorecardDefinitionNewIdLoadingError: state => state.scorecardDefinitionNewIdLoadingError,
    scorecardDefinitionNewIdLoadingStatus: state => state.scorecardDefinitionNewIdLoadingStatus,
    scorecardDefinitionNewId: state => state.scorecardDefinitionNewId,

    scorecardDefinitionProcessingStatus: state => state.scorecardDefinitionProcessingStatus,
    updateProcessFlagsLoadingStatus: state => state.updateProcessFlagsLoadingStatus,

    originalCategoryInfo: state => state.originalCategoryInfo,
    originalSubcategoryInfo: state => state.originalSubcategoryInfo,

    scorecardsDefinitionsEditorList: state => state.scorecardsDefinitionsEditorList,
    scorecardsDefinitionsEditorListLoadingStatus: state => state.scorecardsDefinitionsEditorListLoadingStatus,
    scorecardsDefinitionsEditorListLoadingError: state => state.scorecardsDefinitionsEditorListLoadingError,
    scorecardsDefinitionsEditorListLoadingErrorStatus: state => state.scorecardsDefinitionsEditorListLoadingErrorStatus,

  },
  mutations: {
    SET_ORIGINAL_CATEGORY_INFO(state, info) {
      state.originalCategoryInfo = info
    },
    SET_ORIGINAL_SUBCATEGORY_INFO(state, info) {
      state.originalSubcategoryInfo = info
    },

    SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR_STATUS(state, status) {
      state.scorecardsDefinitionsEditorListItemLoadingErrorStatus = status;
    },
    SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR(state, error) {
      state.scorecardsDefinitionsEditorListItemLoadingError = error;
    },
    SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_STATUS(state, status) {
      state.scorecardsDefinitionsEditorListItemLoadingStatus = status;
    },
    SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM(state, scorecardsDefinitionsEditorListItem) {
      state.scorecardsDefinitionsEditorListItem = scorecardsDefinitionsEditorListItem;
    },

    SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR_STATUS(state, status) {
      state.scorecardDefinitionsListLoadingErrorStatus = status;
    },
    SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR(state, error) {
      state.scorecardDefinitionsListLoadingError = error;
    },
    SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS(state, status) {
      state.scorecardDefinitionsListLoadingStatus = status;
    },
    SET_SCORECARD_DEFINITIONS_LIST(state, scorecardDefinitionsList) {
      state.scorecardDefinitionsList = scorecardDefinitionsList;
    },

    SET_SCORECARD_DEFINITION_NEW_ID_LOADING_ERROR_STATUS(state, status) {
      state.scorecardDefinitionNewIdLoadingErrorStatus = status;
    },
    SET_SCORECARD_DEFINITION_NEW_ID_LOADING_ERROR(state, error) {
      state.scorecardDefinitionNewIdLoadingError = error;
    },
    SET_SCORECARD_DEFINITION_NEW_ID_LOADING_STATUS(state, status) {
      state.scorecardDefinitionNewIdLoadingStatus = status;
    },
    SET_SCORECARD_DEFINITION_NEW_ID(state, scorecardDefinitionNewId) {
      state.scorecardDefinitionNewId = scorecardDefinitionNewId;
    },

    SET_SCORECARD_DEFINITION_PROCESSING_STATUS(state, val) {
      state.scorecardDefinitionProcessingStatus = val
    },
    SET_UPDATE_PROCESS_FLAGS_LOADING_STATUS(state, status) {
      state.updateProcessFlagsLoadingStatus = status
    },

    UPDATE_SCORECARD_DEFINITIONS_LIST_ITEM(state, scorecard) {
      for (const obj of state.scorecardDefinitionsList) {
        if (obj.id === scorecard.id) {
          obj.title = scorecard.title
          obj.description = scorecard.description
          obj.min_call_score = scorecard.min_call_score
          obj.max_call_score = scorecard.max_call_score
          obj.min_talktime_length = scorecard.min_talktime_length
          obj.max_talktime_length = scorecard.max_talktime_length
          obj.display_talktime_or_handletime = scorecard.display_talktime_or_handletime
          obj.handling_for_nonapplicable_category = scorecard.handling_for_nonapplicable_category
          obj.include_all_agents = scorecard.include_all_agents
          obj.media_type = scorecard.media_type
          obj.dynalabels = scorecard.dynalabels
          obj.accessroles = scorecard.accessroles
          obj.categories = scorecard.categories
          obj.processing = scorecard.processing
          obj.draft = scorecard.draft
          obj.published = scorecard.published
          obj.new = scorecard.new
          break;
        }
      }
    },

    SET_SCORECARD_DEFINITIONS_EDITOR_LIST(state, scorecardsDefinitionsEditorList) {
      state.scorecardsDefinitionsEditorList = scorecardsDefinitionsEditorList
    },
    SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_STATUS(state, status) {
      state.scorecardsDefinitionsEditorListLoadingStatus = status
    },
    SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_ERROR(state, error) {
      state.scorecardsDefinitionsEditorListLoadingError = error
    },
    SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_ERROR_STATUS(state, status) {
      state.scorecardsDefinitionsEditorListLoadingErrorStatus = status
    },

  },
  actions: {
    retrieveScorecardDefinitionsEditorList(context) {
      let scorecard_definitions_draft_list = []
      let scorecard_definitions_not_draft_list = []
      let scorecard_definitions_draft_originals = []
      context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "loading")
      context.commit("SET_SCORECARD_DEFINITIONS_LIST", [])

      // get local drafts
      let draft_keys = []
      let scorecard_definition = {}
      for (const property in localStorage) {
        // need to check the user id of the object in localstorage
        let userId = store.getters["users/userData"].id
        if (property.slice(0, property.lastIndexOf("_") + 1) == userId + '_scorecard_definition_') {
          let baseProp = property.substring(property.indexOf("_") + 1)
           //check to see if the key has expired
          if (CacheHandler.isExpired(baseProp)) continue;

          scorecard_definition = JSON.parse(CacheHandler.getItem(baseProp))
          scorecard_definition.draft = true
          scorecard_definitions_draft_list.push(scorecard_definition)
          draft_keys.push(Number(scorecard_definition.id))
        }
      }
      scorecard_definitions_draft_list.sort((a, b) => a.title > b.title && 1 || -1)

      axios
        .get(process.env.VUE_APP_SCORECARDS_V2 + '?scorecards_editor=true')
        .then(response => {
          let scorecardDefinitionList = response.data.scorecard_definitions
          scorecardDefinitionList = scorecardDefinitionList.map(obj => ({ ...obj, published: true, cancel: false }))
          scorecardDefinitionList.forEach((scorecard, index) => {
            scorecard.categories.forEach((category, index) => {
              category.expanded = false
              category.new = false
              category.cancel = false
              category.title_error = false
              category.sub_categories.forEach((sub_category, index) => {
                sub_category.expanded = false
                sub_category.cancel = false
                sub_category.new = false
                sub_category.searches = sub_category.searches.map(obj => ({ ...obj, new: false }))
              })

              category.dynalabels = category.dynalabels.map(obj => ({
                dynalabel_id: obj.dynalabel_id,
                new: false,
                id: obj.id,
              }));
              category.sub_categories.sort((a, b) => a.sort_order > b.sort_order && 1 || -1)
            })
            scorecard.categories.sort((a, b) => a.sort_order > b.sort_order && 1 || -1)
          });
          scorecardDefinitionList.sort((a, b) => a.title > b.title && 1 || -1)


          scorecard_definitions_not_draft_list = scorecardDefinitionList.filter(definition => !(draft_keys.includes(definition.id))).map(obj => ({
            "id": obj.id,
            "title": obj.title,
            "description": obj.description,
            "user_id": obj.user_id,
            "min_call_score": obj.min_call_score,
            "max_call_score": obj.max_call_score,
            "min_talktime_length": obj.min_talktime_length,
            "max_talktime_length": obj.max_talktime_length,
            "display_talktime_or_handletime": obj.display_talktime_or_handletime,
            "handling_for_nonapplicable_category": obj.handling_for_nonapplicable_category,
            "include_all_agents": obj.include_all_agents,
            "media_type": obj.media_type,
            "dynalabels": obj.dynalabels,
            "accessroles": obj.accessroles,
            "categories": obj.categories,
            "processing": obj.processing,
            "created_at": obj.created_at,
            "updated_at": obj.updated_at,
            "draft": false,
            "published": true,
            "new": false,
            "cancel": false
          }))

          scorecard_definitions_draft_originals = scorecardDefinitionList.filter(definition => (draft_keys.includes(definition.id))).map(obj => ({
            "id": obj.id,
            "title": obj.title,
            "description": obj.description,
            "user_id": obj.user_id,
            "min_call_score": obj.min_call_score,
            "max_call_score": obj.max_call_score,
            "min_talktime_length": obj.min_talktime_length,
            "max_talktime_length": obj.max_talktime_length,
            "display_talktime_or_handletime": obj.display_talktime_or_handletime,
            "handling_for_nonapplicable_category": obj.handling_for_nonapplicable_category,
            "include_all_agents": obj.include_all_agents,
            "media_type": obj.media_type,
            "dynalabels": obj.dynalabels,
            "accessroles": obj.accessroles,
            "categories": obj.categories,
            "processing": obj.processing,
            "created_at": obj.created_at,
            "updated_at": obj.updated_at,
            "draft": false,
            "published": true,
            "new": false,
            "cancel": false
          }))


          scorecard_definitions_draft_list.sort(function(a,b) {
            if(a.id > b.id) return 1
            if(a.id < b.id) return -1
          })
          scorecard_definitions_draft_originals.sort(function(a,b) {
            if(a.id > b.id) return 1
            if(a.id < b.id) return -1
          })

          scorecard_definitions_draft_list.forEach((def,index) => {
            if(!def.new){
              let def_orig = scorecard_definitions_draft_originals[index]
              let scorecardDefinitionInfoClone = JSON.parse(JSON.stringify(def))
              let scorecardDefinitionInfoOrigClone = JSON.parse(JSON.stringify(def_orig))
              // function to check if the only difference between the two is that the category was expanded. if so NO DRAFT
              scorecardDefinitionInfoClone.draft = false

              scorecardDefinitionInfoClone.categories.forEach(object => {
                delete object['expanded'];
                object.sub_categories.forEach(sub_object => {
                  delete sub_object['expanded'];
                  delete sub_object['cancel'];
                })
              });
              scorecardDefinitionInfoOrigClone.categories.forEach(object => {
                delete object['expanded'];
                object.sub_categories.forEach(sub_object => {
                  delete sub_object['expanded'];
                  delete sub_object['cancel'];
                })
              });
              if(JSON.stringify(scorecardDefinitionInfoClone) === JSON.stringify(scorecardDefinitionInfoOrigClone)) {
                def.draft = false
              }
            }
          })

          let final_list = scorecard_definitions_draft_list.concat(scorecard_definitions_not_draft_list)
          final_list.sort((a, b) => a.title > b.title && 1 || -1)


          context.commit("SET_SCORECARD_DEFINITIONS_LIST", final_list)
          context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR_STATUS", false)
          context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR", "")
          context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "loaded")

          // need to know which scorecard definitions are not drafts
          context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST", scorecardDefinitionList)
          context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_ERROR_STATUS", false)
          context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_ERROR", "")
          context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_STATUS", "loaded")
        })
        .catch(function (error) {

          context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_DEFINITIONS_LIST", []);
          context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);

          context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST", [])
          context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_ERROR_STATUS", true)
          context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_STATUS", "failed")

          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR", 'retrieveScorecardsEditorList(): ' + error_message)
          context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_LOADING_ERROR", 'retrieveScorecardsEditorList(): ' + error_message)

        })
    },

    updateScorecardLocalStorage(context, params) {

      let definition = params.definition
      let draftFlag = params.draftFlag
      let scorecardId = String(definition.id)

      //in case the original key exists without a timestamp...
      CacheHandler.removeKeyIfExists('scorecard_definition_' + scorecardId)

      // new local storage key...
      let key = 'scorecard_definition_' + scorecardId + '.' + moment().unix()
      let oldKey = CacheHandler.setCacheKeyFromStore(scorecardId, 'scorecard_definition_');

      // set it to a draft copy
      definition.draft = draftFlag

      //remove it
      CacheHandler.removeKeyIfExists(oldKey)
     
      //  save it
      CacheHandler.setItem(key, JSON.stringify(definition))

      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM", definition);
      context.commit("UPDATE_SCORECARD_DEFINITIONS_LIST_ITEM", definition);

    },
    updateScorecardCategoryStorage(context, info) {

      //in case the original key exists without a timestamp...
      CacheHandler.removeKeyIfExists('scorecard_categories_' + String(info.id))

      let key = 'scorecard_categories_' + String(info.id) + '.' + moment().unix()
      let oldKey = CacheHandler.setCacheKeyFromStore(info.id, 'scorecard_categories_');

      // set it to a draft copy/remove it
      CacheHandler.removeKeyIfExists(oldKey)

      //  save it
      CacheHandler.setItem(key, JSON.stringify(info.categories))

    },
    setOriginalCategoryInfo(context, info) {
      let category = {}
      for (const property in localStorage) {
        let userId = store.getters["users/userData"].id
        if (property.slice(0, property.lastIndexOf("_") + 1) == userId + '_scorecard_categories_' &&  property.slice(property.lastIndexOf("_") + 1) == info.scorecard_id) {
          let baseProp = property.substring(property.indexOf("_") + 1)
          let categories = JSON.parse(CacheHandler.getItem(baseProp))
          categories.forEach((cat, index) => {
            if (cat.id == info.category_id) {
              category = cat
            }
          })
        }
      }
      context.commit("SET_ORIGINAL_CATEGORY_INFO", category);
    },
    setOriginalSubcategoryInfo(context, info) {
      let subcategory = {}
      for (const property in localStorage) {
        let userId = store.getters["users/userData"].id
        if (property.slice(0, property.lastIndexOf("_") + 1) == userId + '_scorecard_categories_' &&  property.slice(property.lastIndexOf("_") + 1) == info.scorecard_id) {
          let baseProp = property.substring(property.indexOf("_") + 1)
          let categories = JSON.parse(CacheHandler.getItem(baseProp))
          categories.forEach((cat, index) => {
            cat.sub_categories.forEach((subcat, index) => {
              if (subcat.id == info.subcategory_id) {
                subcategory = subcat
              }
            })
          })
        }
      }
      context.commit("SET_ORIGINAL_SUBCATEGORY_INFO", subcategory);
    },
    updateScorecardProcessingFlags(context) {
      context.commit("SET_UPDATE_PROCESS_FLAGS_LOADING_STATUS", 'loading');
      if (context.getters.scorecardDefinitionsListLoadingStatus != 'loading') {
      let currentId = null
      let localList = Object.assign([], context.getters.scorecardDefinitionsList)
      let serviceList = []
      let scorecardDefinition = context.getters.scorecardsDefinitionsEditorListItem
      if (scorecardDefinition.hasOwnProperty('id')) currentId = scorecardDefinition.id
      axios
        .get(process.env.VUE_APP_SCORECARDS_V2 + '?scorecards_editor=true')
        .then(response => {
          serviceList = response.data.scorecard_definitions
          localList.forEach((item, index) => {
            let sd = serviceList.find(obj => { return obj.id == item.id })
            if (sd && sd.hasOwnProperty('processing')) {
               for (const property in localStorage) {
                // need to check the user id of the object in localstorage
                let userId = store.getters["users/userData"].id
                if (property.slice(0, property.lastIndexOf("_") + 1) == userId + '_scorecard_definition_') {
                  let baseProp = property.substring(property.indexOf("_") + 1)
                  let scorecard_definition = JSON.parse(CacheHandler.getItem(baseProp))
                  if (sd.id == scorecard_definition.id) {
                    scorecard_definition.processing = sd.processing

                    // remove old timestamped key
                    CacheHandler.removeKeyIfExists(baseProp)

                    // new local storage key with timestamp...
                    CacheHandler.setItem('scorecard_definition_' + sd.id + '.' + moment().unix(), JSON.stringify(scorecard_definition))
                  }
                }
              }
              localList[index].processing = sd.processing
              if (item.id == currentId) {
                context.commit("SET_SCORECARD_DEFINITION_PROCESSING_STATUS", sd.processing);
              }
            }
          })
          context.commit("SET_SCORECARD_DEFINITIONS_LIST", localList)
          context.commit("SET_UPDATE_PROCESS_FLAGS_LOADING_STATUS", 'loaded');
        })
        .catch(function (error) {
          context.commit("SET_UPDATE_PROCESS_FLAGS_LOADING_STATUS", 'failed');
         });
        }
    },
    setScorecardDefinitionProcessingStatus(context, val) {
      context.commit("SET_SCORECARD_DEFINITION_PROCESSING_STATUS", val);
    },
    retrieveScorecardEditorListItem(context, scorecard_id) {

      let key = CacheHandler.setCacheKeyFromStore(scorecard_id, 'scorecard_definition_')
      //check original key so we do not ruin current drafts a user might have...
      if (CacheHandler.getItem("scorecard_definition_" + scorecard_id) !== null) {
        let scorecard_definition = JSON.parse(CacheHandler.getItem("scorecard_definition_" + scorecard_id))
        context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM", scorecard_definition);
        context.commit("SET_SCORECARD_DEFINITION_PROCESSING_STATUS", scorecard_definition.processing)
        context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_STATUS", "loaded");
        context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR", '');
        context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR_STATUS", false);
        //check new drafts with a timestamp on the key
      } else if (key != null && CacheHandler.getItem(key) !== null) {
        let scorecard_definition = JSON.parse(CacheHandler.getItem(key))
        context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM", scorecard_definition);
        context.commit("SET_SCORECARD_DEFINITION_PROCESSING_STATUS", scorecard_definition.processing)
        context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_STATUS", "loaded");
        context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR", '');
        context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR_STATUS", false);
      } else {
        if (context.getters.scorecardsDefinitionsEditorListItem.published == undefined || context.getters.scorecardsDefinitionsEditorListItem.published != false) {
          axios
            .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + scorecard_id + '?scorecards_editor=true')
            .then(response => {
              context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM", response.data.scorecard_definitions);
              context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR_STATUS", false);
              context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR", "");
              context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_STATUS", "loaded");
            })
            .catch(function (error) {
              context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_STATUS", "failed");
              context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM", {});
              context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR_STATUS", true);
              let error_message = JSON.stringify(error)
              if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
              if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
              context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM", 'retrieveScorecardEditorListItem(): ' + error_message)
            });
        }
      }
    },
    setScorecardListLoadingStatus(context, status) {
      context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", status)
      if (status=='loading') {
        context.commit("SET_SCORECARD_DEFINITIONS_LIST", [])
      }
    },
    reSortCategories(context, params) {
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecard = scorecardDefinitionsList.find(x => x.id == params.scorecard_id)
      let categories = scorecard.categories
      let category = scorecard.categories.find(x => x.id == params.item_id)
      //original index of item moved
      let index = null
      index = categories.findIndex(object => {
        return object.id == params.item_id;
      });
      categories.sort((a, b) => a.sort_order > b.sort_order && 1 || -1)
      let itemOrder = category.sort_order

      // normalize value after category has been dropped
      if (params.moved_down) {
        for (let j = 0; j < categories.length && j <= index; j++) {
          //foreach item in between the cat index and the new sort order --
          if (category.id != categories[j].id && categories[j].sort_order > itemOrder) {
            categories[j].sort_order++
          }
        }
      } else {
        for (let x = index; x < categories.length; x++) {
          //foreach item after the index and less than the new sort order ++
          if (category.id != categories[x].id && categories[x].sort_order < itemOrder) {
            categories[x].sort_order--
          }
        }
      }

      //check if category was moved down or up
      if (params.moved_down) {
        category.sort_order = Math.round(category.sort_order)
      } else {
        category.sort_order = Math.floor(category.sort_order)
      }

      // recalculate sort order values to clear sort order
      for (let k = 0; k < categories.length; k++) {
        //foreach item in between the cat index and the new sort order --
          categories[k].sort_order = k + 1
      }

      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);

      //in case the original key still exists...
      CacheHandler.removeKeyIfExists('scorecard_categories_' + String(params.scorecard_id))

      // Update separate categories local storage object
      let key = 'scorecard_categories_' + String(params.scorecard_id) + '.' + moment().unix()
      let oldKey = CacheHandler.setCacheKeyFromStore(params.scorecard_id, 'scorecard_categories_');

      // remove the draft copy, if it exists
      CacheHandler.removeKeyIfExists(oldKey)
     
      // now save it
      CacheHandler.setItem(key, JSON.stringify(categories))

    },
    reSortSubCategories(context, params) {
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecard = scorecardDefinitionsList.find(x => x.id == params.scorecard_id)
      let category = scorecard.categories.find(x => x.id == params.category_id)
      let sub_categories = category.sub_categories
      let sub_category = sub_categories.find(x => x.id == params.item_id)
      //original index of item moved
      let index = null
      index = sub_categories.findIndex(object => {
        return object.id == params.item_id;
      });
      category.sub_categories.sort((a, b) => a.sort_order > b.sort_order && 1 || -1)
      let itemOrder = sub_category.sort_order

      // normalize value after category has been dropped
      if (params.moved_down) {
        for (let j = 0; j < sub_categories.length && j <= index; j++) {
          //foreach item in between the cat index and the new sort order --
          if (sub_category.id != sub_categories[j].id && sub_categories[j].sort_order > itemOrder) {
            sub_categories[j].sort_order++
          }
        }
      } else {
        for (let x = index; x < sub_categories.length; x++) {
          //foreach item after the index and less than the new sort order ++
          if (sub_category.id != sub_categories[x].id && sub_categories[x].sort_order < itemOrder) {
            sub_categories[x].sort_order--
          }
        }
      }

      //check if category was moved down or up
      if (params.moved_down) {
        sub_category.sort_order = Math.round(sub_category.sort_order)
      } else {
        sub_category.sort_order = Math.floor(sub_category.sort_order)
      }
      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);
    },
    createNewSubCategoryForCategory(context, params) {
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecard = scorecardDefinitionsList.find(x => x.id == params.scorecard_id)
      let category = scorecard.categories.find(x => x.id == params.category_id)
      let newLabelCount = category.sub_categories.filter(label => label.sub_category_title.slice(0, 15) == "New Subcategory").length
      let titleSuffix = (newLabelCount > 0) ? " (copy " + (newLabelCount + 1) + ")" : ""
      let did = Date.now()

      let ISO = (timeStamp = did) => { return new Date(timeStamp - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T') }
      let dt = ISO()
      let sortOrder = 1
      for (let i = 0; i < category.sub_categories.length; i++) {
        sortOrder = category.sub_categories[i].sort_order
      }
      sortOrder++;

      let sub_category = {
        id: did,
        searches: [],
        scoring_type: params.scoring_type,
        sub_category_title: "New Subcategory " + titleSuffix,
        category_id: category.id,
        weight: 1,
        sort_order: sortOrder,
        cancel: true,
        expanded: false,
        new: true,
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }

      category.sub_categories.push(sub_category)
      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);

    },
    removeNewSubcategory(context, params) {
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecard = scorecardDefinitionsList.find(x => x.id == params.scorecard_id)
      let category = scorecard.categories.find(x => x.id == params.category_id)
      let sub_category = category.sub_categories.find(x => x.id == params.subcategory_id)
      let index = category.sub_categories.findIndex(object => {
        return object.id == params.subcategory_id;
      });

      if (index > -1) {
        category.sub_categories.splice(index, 1)
        for (let j = index; j < category.sub_categories.length; j++) {
          //foreach item in between the cat index and the new sort order --
          if (sub_category.id != category.sub_categories[j].id) {
            category.sub_categories[j].sort_order--
          }
        }
      }
      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);
    },
    removeNewCategory(context, params) {
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecard = scorecardDefinitionsList.find(x => x.id == params.scorecard_id)
      let category = scorecard.categories.find(x => x.id == params.category_id)
      let index = scorecard.categories.findIndex(object => {
        return object.id == params.category_id;
      });
      if (index > -1) {
        scorecard.categories.splice(index, 1)
        for (let j = index; j < scorecard.categories.length; j++) {
          //foreach item in between the cat index and the new sort order --
          if (category.id != scorecard.categories[j].id) {
            scorecard.categories[j].sort_order--
          }
        }
      }

      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);
    },
    createNewCategoryForScorecard(context, params) {
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecard = scorecardDefinitionsList.find(x => x.id == params.scorecard_id)
      let sortOrder = 1
      if (params.sort_order != null) {
        //set sortOrder where category was added
        sortOrder = params.sort_order + 1
        for (let i = 0; i < scorecard.categories.length; i++) {
          //increment the sort orders >= the new category sort order
          if (scorecard.categories[i].sort_order >= sortOrder) {
            scorecard.categories[i].sort_order++
          }
        }
      } else {
        //if for some reason the sort_order is not set, add the category last...
        for (let i = 0; i < scorecard.categories.length; i++) {
          sortOrder = scorecard.categories[i].sort_order
        }
        sortOrder = scorecard.categories[scorecard.categories.length - 1].sort_order++
      }
      let newLabelCount = scorecard.categories.filter(label => label.category_title.slice(0, 12) == "New Category").length
      let titleSuffix = (newLabelCount > 0) ? " (copy " + (newLabelCount + 1) + ")" : ""
      let did = Date.now()
      let sid = Date.now()
      let ISO = (timeStamp = did) => { return new Date(timeStamp - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T') }
      let dt = ISO()
      let sub_category = {
        id: sid,
        expanded: false,
        cancel: false,
        new: true,
        searches: [],
        sort_order: 1,
        scoring_type: 0,
        sub_category_title: "New Subcategory",
        weight: 1,
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }
      let category = {
        id: did,
        expanded: false,
        new: true,
        dynalabels: [],
        sub_categories: [sub_category],
        threshold: 0,
        sort_order: sortOrder,
        manual_scoring: params.default_manual ? "1" : "0",
        manual_scoring_choice: 0,
        threshold_count: null,
        cancel: true,
        weight: 1,
        scoring_rule: 0,
        threshold_passfail: 0,
        title_error: false,
        category_title: "New Category " + titleSuffix,
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }
      sub_category.category_id = category.id
      let indexOfObject = scorecard.categories.findIndex(object => {
        return object.id == params.category_id;
      });

      scorecard.categories.splice(indexOfObject + 1, 0, category)
      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);
    },
    deleteSubCategory(context, params) {
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecard = scorecardDefinitionsList.find(x => x.id == params.scorecard_id)
      let category = scorecard.categories.find(x => x.id == params.category_id)
      let sub_categories = category.sub_categories
      let sub_category = sub_categories.find(x => x.id == params.sub_category_id)
      let indexOfObject = category.sub_categories.findIndex(object => {
        return object.id == params.sub_category_id;
      });

      if (indexOfObject > -1) {
        sub_categories.splice(indexOfObject, 1);
        for (let j = indexOfObject; j < sub_categories.length; j++) {
          //foreach item in between the cat index and the new sort order --
          if (sub_category.id != sub_categories[j].id) {
            sub_categories[j].sort_order--
          }
        }
      }

      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList)
    },
    deleteCategory(context, params) {
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecard = scorecardDefinitionsList.find(x => x.id == params.scorecard_id)
      let category = scorecard.categories.find(x => x.id == params.category_id)
      let categories = scorecard.categories
      let indexOfObject = categories.findIndex(object => {
        return object.id == params.category_id;
      });

      if (indexOfObject > -1) {
        categories.splice(indexOfObject, 1);
        for (let j = indexOfObject; j < scorecard.categories.length; j++) {
          //foreach item in between the cat index and the new sort order --
          if (category.id != categories[j].id) {
            categories[j].sort_order--
          }
        }
      }

      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList)
    },
    createNewScorecardDefinition(context, params) {

      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      //gets the root state of the search data which is loaded when the setup tab is loaded
      let searchDataLength = context.rootState.resources.searchData.length
      let newLabelCount = scorecardDefinitionsList.filter(label => label.title.slice(0, 24) == "New Scorecard Definition").length
      let titleSuffix = (newLabelCount > 0) ? " (copy " + (newLabelCount + 1) + ")" : ""
      let did = Date.now()
      let sid = Date.now()
      let ISO = (timeStamp = did) => { return new Date(timeStamp - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T') }
      let dt = ISO()
      let key = 'scorecard_definition_' + params.id + '.' + moment().unix();

      let sub_category = {
        id: sid,
        expanded: false,
        searches: [],
        sub_category_title: "New Subcategory",
        scoring_type: (searchDataLength < 1 ) ? 1 : 0,
        sort_order: 1,
        new: true,
        weight: 1,
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }
      let category = {
        id: did,
        expanded: false,
        dynalabels: [],
        sub_categories: [sub_category],
        threshold: 0,
        sort_order: 1,
        manual_scoring: (searchDataLength < 1 ) ? "1" : "0",
        manual_scoring_choice: "0",
        threshold_count: null,
        new: true,
        weight: 1,
        scoring_rule: 0,
        threshold_passfail: 0,
        category_title: "New Category",
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }

      sub_category.category_id = category.id
      let scorecardDefinition = {
        id: params.id,
        accessroles: [],
        dynalabels: [],
        categories: [category],
        user_id: params.user_id,
        published: false,
        cancel: true,
        new: true,
        draft: true,
        processing: false,
        title: "New Scorecard Definition" + titleSuffix,
        description: "Description",
        min_talktime_length: null,
        max_talktime_length: null,
        min_call_score: null,
        max_call_score: null,
        display_talktime_or_handletime: 0,
        handling_for_nonapplicable_category: 0,
        include_all_agents: 1,
        media_type: 'Call Recording',
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],

      }

      scorecardDefinitionsList.push(scorecardDefinition)
      scorecardDefinitionsList.sort((a, b) => a.title > b.title && 1 || -1)
      CacheHandler.setItem(key, JSON.stringify(scorecardDefinition))
      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);
      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM", scorecardDefinition);
      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR_STATUS", false);
      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR", "");
      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_STATUS", "loaded");

    },
    cancelScorecardDefinitionEdits(context, scorecardId) {

      //in case the original key still exists...
      CacheHandler.removeKeyIfExists('scorecard_definition_' + String(scorecardId))
      CacheHandler.removeKeyIfExists('scorecard_categories_' + String(scorecardId))

      // get a reference to the draft
      let key = CacheHandler.setCacheKeyFromStore(scorecardId, 'scorecard_definition_')
      let cat_key = CacheHandler.setCacheKeyFromStore(scorecardId, 'scorecard_categories_');

      // remove the draft (scorecard and categories)
      CacheHandler.removeKeyIfExists(key)
      CacheHandler.removeKeyIfExists(cat_key)
   
      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let index = scorecardDefinitionsList.findIndex(object => {
        return object.id == scorecardId;
      });

      if (index > -1) {
        scorecardDefinitionsList.splice(index, 1)
        context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);
      }

    },
    deleteScorecardDefinition(context, scorecard_definition_id) {

      context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "loading")

      //in case the original key still exists...
      CacheHandler.removeKeyIfExists('scorecard_definition_' + String(scorecard_definition_id))
      CacheHandler.removeKeyIfExists('scorecard_categories_' + String(scorecard_definition_id))

      // set up some variables - assume this scorecard is published
      let key = CacheHandler.setCacheKeyFromStore(scorecard_definition_id, 'scorecard_definition_')
      let cat_key = CacheHandler.setCacheKeyFromStore(scorecard_definition_id, 'scorecard_categories_');

      // delete the object from localStorage if it exists
      CacheHandler.removeKeyIfExists(key)
      CacheHandler.removeKeyIfExists(cat_key)

      axios
        .delete(process.env.VUE_APP_SCORECARDS_V2 + '/' + scorecard_definition_id)
        .then(() => {
          context.dispatch('retrieveScorecardDefinitionsEditorList')
        })
        .catch(function (error) {
          context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_DEFINITIONS_LIST", []);
          context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_SCORECARD_DEFINITIONS_LIST", 'deleteScorecardDefinition(): ' + error_message)
        });
    },
    publishScorecardDefinition(context, params) {

      // check if published is false

      let key = CacheHandler.setCacheKeyFromStore(params.id, 'scorecard_definition_')
      let originalKey = 'scorecard_definition_' + String(params.id)

      if (!params.published) {
        axios
          .post(process.env.VUE_APP_SCORECARDS_V2, params)
          .then(response => {
            if (response !== undefined) {
              if (response.status >= 400) {
                alert('The new scorecard definition was not saved. The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
              } else {
                let scorecardsDefinitionsList = context.getters.scorecardDefinitionsList
                for (let i = 0; i < scorecardsDefinitionsList.length; i++) {
                  if (!scorecardsDefinitionsList[i].published) {
                    scorecardsDefinitionsList[i].published = true
                  }
                }
                CacheHandler.removeKeyIfExists(key)
                CacheHandler.removeKeyIfExists(originalKey)
                
                context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardsDefinitionsList)

                context.commit("SET_SCORECARD_DEFINITION_NEW_ID", response.data.definition_id)
                context.commit("SET_SCORECARD_DEFINITION_NEW_ID_LOADING_ERROR_STATUS", false)
                context.commit("SET_SCORECARD_DEFINITION_NEW_ID_LOADING_ERROR", "")
                context.commit("SET_SCORECARD_DEFINITION_NEW_ID_LOADING_STATUS", "loaded")

                context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "loading")
                context.dispatch('retrieveScorecardDefinitionsEditorList')
              }
            }
          }).catch(function (error) {
            context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "failed");
            context.commit("SET_SCORECARD_DEFINITION_NEW_ID_LOADING_STATUS", "failed");
            context.commit("SET_SCORECARD_DEFINITION_NEW_ID", {});
            context.commit("SET_SCORECARD_DEFINITION_NEW_ID_LOADING_ERROR_STATUS", true);
            let error_message = JSON.stringify(error)
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`

            //remove draft state - scorecard wont save
            CacheHandler.removeKeyIfExists(key)
            CacheHandler.removeKeyIfExists(originalKey)

            if (error.response.status==409) {
              window.location.href = "/conflicterror"
            } else {
              window.location.href = "/servererror"
            }
          });
      } else {
        axios
          .put(process.env.VUE_APP_SCORECARDS_V2 + '/' + params.id, params)
          .then((response) => {
              context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "loading")
              CacheHandler.removeKeyIfExists(key)
              CacheHandler.removeKeyIfExists(originalKey)
              context.dispatch('retrieveScorecardDefinitionsEditorList')
          }).catch(function (error) {
            context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_STATUS", "failed");
            context.commit("SET_SCORECARD_DEFINITIONS_LIST", []);
            context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);

            let error_message = JSON.stringify(error)
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
            context.commit("SET_SCORECARD_DEFINITIONS_LIST_LOADING_ERROR", 'publishScorecardDefinition(): ' + error_message)
            //remove draft state - scorecard wont save
            CacheHandler.removeKeyIfExists(key)
            CacheHandler.removeKeyIfExists(originalKey)
            if (error.response.status==409) {
              window.location.href = "/conflicterror"
            } else {
              window.location.href = "/servererror"
            }
          });
      }
    },

    cloneScorecardDefinition(context, params) {

      let scorecardDefinitionsList = context.getters.scorecardDefinitionsList
      let scorecardDefinition = Object.assign({}, params.definition)
      let scorecardTitle =  scorecardDefinition.title

      //get titles from definition list - taken from backend
      let titles = []
      scorecardDefinitionsList.forEach((scorecard, index) => {
        titles.push(scorecard.title)
      })

      for (let idx = 1; idx <= 100; idx++) {
        // figure out clone number
        scorecardTitle = scorecardTitle + ' - clone' + (idx == 1 ? '' : ' ' + idx);
        if (!titles.includes(scorecardTitle)) {
            break;
        }
      }

      scorecardDefinition.id = params.id
      scorecardDefinition.new = true
      scorecardDefinition.published = false
      scorecardDefinition.cancel = true
      scorecardDefinition.draft = true
      scorecardDefinition.title = scorecardTitle
      scorecardDefinition.created_at = moment().format('YYYY-MM-D hh:mm:ss')
      scorecardDefinition.updated_at = moment().format('YYYY-MM-D hh:mm:ss')
      scorecardDefinition.processing = 0

      scorecardDefinitionsList.push(scorecardDefinition)
      scorecardDefinitionsList.sort((a, b) => a.title > b.title && 1 || -1)
      CacheHandler.setItem('scorecard_definition_' + params.id + '.' + moment().unix(), JSON.stringify(scorecardDefinition))
      context.commit("SET_SCORECARD_DEFINITIONS_LIST", scorecardDefinitionsList);
      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM", scorecardDefinition);
      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR_STATUS", false);
      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_ERROR", "");
      context.commit("SET_SCORECARD_DEFINITIONS_EDITOR_LIST_ITEM_LOADING_STATUS", "loaded");

    },
  }
}
