<template>
  <div>
    <v-row>
      <v-col cols="12">
        <fieldset>
          <legend>
            <setup-tip :section="'coaching_scorecards'"> </setup-tip> Scorecard
            Selections
          </legend>
          <v-data-table
            id="scorecardSelectionsDataTbl"
            :loading="tablesLoading"
            loading-text="Loading... Please wait"
            v-model="scorecardsSelected"
            :headers="scorecardHeaders"
            :items="selectableScorecards"
            :search="scorecardSearch"
            :hide-default-footer="true"
            @item-selected="selectItem"
            item-key="id"
            show-select
            disable-pagination
            class="dl-dt"
            dense
            height="25vh"
          >
            <template v-slot:[`header.data-table-select`]
              >Selected</template
            >
            <template v-slot:top>
              <v-text-field
                v-model="scorecardSearch"
                label="Find Scorecards..."
                class="mx-4"
                single-line
                hide-details
                clearable
                append-icon="mdi-magnify"
              ></v-text-field>
            </template>
            <template
              v-slot:item.data-table-select="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                :disabled="checkBoxDisabled(item)"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </fieldset>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SetupTip from "@/components/SetupTip.vue";

const SELECTION_LIMIT = 5;

export default {
  name: "insight-definition",
  computed: {},
  components: {
    SetupTip
  },
  data() {
    return {
      scorecardsSelected: [],
      selectableScorecards: [],
      scorecardSelections: [],
      myScorecards: [],
      scorecardSearch: "",
      scorecardHeaders: [
        {
          text: "Scorecards",
          align: "start",
          sortable: false,
          value: "title"
        }
      ]
    };
  },
  props: {
    coachingDefinitionId: String,
    cancelEdits: Boolean,
    selectedScorecards: Array,
    mediaType: String
  },
  computed: {
    tablesLoading() {
      return (
        this.coachingDefinitionsListItemLoadingStatus == "loading" ||
        this.coachingDefinitionsListLoadingStatus == "loading" ||
        this.scorecardsDefinitionsEditorListLoadingStatus == "loading"
      );
    },
    ...mapGetters("scorecardDefinitions", [
      "scorecardsDefinitionsEditorList",
      "scorecardsDefinitionsEditorListLoadingStatus"
    ]),
    ...mapGetters("coachingDefinitions", [
      "coachingDefinitionsList",
      "coachingDefinitionsListLoadingStatus",
      "coachingDefinitionsListLoadingError",
      "coachingDefinitionsListErrorStatus",

      "coachingDefinitionsListItem",
      "coachingDefinitionsListItemLoadingStatus",
      "coachingDefinitionsListItemLoadingError",
      "coachingDefinitionsListItemErrorStatus"
    ])
  },
  methods: {
    selectItem(value) {
      this.$emit("updateInfo");
    },
    setInfo() {
      this.$emit("setInfo");
    },
    updateScorecards() {
      this.$emit("updateScorecardSelections", this.scorecardsSelected);
    },
    resetInfo() {
      this.scorecardsSelected = [];
      this.loadScorecardSelections();
      this.$emit("updatesCancelled", false);
    },
    checkBoxDisabled(listItem) {
      return (
        this.scorecardsSelected.length >= SELECTION_LIMIT &&
        !this.scorecardsSelected.find(scorecard => scorecard.id == listItem.id)
      );
    },
    getSortedScorecards(options, selections) {
      let selected = options
        .filter(d => selections.some(s => d.id == s.scorecard_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      let unselected = options
        .filter(d => !selections.some(s => d.id == s.scorecard_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      return Array(...selected, ...unselected);
    },
    loadScorecardSelections() {
      let scorecardOptions = [];
      this.myScorecards = this.selectedScorecards;

      scorecardOptions = this.scorecardsDefinitionsEditorList.map(obj => {
        return {
          id: obj.id,
          title: obj.title + " (" + obj.id + ")",
          description: obj.description,
          media_type: obj.media_type
        };
      });

      //filter on media type
      scorecardOptions = scorecardOptions.filter(
        d => d.media_type == this.mediaType
      );

      this.selectableScorecards = this.getSortedScorecards(
        scorecardOptions,
        this.selectedScorecards
      );
      this.scorecardsSelected = this.selectableScorecards.filter(d =>
        this.myScorecards.some(s => d.id == s.scorecard_id)
      );
    }
  },
  mounted() {
    this.loadScorecardSelections();
  },
  watch: {
    coachingDefinitionsListItem: "setInfo",
    cancelEdits: "resetInfo",
    scorecardsSelected: "updateScorecards",
    coachingDefinitionId: "loadScorecardSelections",
    scorecardsDefinitionsEditorListLoadingStatus: "loadScorecardSelections",
    mediaType: "loadScorecardSelections"
  }
};
</script>

<style scoped>
.vue__time-picker {
  border: 1px solid rgb(220, 222, 224);
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

fieldset {
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
  -webkit-border-radius: 0.2rem;
  border: 1px solid #c2c2c2;
  padding: 0.4rem;
}

legend {
  margin-left: 0.4rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-size: 0.8rem;
  color: #bababa;
}

.input-error {
  border-color: red;
}

.input-invalid {
  color: red;
  font-size: 9px;
}
</style>
