<template>
  <div>
    <v-icon @click="openDialog" :class="getClasses" :title="getTitle" v-if="displaySoup"
      >mdi-folder-information-outline</v-icon
    >
    <v-icon @click="openDialog" :class="getClasses" :title="getTitle" v-else
      ></v-icon
    >
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>Custom Metadata</v-col>
            <v-col align="right"
              ><span class="dim">(Call ID: {{ callId }})</span></v-col
            >
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-layout column class="tab-height">
            <v-flex style="overflow: auto">
              <v-data-table
                :search="search"
                :headers="dynaLabelHeaders"
                :items="dynalabelInfo"
                :items-per-page="dynalabelInfo.length"
                :hide-default-footer="true"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template v-slot:[`item.value`]="{ item }">
                  <a v-if="regexUrlCheck(item.value)" target="_blank" :href="checkValueProtocol(item.value)">{{ item.value }}</a>
                  <span v-else>{{ item.value }}</span>
                </template>
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Search"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark color="primary" @click="show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/utils/AxiosInstance.js";
import moment from "moment";
import regexChecks from "@/mixins/regexChecks";

const EXCLUDED_DYNA_FIELDS = [
  "Customer",
  "Acctnmbr",
  "Agent Id",
  "Adjusteddatetime",
  "Cust Id",
  "Call Id",
  "Average Talk Time",
  "Talk Time",
  "Client Call Identifier",
];

const DESIGNATED_TIME_FIELDS = [
  "Hold Time",
  "Handle Time External"
];

export default {
  mixins: [regexChecks],
  props: {
    callId: {
      required: false,
      default: null
    },
    selfSignedTranscript: {
      type: Boolean,
      required: false,
      default: false,
    },
    displaySoup: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    search: "",
    tab: 0,
    show: false,
    cdrExtras: [],
    dynalabelInfo: [],
    sortBy: "var_text",
    sortDesc: false,
    disabledDefault: true,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "var_text"
      },
      {
        text: "Value",
        align: "start",
        sortable: false,
        value: "var_value"
      }
    ],

    dynaLabelHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "key"
      },
      {
        text: "Value",
        align: "start",
        sortable: false,
        value: "value"
      }
    ]
  }),
  computed: {
    disabled() {
      let disabled = (this.cdrExtras.length === 0 && this.dynalabelInfo.length === 0)
      this.disabledDefault = disabled
      return disabled;
    },
    cdrExtrasExist() {
      if (this.cdrExtras.length < 1) {
        this.tab = 1;
      }
      return this.cdrExtras.length > 0;
    },
    getClasses: function() {
      // if in the insights call log, don't float right
      let cls =
        this.$route.name == "insights-agent-calls" ||
        this.$route.name == "insights-team-calls"
          ? ""
          : "float-right";
      if (this.disabled) {
        cls += " no-metadata";
      } else {
        cls += " view-metadata";
      }
      return cls;
    },
    getTitle: function() {
      let rtn_val = ""
      if (this.disabled) {
        rtn_val = "No custom metadata for this call...";
      } else {
        rtn_val = "Custom metadata for this call...";
      }
      return rtn_val
    }
  },
  created() {
    this.getCDRExtras();
    this.getDynaFields();
  },
  methods: {
    emitMetadataStatus() {
      this.$emit('custom-metadata-disabled', this.disabled);
    },
    getCDRExtras() {
      let call_records_url = process.env.VUE_APP_CALL_RECORDS + this.callId;
      let axios_params = {
        params: {}
      }
      if(this.selfSignedTranscript) {
        call_records_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callId
        axios_params['params']['route'] = 'call-records'
        axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey} 
      } 

      axios
        .get(call_records_url,axios_params)
        .then(response => {
          if (response && response.data) {
            this.cdrExtras = response.data.cdrextras.sort((a, b) =>
              a.var_text.localeCompare(b.var_text)
            );
          }
        })
        .finally(() => {
          // loader.hide();
        });
    },
    getDynaFields() {
      let dynalabel_url = process.env.VUE_APP_SETUP_DYNA_LABELS_CALLS + this.callId;

      let axios_params = {
        params: {}
      }
      if(this.selfSignedTranscript) {
        dynalabel_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + this.callId
        axios_params['params']['route'] = 'dynalabels-calls'
        axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey} 
      } 
      axios
        .get(dynalabel_url, axios_params)
        .then(response => {
          if (response && response.data) {
            let dynalabelFields = Object.entries(response.data.data).map(
              ([key, value]) => ({
                key,
                value
              })
            );
            dynalabelFields.sort((a, b) => a.key.localeCompare(b.key));
            this.formatDynalabelInfo(dynalabelFields);
          }
        })
        .finally(() => {
          // loader.hide();
        });
    },
    formatName(str) {
      str = str
        .replace(/([A-Z])/g, " $1")
        .replace(/\s+/g, " ")
        .replace(/\s[a-z]/g, c => " " + c.toUpperCase());
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    openDialog() {
      this.show = !this.disabled;
    },
    formatDynaField(key) {
      if (!key.includes("_")) {
        return key[0].toUpperCase() + key.substr(1);
      } else {
        let keySplit = key.split("_");
        for (var i = 0; i < keySplit.length; i++) {
          keySplit[i] =
            keySplit[i].charAt(0).toUpperCase() + keySplit[i].slice(1);
        }
        let capitalized = keySplit.join("_");
        capitalized = capitalized.replaceAll("_", " ");
        return capitalized;
      }
    },
    formatDynalabelInfo(fields) {
      let itemsFormatted = [];
      fields.forEach((item, index) => {
        item.key = this.formatDynaField(item.key);
        let notNull = item.value != null;
        if (DESIGNATED_TIME_FIELDS.includes(item.key) && notNull) {
          if (item.key == DESIGNATED_TIME_FIELDS[1]) {
            item.key = "Handle Time (External)";
          }
          item.value = this.formatHMS(item.value);
        }
        if (notNull && !EXCLUDED_DYNA_FIELDS.includes(item.key)) {
          itemsFormatted.push(item);
        }
      });

      this.dynalabelInfo = itemsFormatted;
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    }
  },
  watch: {
    disabledDefault: "emitMetadataStatus"
  },
};
</script>

<style>
.dim {
  font-size: 1rem;
  opacity: 0.5;
}

.no-metadata {
  cursor: help;
  opacity: 0.25;
}

.view-metadata {
  cursor: pointer;
}

.tab-height {
  height: 40vh;
}
</style>
