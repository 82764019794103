<template>
    <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn fab small dark color="primary" href="/setup-faq/4" target="_blank" title="Click for Setup Help and FAQs">
          <v-icon dark>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-row>
      <v-row v-for="row in chunkedCoachingDefinitionItems" :key="row.index" justify-space-between>
        <v-col v-for="coachingDefinition in row" :key="coachingDefinition.index" :cols="12/colCount" class="d-flex flex-column">
          <v-card class="mr-8 pa-1 setup-card flex d-flex flex-column card-outer" outlined tile>
            <v-card-title>{{coachingDefinition.title}}</v-card-title>
            <v-card-text style="min-height: 5vh;">{{coachingDefinition.description}}</v-card-text>
            <v-card-actions class="card-actions">
              <p class="coaching-definition-info">Created: {{formatDate(coachingDefinition.created_at)}}<br/>
              Updated: {{formatDate(coachingDefinition.updated_at)}}<br/>
              Coaching Definition ID: {{coachingDefinition.id}}</p>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="openMenuAndSelectTarget('coachingDefinitions', coachingDefinition.id)">Edit</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  
  <script>
    import moment from "moment";
  
    export default {
      name: 'coachingDefinitionsListing',
      props: {
        coachingDefinitionsList: {
          type: Array,
        },
      },
      data() {
        return {
          colCount: 2,
        }
      },
      components: {
      },
      computed: {
        chunkedCoachingDefinitionItems() {
          let chunkedCoachingDefinitionItems = [];
          let index = 0;
          while (index < this.coachingDefinitionsList.length) {
            let tmparr = this.coachingDefinitionsList.slice(index, this.colCount + index);
            chunkedCoachingDefinitionItems.push(tmparr);
            index += this.colCount;
          }
          return chunkedCoachingDefinitionItems;
        },
      },
      methods: {
        openMenuAndSelectTarget(type, id) {
          this.$emit('openMenu', type)
          this.$emit('selectTarget', { 'type':type, 'id':id })
        },
        formatDate(time){
          return moment(time).format('l LT');
        },
      },
      watch: {
      },
      mounted() {
        this.$emit('openMenu', 'coachingDefinitions')
      }
    }
  
  </script>
  
  <style scoped>
  
    p.coaching-definition-info {
      font-size: .75rem;
      color: rgba(0,0,0,.33);
    }
  
  </style>
  