// main layouts
import MainLayout from "@/layout/MainLayout.vue";
import MainNoNavLayout from "@/layout/MainNoNavLayout.vue";

// Signin
import Signin from "@/pages/Signin/Signin.vue";
import Mfapage from "@/pages/Signin/Mfapage.vue";
import SSO from "@/pages/Signin/SSO.vue";

// GeneralViews
import NotFound from "@/pages/NotFound.vue";
import Forbidden from "@/pages/Forbidden.vue";
import ServerError from "@/pages/ServerError.vue";
import ConflictError from "@/pages/ConflictError.vue";
import BadGateway from "@/pages/BadGateway.vue";
import ServiceUnavailable from "@/pages/ServiceUnavailable.vue";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import FAQ from "@/pages/FAQ.vue";
import SetupFAQ from "@/pages/SetupFAQ.vue"
import ScheduledDeliveries from "@/pages/ScheduledDeliveries.vue"

// Feature pages
import Transcripts from "@/pages/Transcripts.vue";
import TranscriptView from "@/pages/TranscriptView.vue";
import TranscriptViewSigned from "@/pages/TranscriptViewSigned.vue";
import Insights from "@/pages/Insights.vue";
import DynaLabelInsights from "@/pages/Insights/DynaLabelInsights.vue";
import AgentInsights from "@/pages/Insights/AgentInsights.vue";
import Widgets from "@/pages/Widgets.vue";
import Offline from "@/pages/Offline.vue";

// Scorecards V2
import ScorecardsV2 from "@/pages/ScorecardsV2/ScorecardsDashboard.vue";
import ScorecardsV2Calls from "@/pages/ScorecardsV2/ScorecardsV2Calls.vue";
import DynaLabelScorecards from "@/pages/ScorecardsV2/DynaLabelScorecards.vue";
import AgentScorecardsV2 from "@/pages/ScorecardsV2/AgentScorecardsV2.vue";
import PrintableCallScorecardV2 from "@/pages/PrintableScorecards/PrintableCallScorecardV2.vue"
import PrintableTeamScorecardV2 from "@/pages/PrintableScorecards/PrintableTeamScorecardV2.vue"
import PrintableAgentScorecardV2 from "@/pages/PrintableScorecards/PrintableAgentScorecardV2.vue"

// Setup
import Setup from "@/pages/Setup.vue";

const routes = [
  {
    path: "/",
    component: MainLayout,
    redirect: "/insights",
    meta: { requiresAuth: true },
    children: [
      {
        path: "/scorecards/:scorecardId/agents/:agentId/calls",
        redirect: "/scorecards-v2",
      },
      {
        path: "scorecards-v2/:scorecardId/dynalabels",
        name: "scorecards-v2-dynalabels",
        component: DynaLabelScorecards,
        meta: {
          title: "Dynamic Label Summary"
        }
      },
      {
        path: "scorecards-v2/:scorecardId/dynalabels/:dynalabelId/agents",
        name: "scorecards-v2-agents",
        component: AgentScorecardsV2,
        meta: {
          title: "Agent Summary"
        }
      },
      {
        path: "/scorecards/:scorecardId/agents/:agentId?",
        redirect: "/scorecards-v2",
      },
      {
        path: "/scorecards/:scorecardId?",
        redirect: "/scorecards-v2",
      },
      {
        path: "/scorecards-v2/:scorecardId?",
        name: "scorecards-v2",
        component: ScorecardsV2,
        meta: {
          title: "Scorecards"
        }
      },
      {
        path: "transcripts/:id?",
        name: "transcripts",
        component: TranscriptView,
        meta: {
          title: "Transcript View",
          type: "transcript-view"

        }
      },
      {
        path: "transcripts-signed/:id/:reckey/:userid?",
        name: "transcripts-signed",
        component: TranscriptViewSigned,
        meta: {
          title: "Transcript View",
          type: "transcript-view-signed",
          signedPage: true,
        }
      },
      {
        path: "insights/",
        name: "insights-dashboard",
        component: Insights,
        meta: {
          title: "Insights"
        }
      },
      {
        path: "insights/:id/dynalabels",
        name: "insights-details",
        component: DynaLabelInsights,
        meta: {
          title: "Dynamic Label Summary"
        }
      },
      {
        path: "insights/:id/dynalabels/:dynalabelId/agents/:agentId/calls",
        name: "insights-dynalabel-agent-calls",
        component: AgentInsights,
        meta: {
          title: "Insights - Agent Calls"
        }
      },
      {
        path: "insights/:id/dynalabels/:dynalabelId/agents/calls",
        name: "insights-dynalabel-team-calls",
        component: AgentInsights,
        meta: {
          title: "Insights - Agent Calls"
        }
      },
      {
        path: "insights/:id/dynalabels/:dynalabelId/agents",
        name: "insights-dynalabels-agents",
        component: AgentInsights,
        meta: {
          title: "Agent Summary"
        }
      },
      {
        path: "setup/",
        name: "setup",
        component: Setup,
        meta: {
          title: "Setup"
        }
      },
      {
        path: "setup/dyna-labels/:dynaLabelId?",
        name: "setup-dyna-labels",
        component: Setup,
        meta: {
          title: "Setup - Dynamic Labels"
        }
      },
      {
        path: "setup/insight-definitions/:insightDefinitionId?",
        name: "setup-insight-definitions",
        component: Setup,
        meta: {
          title: "Setup - Insight Definitions"
        }
      },

      {
        path: "setup/scorecard-definitions/:scorecardDefinitionId?",
        name: "setup-scorecard-definitions",
        component: Setup,
        meta: {
          title: "Setup - Scorecard Definitions"
        }
      },
      {
        path: "setup/coaching-definitions/:coachingDefinitionId?",
        name: "setup-coaching-definitions",
        component: Setup,
        meta: {
          title: "Setup - Coaching Definitions"
        }
      },
      {
        path: "setup/access-roles/:accessRoleId?",
        name: "setup-access-roles",
        component: Setup,
        meta: {
          title: "Setup - Access Roles"
        }
      },
      {
        path: "setup/access-roles/new",
        name: "setup-access-roles-new",
        component: Setup,
        meta: {
          title: "Setup - New Access Role"
        }
      },
      {
        path: "setup/agents",
        name: "setup-agents",
        component: Setup,
        meta: {
          title: "Setup - Agents"
        }
      },
      {
        path: "widgets",
        name: "widgets",
        component: Widgets
      },

      {
        path: "offline",
        name: "offline",
        component: Offline,
        meta: {
          title: "CallFinder&reg; is Offline for Maintenance",
          type: "no-auth"
        }
      },
      // the following are hidden routes for pdf generation
      {
        path:
          "/scorecards/:scorecardId/agents/:agentId/printable-agent-scorecard",
        redirect: "/scorecards-v2",
      },
      {
        path: "/scorecards-v2/:scorecardId/dynalabels/:dynalabelId/agents/:agentId/calls/printable-agent-scorecard",
        name: "printable-agent-scorecard-v2",
        component: PrintableAgentScorecardV2,
        meta: {
          title: "Printable Agent Scorecard",
          type: "printable-scorecard-v2"
        }
      },
      {
        path:
          "/scorecards/:scorecardId/agents/:agentId/calls/:callid/printable-call-scorecard",
        redirect: "/scorecards-v2",
      },
      {
        path:
          "/scorecards-v2/:scorecardId/dynalabels/:dynalabelId/agents/:agentId/calls/:callId/printable-call-scorecard",
        name: "printable-call-scorecard-v2",
        component: PrintableCallScorecardV2,
        meta: {
          title: "Printable Call Scorecard",
          type: "printable-scorecard-v2"
        }
      },
      {
        path:
          "/scorecards-v2-signed/:scorecardId/dynalabels/:dynalabelId/agents/:agentId/calls/:callId/printable-call-scorecard/:reckey/:userid",
        name: "printable-call-scorecard-v2-signed",
        component: PrintableCallScorecardV2,
        meta: {
          title: "Printable Call Scorecard",
          type: "printable-call-scorecard-v2-signed",
          signedPage: true
        }
      },
      {
        path: "/scorecards/:scorecardId/printable-team-scorecard",
        redirect: "/scorecards-v2",
      },
      {
        path: "/scorecards-v2/:scorecardId/dynalabels/:dynalabelId/agents/:agentId/calls/printable-team-scorecard",
        name: "printable-team-scorecard-v2",
        component: PrintableTeamScorecardV2,
        meta: {
          title: "Printable Team Scorecard",
          type: "printable-scorecard-v2"
        }
      },
      {
        path: "/scorecards/:scorecardId/scorecards-srd",
        redirect: "/scorecards-v2",
      },
      {
        path:
          "/scorecards-v2/:scorecardId/dynalabels/:dynalabelId/agents/:agentId/calls",
        name: "scorecards-v2-calls",
        component: ScorecardsV2Calls,
        meta: {
          title: "Scorecard Call Log"
        }
      },
    ]
  },
  {
    path: "/signin",
    name: "signin",
    component: Signin,
    meta: {
      title: "Sign in",
      type: "sign-in"
    }
  },
  {
    path: "/sso/:onetime",
    name: "sso",
    component:SSO,
    meta: {
      title: "Single Sign On",
      type: 'sign-in',
    }
  },
  {
    path: "/mfapage",
    name: "mfapage",
    component: Mfapage,
    meta: {
      title: "Mfa Page",
      type: "sign-in"
    }
  },
  {
    path: "/lg",
    component: MainNoNavLayout,
    meta: {
      requiresAuth: true,
      type: "pop-up"
    },
    children: [
      {
        path: "transcripts/:id",
        name: "transcripts-lg",
        component: Transcripts,
        meta: {
          title: "Search Transcripts",
          type: "pop-up"
        }
      }
    ]
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy"
    }
  },
  {
    path: "/faq/:pageid?",
    component: FAQ,
    meta: {
      title: "Frequently Asked Questions"
    }
  },
  {
    path: "/setup-faq/:pageid?",
    component: SetupFAQ,
    meta: {
      title: "Setup Frequently Asked Questions"
    }
  },
  {
    path: "/scheduled-deliveries",
    component: ScheduledDeliveries,
    meta: {
      title: "Scheduled Deliveries"
    }
  },
  {
    path: "/notfound",
    component: NotFound
  },
  {
    path: "/forbidden",
    component: Forbidden
  },
  {
    path: "/servererror",
    component: ServerError
  },
  {
    path: "/badgateway",
    component: BadGateway
  },
  {
    path: "/serviceunavailable",
    component: ServiceUnavailable
  },
  {
    path: "/conflicterror",
    component: ConflictError
  },
  {
    path: "*",
    component: NotFound
  }
];

export default routes;
