import axios from "@/utils/AxiosInstance.js";

export const presentation = {
  namespaced: true,
  state: {
    //  insights
    presentationInsightsData: [],
    presentationInsightsDataLoadingStatus: 'notLoading',
    presentationInsightsDataLoadingError: '',
    presentationInsightsDataLoadingErrorStatus: false,

    presentationInsightsCallData: [],
    presentationInsightsCallDataLoadingStatus: 'notLoading',
    presentationInsightsCallDataLoadingError: '',
    presentationInsightsCallDataLoadingErrorStatus: false,

    //  scorecards
    presentationScorecardsData: [],
    presentationScorecardsDataLoadingStatus: 'notLoading',
    presentationScorecardsDataLoadingError: '',
    presentationScorecardsDataLoadingErrorStatus: false,
  },
  getters: {
    presentationInsightsDataLoadingErrorStatus: state => state.presentationInsightsDataLoadingErrorStatus,
    presentationInsightsDataLoadingError: state => state.presentationInsightsDataLoadingError,
    presentationInsightsDataLoadingStatus: state => state.presentationInsightsDataLoadingStatus,
    presentationInsightsData: state => state.presentationInsightsData,

    presentationInsightsCallDataLoadingErrorStatus: state => state.presentationInsightsCallDataLoadingErrorStatus,
    presentationInsightsCallDataLoadingError: state => state.presentationInsightsCallDataLoadingError,
    presentationInsightsCallDataLoadingStatus: state => state.presentationInsightsCallDataLoadingStatus,
    presentationInsightsCallData: state => state.presentationInsightsCallData,

    presentationScorecardsDataLoadingErrorStatus: state => state.presentationScorecardsDataLoadingErrorStatus,
    presentationScorecardsDataLoadingError: state => state.presentationScorecardsDataLoadingError,
    presentationScorecardsDataLoadingStatus: state => state.presentationScorecardsDataLoadingStatus,
    presentationScorecardsData: state => state.presentationScorecardsData,
  },
  mutations: {
    SET_PRESENTATION_INSIGHTS_DATA_LOADING_ERROR_STATUS(state, status) {
      state.presentationInsightsDataLoadingErrorStatus = status;
    },
    SET_PRESENTATION_INSIGHTS_DATA_LOADING_ERROR(state, error) {
      state.presentationInsightsDataLoadingError = error;
    },
    SET_PRESENTATION_INSIGHTS_DATA_LOADING_STATUS(state, status) {
      state.presentationInsightsDataLoadingStatus = status;
    },
    SET_PRESENTATION_INSIGHTS_DATA(state, data) {
      state.presentationInsightsData = data;
    },

    SET_PRESENTATION_INSIGHTS_CALL_DATA_LOADING_ERROR_STATUS(state, status) {
      state.presentationInsightsCallDataLoadingErrorStatus = status;
    },
    SET_PRESENTATION_INSIGHTS_CALL_DATA_LOADING_ERROR(state, error) {
      state.presentationInsightsCallDataLoadingError = error;
    },
    SET_PRESENTATION_INSIGHTS_CALL_DATA_LOADING_STATUS(state, status) {
      state.presentationInsightsCallDataLoadingStatus = status;
    },
    SET_PRESENTATION_INSIGHTS_CALL_DATA(state, data) {
      state.presentationInsightsCallData = data;
    },

    SET_PRESENTATION_SCORECARDS_DATA_LOADING_ERROR_STATUS(state, status) {
      state.presentationScorecardsDataLoadingErrorStatus = status;
    },
    SET_PRESENTATION_SCORECARDS_DATA_LOADING_ERROR(state, error) {
      state.presentationScorecardsDataLoadingError = error;
    },
    SET_PRESENTATION_SCORECARDS_DATA_LOADING_STATUS(state, status) {
      state.presentationScorecardsDataLoadingStatus = status;
    },
    SET_PRESENTATION_SCORECARDS_DATA(state, data) {
      state.presentationScorecardsData = data;
    },
  },
  actions: {
    retrievePresentationInsightsData (context, payload) {
      let error_msg = 'An error occurred retrieving insights presentation data. Please try again.'
      let aggregate_type = payload.aggregate_type
      if (payload.insight_id) {
        let urlParams = '?'
        urlParams += '&feature=insight';
        urlParams += '&feature_id=' + payload.insight_id
        urlParams += '&aggregate_type=' + aggregate_type

        if (payload.display_type) urlParams += '&display_type=' + payload.display_type
        if (aggregate_type!='call') {
          context.commit("SET_PRESENTATION_INSIGHTS_DATA_LOADING_STATUS", "loading")
        }  else {
          context.commit("SET_PRESENTATION_INSIGHTS_CALL_DATA_LOADING_STATUS", "loading")
        }
        axios
          .get(process.env.VUE_APP_PRESENTATION + urlParams)
          .then(response => {
            if (aggregate_type!='call') {
              context.commit("SET_PRESENTATION_INSIGHTS_DATA", response.data)
              context.commit("SET_PRESENTATION_INSIGHTS_DATA_LOADING_ERROR_STATUS", false)
              context.commit("SET_PRESENTATION_INSIGHTS_DATA_LOADING_ERROR", "")
              context.commit("SET_PRESENTATION_INSIGHTS_DATA_LOADING_STATUS", "loaded")
            } else {
              context.commit("SET_PRESENTATION_INSIGHTS_CALL_DATA", response.data)
              context.commit("SET_PRESENTATION_INSIGHTS_CALL_DATA_LOADING_ERROR_STATUS", false)
              context.commit("SET_PRESENTATION_INSIGHTS_CALL_DATA_LOADING_ERROR", "")
              context.commit("SET_PRESENTATION_INSIGHTS_CALL_DATA_LOADING_STATUS", "loaded")
            }
          })
          .catch(function (error) {
            if (aggregate_type!='call') {
              throwPresentationError(context, 'SET_PRESENTATION_INSIGHTS', error_msg)
            } else {
              throwPresentationError(context, 'SET_PRESENTATION_INSIGHTS_CALL', error_msg)
            }
          })
        } else {
          if (aggregate_type!='call') {
            throwPresentationError(context, 'SET_PRESENTATION_INSIGHTS', error_msg)
          } else {
            throwPresentationError(context, 'SET_PRESENTATION_INSIGHTS_CALL', error_msg)
          }
        }
    },
    retrievePresentationInsightsCallData (context, payload) {
      let error_msg = 'An error occurred retrieving insights presentation data. Please try again.'
      if (payload.insight_id) {
        let urlParams = '?'
        urlParams += '&feature=insight';
        urlParams += '&feature_id=' + payload.insight_id
        urlParams += '&aggregate_type=' + payload.aggregate_type
        context.commit("SET_PRESENTATION_INSIGHTS_DATA_LOADING_STATUS", "loading")
        axios
          .get(process.env.VUE_APP_PRESENTATION + urlParams)
          .then(response => {
            context.commit("SET_PRESENTATION_INSIGHTS_DATA", response.data)
            context.commit("SET_PRESENTATION_INSIGHTS_DATA_LOADING_ERROR_STATUS", false)
            context.commit("SET_PRESENTATION_INSIGHTS_DATA_LOADING_ERROR", "")
            context.commit("SET_PRESENTATION_INSIGHTS_DATA_LOADING_STATUS", "loaded")
          })
          .catch(function (error) {
            throwPresentationError(context, 'SET_PRESENTATION_INSIGHTS', error_msg)
          })
      } else {
        if (aggregate_type!='call') {
          throwPresentationError(context, 'SET_PRESENTATION_INSIGHTS', error_msg)
        } else {
          throwPresentationError(context, 'SET_PRESENTATION_INSIGHTS_CALL', error_msg)
        }
      }
    },
    retrievePresentationScorecardsData (context, payload) {
      let error_msg = 'An error occurred retrieving scorecard presentation data. Please try again.'
      if (payload.scorecard_id) {
        let urlParams = '?'
        urlParams += '&feature=scorecard';
        urlParams += '&feature_id=' + payload.scorecard_id
        urlParams += '&aggregate_type=' + payload.aggregate_type
        context.commit("SET_PRESENTATION_SCORECARDS_DATA_LOADING_STATUS", "loading")
        axios
          .get(process.env.VUE_APP_PRESENTATION + urlParams)
          .then(response => {
            context.commit("SET_PRESENTATION_SCORECARDS_DATA", response.data)
            context.commit("SET_PRESENTATION_SCORECARDS_DATA_LOADING_ERROR_STATUS", false)
            context.commit("SET_PRESENTATION_SCORECARDS_DATA_LOADING_ERROR", "")
            context.commit("SET_PRESENTATION_SCORECARDS_DATA_LOADING_STATUS", "loaded")
          })
          .catch(function (error) {
            throwPresentationError(context, 'SET_PRESENTATION_SCORECARDS', error_msg)
          })
      } else {
        throwPresentationError(context, 'SET_PRESENTATION_SCORECARDS', error_msg)
      }
    },
  },
}

function throwPresentationError(context, prefix, error_msg) {
  context.commit(prefix + '_DATA', [])
  context.commit(prefix + '_DATA_LOADING_ERROR_STATUS', true)
  context.commit(prefix + '_DATA_LOADING_ERROR', error_msg)
  context.commit(prefix + '_DATA_LOADING_STATUS', 'failed')
}
