var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticStyle:{"height":"100vh","max-height":"calc(100%)"}},[_c('v-container',{staticStyle:{"height":"100vh","max-height":"calc(100%)"},attrs:{"fluid":"","pa-0":""}},[_c('v-row',[_c('v-col',{staticStyle:{"height":"100vh","max-height":"calc(100%)","padding":"0","position":"relative","align":"center"}},[_c('Filters',{ref:"myInsightsFilters",attrs:{"channels":this.channelCount,"feature":"insights"},on:{"filters-loaded":_vm.filtersLoaded,"agents-filter-updated-reload":_vm.filterUpdated,"subjectiveSet-filter-updated-reload":_vm.filterUpdated,"accounts-filter-updated-reload":_vm.filterUpdated}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:((this.myRouteName=='insights-details')),expression:"(this.myRouteName=='insights-details')"}]},[_c('v-col',{staticClass:"pl-12 pb-0"},[(_vm.insightsAggregatedDynaLabelDataLoadingStatus==='loaded' && _vm.insightsAggregatedDynaLabelDataLoadingErrorStatus===false)?[_c('insight-breadcrumbs',{attrs:{"linkEnabled":true,"insightHeader":_vm.insightHeader,"level":'dynalabel',"searchFieldIsEmpty":_vm.searchFieldIsEmpty},on:{"go-to-page":_vm.goBack,"re-apply-search-phrase":_vm.reApplySearchPhrase}})]:_vm._e()],2),_c('v-col',{staticClass:"text-right pr-10"},[_c('export-menu',{attrs:{"disableDeliveries":_vm.insightsAggregatedDynaLabelDataLoadingStatus!='loaded',"disableExports":_vm.insightsAggregatedDynaLabelDataLoadingStatus!='loaded'},on:{"exportClick":_vm.exportClick}}),(
                  _vm.insightsAggregatedDynaLabelDataLoadingStatus==='loaded'
                )?_c('insights-download',{ref:"InsightsDownload",staticClass:"float-right",attrs:{"insightsId":Number(_vm.uriInsightId),"insightsUrl":_vm.insightDynalabelUrl,"insightsTitle":_vm.insightHeader + ' Grouping Dynamic Label',"dateRange":_vm.getDateRangeString()}}):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","small":"","dark":"","color":"primary","href":"/faq/1","target":"_blank","title":"Click for Insights Help and FAQs"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-help-circle-outline")])],1)],1)],1),_c('insight-processing-status',{attrs:{"type":'dynalabel'}}),_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"3"}},[_c('ad-hoc-search',{ref:"adHocSearch",attrs:{"channels":this.channelCount},on:{"searchChanged":_vm.searchChanged}})],1)],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:((this.myRouteName=='insights-details')),expression:"(this.myRouteName=='insights-details')"}],staticStyle:{"height":"100vh","max-height":"calc(100%)"},attrs:{"pl-5":"","pr-5":"","fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-6"},[(_vm.insightsAggregatedDynaLabelDataLoadingStatus==='loaded' && _vm.insightsAggregatedDynaLabelDataLoadingErrorStatus===false)?_c('v-card',{staticClass:"mb-12 pb-12",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"pl-4 pr-8 pb-2",attrs:{"headers":_vm.filteredAggregateHeaders,"items":_vm.dynaLabelTeamData,"hide-default-footer":true},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-2",attrs:{"value":true,"color":"warning","icon":"mdi-message-alert-outline","dark":""}},[_vm._v("\n                        "+_vm._s(_vm.noDataMessage)+"\n                      ")])]},proxy:true},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{class:_vm.getCallCountStyle(item),attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"agent-btn",attrs:{"disabled":item.callCount==0,"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.loadDynaLabelTeam()}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.name)+"\n                          ")])]}}],null,true)},[(item.callCount>0)?_c('span',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.id)+") call log…")]):_vm._e()])]}},{key:"item.team_sentiment",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[(item.channelCount==2)?[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.sentimentCounts,"display":'Customer',"type":'sentiment'}})]:(item.channelCount==1)?[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.sentimentCounts,"display":'Speaker 1/Speaker 2',"type":'sentiment'}})]:[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.sentimentCounts,"display":'Both Channels',"type":'sentiment'}})]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.team_emotion",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount==2)?[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.emotionCounts,"display":'Customer',"type":'emotion'}})]:(item.channelCount==1)?[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.emotionCounts,"display":'Speaker 1/Speaker 2',"type":'emotion'}})]:[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.emotionCounts,"display":'Both Channels',"type":'emotion'}})]]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.averageDuration_formatted",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[_c('span',{staticClass:"tData",attrs:{"title":"Talk Time (the average length of all the audio recordings)"}},[_vm._v(_vm._s(item.averageDuration_formatted))])]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.silenceAveragePercent",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[_c('span',{staticClass:"tData",attrs:{"title":item.silenceAverageSeconds + ' (Average measured silence per call)'}},[_vm._v(_vm._s(item.silenceAveragePercent))])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.silenceExtra",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount>1)?_c('span',{staticClass:"dim",attrs:{"title":'Silence: Agent owns ' + item.silencePercentAgent + ' / Customer owns ' + item.silencePercentCustomer}},[_vm._v(_vm._s(item.silenceRatio))]):_c('span',{staticClass:"dim",attrs:{"title":"Not available from single-channel audio"}},[_vm._v("—")])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.talkTimeAverageSeconds",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[_c('span',{staticClass:"tData",attrs:{"title":item.talkTimeAverageSeconds + ' (Average speaking time: the average conversation time within all the audio recordings)'}},[_vm._v(_vm._s(item.talkTimeAveragePercent))])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.talkTimeExtra",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount>1)?_c('span',{staticClass:"dim",attrs:{"title":'Speaking Time: Agent owns ' + item.talkTimePercentAgent + ' / Customer owns ' + item.talkTimePercentCustomer}},[_vm._v(_vm._s(item.talktimeRatio))]):_c('span',{staticClass:"dim",attrs:{"title":"Not available from single-channel audio"}},[_vm._v("—")])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.overtalkAveragePercent",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount>1)?_c('span',{staticClass:"tData",attrs:{"title":item.overtalkAverageSeconds + ' (Average measured overtalk per call)'}},[_vm._v(_vm._s(item.overtalkAveragePercent))]):_c('span',{staticClass:"tData",class:_vm.getCallCountStyle(item),attrs:{"title":"Not available from single-channel audio"}},[_vm._v("—")])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.overtalkExtra",fn:function(ref){
                var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount>1)?_c('span',{staticClass:"dim",attrs:{"title":'Overtalk: Agent owns ' + item.overtalkPercentAgent + ' / Customer owns ' + item.overtalkPercentCustomer}},[_vm._v(_vm._s(item.overtalkRatio))]):_c('span',{staticClass:"dim",class:_vm.getCallCountStyle(item),attrs:{"title":"Not available from single-channel audio"}},[_vm._v("—")])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.callCount",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"tData"},[_vm._v(_vm._s(_vm.getCallCountDisplay(item)))]),[(item.channelCount==3)?_c('v-icon',{staticClass:"pl-10 mixed-channels",attrs:{"color":"warning"}},[_vm._v("mdi-alert-box")]):_vm._e()]]}}],null,false,2516053326)}),_c('v-card-title',[_c('h1',{staticClass:"main"},[_vm._v("Dynamic Labels")]),_c('v-text-field',{staticClass:"pl-0 pr-4",attrs:{"dense":"","append-icon":"mdi-magnify","placeholder":"Find Dynamic Labels","single-line":"","clearable":""},on:{"click:clear":function($event){return _vm.clearDynaLabelSearch()}},model:{value:(_vm.dynaLabelSearch),callback:function ($$v) {_vm.dynaLabelSearch=$$v},expression:"dynaLabelSearch"}})],1),_c('v-data-table',{staticClass:"pl-4 pr-8 pb-2",attrs:{"sort-by":_vm.dynaLabelSortBy,"sort-desc":_vm.dynaLabelSortDesc,"search":_vm.dynaLabelSearch,"headers":_vm.filteredGroupedHeaders,"items":_vm.dynaLabelRowData,"items-per-page":5,"footer-props":{
                      itemsPerPageOptions: [10, 25, 50, 100]
                    },"custom-sort":_vm.customSort},on:{"update:sortBy":function($event){_vm.dynaLabelSortBy=$event},"update:sort-by":function($event){_vm.dynaLabelSortBy=$event},"update:sortDesc":function($event){_vm.dynaLabelSortDesc=$event},"update:sort-desc":function($event){_vm.dynaLabelSortDesc=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-2",attrs:{"value":true,"color":"warning","icon":"mdi-message-alert-outline","dark":""}},[_vm._v("\n                        "+_vm._s(_vm.noDataMessage)+"\n                      ")])]},proxy:true},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"agent-btn",attrs:{"disabled":item.callCount==0,"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.loadDynaLabelAgents(item.id)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.name)+"\n                          ")])]}}],null,true)},[(item.callCount>0)?_c('span',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.id)+") call log…")]):_vm._e()])]}},{key:"item.averageDuration_formatted",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[_c('span',{staticClass:"tData",class:_vm.getCallCountStyle(item),attrs:{"title":"Talk Time (the average length of all the audio recordings)"}},[_vm._v(_vm._s(item.averageDuration_formatted))])]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.agent_sentiment",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[(item.channelCount==2)?[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.sentimentCounts,"display":'Customer',"type":'sentiment'}})]:(item.channelCount==1)?[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.sentimentCounts,"display":'Speaker 1/Speaker 2',"type":'sentiment'}})]:[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.sentimentCounts,"display":'Both Channels',"type":'sentiment'}})]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.agent_emotion",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount==2)?[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.emotionCounts,"display":'Customer',"type":'emotion'}})]:(item.channelCount==1)?[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.emotionCounts,"display":'Speaker 1/Speaker 2',"type":'emotion'}})]:[_c('subjective-distribution-widget',{attrs:{"scale":50,"info":item.emotionCounts,"display":'Both Channels',"type":'emotion'}})]]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.silenceAveragePercent",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[_c('span',{staticClass:"tData",attrs:{"title":"Average measured silence per call"}},[_vm._v(_vm._s(item.silenceAveragePercent))])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.silenceExtra",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount>1)?_c('span',{staticClass:"dim",attrs:{"title":'Silence: Agent owns ' + item.silencePercentAgent + ' / Customer owns ' + item.silencePercentCustomer}},[_vm._v(_vm._s(item.silenceRatio))]):_c('span',{staticClass:"dim",class:_vm.getCallCountStyle(item),attrs:{"title":"Not available from single-channel audio"}},[_vm._v("—")])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.talkTimeAveragePercent",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[_c('span',{staticClass:"tData",attrs:{"title":item.talkTimeAverageSeconds + ' (Average speaking time: the average conversation time within all the audio recordings)'}},[_vm._v(_vm._s(item.talkTimeAveragePercent))])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.talkTimeExtra",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount>1)?_c('span',{staticClass:"dim",attrs:{"title":'Speaking Time: Agent owns ' + item.talkTimePercentAgent + ' / Customer owns ' +item.talkTimePercentCustomer}},[_vm._v(_vm._s(item.talktimeRatio))]):_c('span',{staticClass:"dim",class:_vm.getCallCountStyle(item),attrs:{"title":"Not available from single-channel audio"}},[_vm._v("—")])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.overtalkAveragePercent",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount>1)?_c('span',{staticClass:"tData",attrs:{"title":item.overtalkAverageSeconds + ' (Average measured overtalk per call)'}},[_vm._v(_vm._s(item.overtalkAveragePercent))]):_c('span',{staticClass:"tData",class:_vm.getCallCountStyle(item),attrs:{"title":"Not available from single-channel audio"}},[_vm._v("—")])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.overtalkExtra",fn:function(ref){
                    var item = ref.item;
return [(item.callCount>0)?[(!_vm.isChat)?[(item.channelCount>1)?_c('span',{staticClass:"dim",attrs:{"title":'Overtalk: Agent owns ' + item.overtalkPercentAgent + ' / Customer owns ' + item.overtalkPercentCustomer}},[_vm._v(_vm._s(item.overtalkRatio))]):_c('span',{staticClass:"dim",class:_vm.getCallCountStyle(item),attrs:{"title":"Not available from single-channel audio"}},[_vm._v("—")])]:[_c('span',[_vm._v("—")])]]:[_c('span',{staticClass:"no-data"},[_vm._v("—")])]]}},{key:"item.callCount",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"tData"},[_vm._v(_vm._s(_vm.getCallCountDisplay(item)))]),[(item.channelCount==3)?_c('v-icon',{staticClass:"pl-10 mixed-channels",attrs:{"color":"warning"}},[_vm._v("mdi-alert-box")]):_vm._e()]]}}],null,false,558304285)}),_c('h5',{staticClass:"footnote"},[_c('sup',{staticClass:"footnote"},[_vm._v("*")]),_vm._v(" A call can belong to more than one dynamic label. The sum of the dynamic labels may add up to more than the \"All Dynamic Labels\" total.")]),(_vm.showMixAlert)?_c('h5',{staticClass:"footnote"},[_c('v-icon',{staticClass:"mixed-channels",attrs:{"color":"warning"}},[_vm._v("mdi-alert-box")]),_vm._v(" Reported data contain a mix of single and dual-channel call recordings — agent/cust details apply only to the dual-channel recordings.")],1):_vm._e()],1):_vm._e(),(_vm.insightsAggregatedDynaLabelDataLoadingErrorStatus===true)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_vm._v(_vm._s(_vm.insightsAggregatedDynaLabelDataLoadingError))])],1):_vm._e(),(_vm.insightsAggregatedDynaLabelDataLoadingStatus !=='loaded' )?_c('v-card',{attrs:{"flat":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"}},[_c('v-progress-circular',{staticClass:"align-self-center mb-4",attrs:{"indeterminate":"","color":"primary"}}),_c('div',{staticClass:"progress"},[_vm._v("retrieving list loading")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1),_c('ScheduledExportsModal',{attrs:{"showScheduledExportsModal":_vm.showScheduledExportsModal,"isNew":true,"insightOrScorecard":'insights',"pdfDelivery":false,"exportPath":1},on:{"HIDE_SCHEDULED_EXPORTS_MODAL":_vm.hideScheduledExportsModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }