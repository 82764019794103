<template>
  <div v-if="isLegacy">
    <v-row style="margin-top: -3.5rem;">
      <v-col cols="12">
        <AudioWaveform
          v-if="userDataLoadingStatus=='loaded' && !isChat"
          @update-play-head-position="updatePlayHeadPosition"
          :callId="callId"
          :isLegacy="isLegacy"
          :playHeadPosition="playHeadPosition"
          :playFromPosition="playFromPosition"
          :setPosition="setPosition"
          :offset="offset"
          :transcriptBtnCounter="transcriptBtnCounter"
          :modalView="noModal"
          :overlay-present="overlay"
        ></AudioWaveform>
      </v-col>
    </v-row>
    <v-row no-gutters style="margin: 0 4px 0 4px;" class="justify-center align-content-center">
      <v-col class="pa-1">
        <h4 class="display-legacy">
          <template > {{ convertToUpperCase(agent)}}</template>
        </h4>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="4">
        <h3>
          <div class="info-integrated-legacy">
            {{ getCallNumber(callDirection) }} <br>
            {{ formatDate(callDate) }}<br>
            {{ formatAccount(accountName, accountNumber) }}<br>
            {{ formatClientCallId(clientCallId) }}
          </div>
        </h3>
          <div class="icon-soup-legacy" >
          <email-control ref="emailControlLegacy"
            :url="getUrl()"
            :agentId="agentCode"
            :feature="'transcripts'"
            :dialog_title="'Transcript Link'"
            :reporttype="'transcript-audio'"
            :scorecardId="String($route.params.scorecardId)"
            :callId=callId
            :start_date="$route.query.start_date"
            :end_date="$route.query.end_date"
            :icon_position="'float-right'"
            :displaySoup="false"
            :type="'transcript'"
            @overlay-present="overlayPresent"
          ></email-control>
          <tags-edit-control ref="tagsEditControlLegacy" v-if="userDataLoadingStatus=='loaded'" class="float-right"
            @update-call-has-tags="updateCallHasTags"
            :tagPriv="returnSignedPrivileges(userData.privileges.tags)"
            :callId="Number(callId)"
            :hasTags="has_tags"
            :displaySoup="false">
          </tags-edit-control>
          <note-edit-control ref="notesEditControlLegacy" v-if="userDataLoadingStatus=='loaded'" class="float-right"
            @update-call-note="updateCallNote"
            @overlay-present="overlayPresent"
            :notePriv="returnSignedPrivileges(userData.privileges.notes)"
            :callId="Number(callId)"
            :noteId="note_id"
            :noteIsBlank="note_is_blank"
            :displaySoup="false">
          </note-edit-control>
          <transcript-menu v-if="userDataLoadingStatus=='loaded'"
            @menuClick="menuClick"
            :isLegacy="isLegacy"
            :tagPriv="returnSignedPrivileges(userData.privileges.tags)"
            :hasTags="has_tags"
            :notePriv="returnSignedPrivileges(userData.privileges.notes)"
            :noteIsBlank="note_is_blank"
            :menuLocation="String(menuLocation)"
            :selfSignedTranscript="selfSignedTranscript"
            :customMetadataDisabled="customMetadataDisabled"
            :transcriptProcessStatus="transcriptProcessStatus"
            :isTranscriptModal="isModal"
            >
          </transcript-menu>
        </div>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row>
      <v-col cols="3">
        <h2 class="scorecard-name">{{scorecardName}}</h2>
        <h2 v-if="noModal" class="scorecard-name">{{convertToUpperCase(agent)}}</h2>
        <h3 class="info-integrated">

            {{ getCallNumber(callDirection) }} <br>
            {{ formatDate(callDate) }}<br>
            {{ formatAccount(accountName, accountNumber) }}<br>
            {{ formatClientCallId(clientCallId) }}
        </h3>
        <div class="icon-soup" >
          <transcript-download ref="transcriptDownload" v-if="userDataLoadingStatus=='loaded'&&transcriptExists&&this.userData.settings.transcriptExports"
            class="float-right"
            :callId="determineDemoCallId"
            :usingInsights="insights"
            :accountName="accountName"
            :agent="agent"
            :callerNumber="callerNumber"
            :accountNumber="accountNumber"
            :callDirection="callDirection"
            :callDate="callDate"
            :displaySoup="false"
            :selfSignedTranscript="selfSignedTranscript"
            @transcriptDownloadSetter="transcriptDownloadSetter"
          ></transcript-download>
          <email-control
            ref="emailControl"
            :agentId="agentCode"
            :url="getUrl()"
            :feature="'transcripts'"
            :dialog_title="'Transcript Link'"
            :reporttype="'transcript-audio'"
            :scorecardId="String($route.params.scorecardId)"
            :callId = callId
            :start_date="$route.query.start_date"
            :end_date="$route.query.end_date"
            :icon_position="'float-right'"
            :type="'transcript'"
            :displaySoup="false"
            :selfSignedTranscript="selfSignedTranscript"
            @overlay-present="overlayPresent"
          ></email-control>
          <tags-edit-control ref="tagsEditControl" v-if="userDataLoadingStatus=='loaded'" class="float-right"
            @update-call-has-tags="updateCallHasTags"
            :tagPriv="returnSignedPrivileges(userData.privileges.tags)"
            :callId="Number(callId)"
            :hasTags="has_tags"
            :selfSignedTranscript="selfSignedTranscript"
            :displaySoup="false">
          </tags-edit-control>

          <note-edit-control ref="notesEditControl" v-if="userDataLoadingStatus=='loaded'" class="float-right"
            @update-call-note="updateCallNote"
            @overlay-present="overlayPresent"
            :notePriv="returnSignedPrivileges(userData.privileges.notes)"
            :callId="Number(callId)"
            :noteId="note_id"
            :noteIsBlank="note_is_blank"
            :selfSignedTranscript="selfSignedTranscript"
            :displaySoup="false">
          </note-edit-control>

          <custom-metadata-modal ref="customMetadata"
            @custom-metadata-disabled="disabledCustomMetadata"
            :callId="callId"
            :selfSignedTranscript="selfSignedTranscript"
            class="float-right"
            :displaySoup="false">
          </custom-metadata-modal>

          <transcript-menu v-if="userDataLoadingStatus=='loaded'"
            @menuClick="menuClick"
            :isLegacy="isLegacy"
            :tagPriv="returnSignedPrivileges(userData.privileges.tags)"
            :hasTags="has_tags"
            :notePriv="returnSignedPrivileges(userData.privileges.notes)"
            :noteIsBlank="note_is_blank"
            :menuLocation="String(menuLocation)"
            :selfSignedTranscript="selfSignedTranscript"
            :customMetadataDisabled="customMetadataDisabled"
            :transcriptProcessStatus="transcriptProcessStatus"
            :transcriptExports="userData.settings.transcriptExports"
            :transcriptExists="transcriptExists"
            :isTranscriptModal="isModal"
          ></transcript-menu>

        </div>
      </v-col>
      <v-col cols="9" class="pt-0">
        <div class="pr-1">
          <AudioWaveform
            v-if="userDataLoadingStatus=='loaded' && !isChat"
            @update-play-head-position="updatePlayHeadPosition"
            @update-set-position="updateSetPosition"
            :callId="callId"
            :playFromPosition="playFromPosition"
            :setPosition="setPosition"
            :offset="offset"
            :transcriptBtnCounter="transcriptBtnCounter"
            :selfSignedTranscript="selfSignedTranscript"
            :modalView="!noModal"
            :transcriptDisplayedRegions="transcriptHits"
            :displayedRegions="displayedRegions"
            :context="context"
            :overlay-present="overlay"
          ></AudioWaveform>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <insights-widget :callId="callId" :selfSignedTranscript="selfSignedTranscript" :isChat="isChat" v-if="insights"></insights-widget>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import emailControl from "@/components/Controls/EmailControl"
import AudioWaveform from "@/components/AudioWaveform";
import noteEditControl from "@/components/Controls/NoteEditControl";
import tagsEditControl from "@/components/Controls/TagsEditControl";
import { mapGetters, mapActions } from "vuex";
import InsightsWidget from "@/components/Widgets/InsightsWidget.vue";
import TranscriptDownload from '../Controls/TranscriptDownload.vue';
import CustomMetadataModal from '../Widgets/CustomMetadataModal.vue';
import TranscriptMenu from "@/components/Widgets/TranscriptMenu.vue";
import axios from "@/utils/AxiosInstance.js";
import DemoHandler from '@/utils/DemoHandler';

export default {
  components: {
    AudioWaveform,
    noteEditControl,
    tagsEditControl,
    emailControl,
    InsightsWidget,
    TranscriptDownload,
    CustomMetadataModal,
    TranscriptMenu,
  },
  props: {
    agent: {
      type: String,
      required: true,
    },
    agentCode: {
      type: Number,
      required: true,
    },
    callerNumber: {
      type: String,
      required: true,
    },
    calledNumber: {
      type: String,
      required: true,
    },
    callDate: {
      type: String,
      required: true,
    },
    callDirection: {
      type: String,
      required: true,
    },
    accountNumber: {
      type: Number,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    scorecardName: {
      type: String,
      required: false,
    },
    scorecardId: {
      type: String,
      required: false,
    },
    insights: {
      type: Boolean,
      required: false,
    },
    sentiment: {
      type: String,
      required: true,
    },
    callId: {
      type: String,
      required: true,
    },
    isLegacy: {
      type: Boolean,
      required: false,
      default: false,
    },
    note_id: {
      type: Number,
      default: 0
    },
    note_is_blank: {
      type: Number,
      default: 1,
      required: true,
    },
    has_tags: {
      type: Boolean,
      default: false,
    },
    playHeadPosition: {
      type: Number,
      default: 0
    },
    playFromPosition: {
      type: Number,
      default: 0
    },
    setPosition: {
      type: Number,
      default: 0
    },
    transcriptions: {
      type: Boolean,
      required: false,
    },
    transcriptExists: {
      type: Boolean,
      required: false,
    },
    offset: {
      type: Number,
      default: 0
    },
    transcriptBtnCounter: {
      type: Number,
      default: null
    },
    clientCallId: {
      type: String,
      default: null,
      required: false,
    },
    noModal: {
      type: Boolean,
      default: false,
      required: false,
    },
    showModal: {
      type: Boolean,
      default: false,
      required: false,
    },
    selfSignedTranscript: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuLocation: {
      type: String,
      required: false,
      default: "transcripts",
    },
    isModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    context: {
      type: String,
      required: false,
      default: "",
    },
    contextId: {
      type: Number,
      required: false,
      default: 0,
    },
    contextDynalabelId: {
      type: Number,
      required: false,
      default: 0,
    },
    contextAgentId: {
      type: Number,
      required: false,
      default: 0,
    },
    displayedRegions: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    customMetadataDisabled: true,
    transcriptProcessStatus: false,
    signedTranscriptUrl: "",
    transcriptHits: [],
    overlay: false,
    callDataId: 0
  }),
  computed: {
    ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),
    ...mapGetters('scorecardsV2Calls',[
      "callSearchHitsTranscript",
      "callSearchHitsTranscriptLoadingStatus",
      "callSearchHitsTranscriptLoadingError",
      "callSearchHitsTranscriptLoadingErrorStatus",
    ]),
    ...mapGetters("insights", [
      "insightsGroupedDynaLabelDataLoadingErrorStatus",
      "insightsGroupedDynaLabelDataLoadingError",
      "insightsGroupedDynaLabelDataLoadingStatus",
      "insightsGroupedDynaLabelData",
    ]),
    ...mapGetters('dynaLabels',[
      'dynalabelSearches',
      'dynalabelSearchesLoadingStatus',
      'dynalabelSearchesLoadingError',
      'dynalabelHitsForCallLoadingErrorStatus',
    ]),
    ...mapGetters("scorecardsV2Aggregates", [
      "groupedScorecardDynalabelData",
      "groupedScorecardDynalabelDataLoadingStatus",
      "groupedScorecardDynalabelDataLoadingError",
      "groupedScorecardDynalabelDataLoadingErrorStatus",
    ]),
    transformSentiment() {
      let response = ''
      switch (this.sentiment) {
        case 'Positive':
          response = 'Strong Positive';
          break;
        case 'Mostly Positive':
          response = 'Positive';
          break;
        case 'Mostly Negative':
          response = 'Negative';
          break;
        case 'Negative':
          response = 'Strong Negative';
          break;
        case 'Mixed':
          response = 'Mixed';
          break;
        default:
          response = "Neutral"
      }
      return response;
    },
    getUnit() {
      return (moment(this.$route.query.end_date).diff(moment(this.$route.query.start_date),'days')>1) ? 'day' : 'hour'
    },
    determineDemoCallId() {
      return DemoHandler.determineDemoCall(this.callId)
    },
    isChat() {
      return this.insightsGroupedDynaLabelDataLoadingStatus == "loaded" && this.insightsGroupedDynaLabelData[0].insight_media_type == 'Chat';
    },
  },
  watch: {
    insightsGroupedDynaLabelDataLoadingStatus: 'getDynalabelSearchesForTranscript',
    groupedScorecardDynalabelData: 'getDynalabelSearchesForTranscript',
    userDataLoadingStatus: 'generateSignedLink',
    callSearchHitsTranscriptLoadingStatus: 'generateTranscriptHits',
    dynalabelSearches: 'getSearchHits',
    context: 'updateContext',
    showModal: 'updateShowModal',
    userDataLoadingStatus: function(status) {
      if(status=='loaded') {
        this.loadCallData()
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'retrieveUserData',
    ]),
    ...mapActions('scorecardsV2Calls', [
      'retrieveSearchHits',
      'setCallSearchHitsTranscriptLoadingStatus',
    ]),
    ...mapActions('dynaLabels',[
      'getDynalabelSearches',
    ]),
    ...mapActions('scorecardsV2Aggregates',[
      'retrieveScorecardsGroupedDynaLabelData',
    ]),
    loadCallData() {
      this.callDataId = DemoHandler.determineDemoCall(this.callId)

      if(this.context == "scorecards") {
        this.loadScorecardTranscriptHits()
      } else if (this.context == "insights") {
        this.$store.dispatch("insights/retrieveInsightsGroupedDynaLabelData",this.getDynalabelParametersInsights());
      }

    },
    updateShowModal() {
      if (this.showModal && this.context=='scorecards') {
        this.generateScorecardHits()
      }
    },
    updateContext() {
      if (this.context=='scorecards') {
        this.generateScorecardHits()
      }
    },
    loadScorecardTranscriptHits() {
      let payload = {
        scorecard_id: this.contextId,
        start_date: moment(this.$route.query.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.$route.query.end_date).format("YYYY-MM-DD"),
        grouping: this.getUnit,
        call_type:
          this.$store.getters["filters/scorecardAdjustments"] || "all",
        presentation: []
      };
      if(this.selfSignedTranscript) {
        payload['selfSigned'] = this.selfSignedTranscript
        payload['userid'] = this.$route.params.userid
        payload['reckey'] = this.$route.params.reckey
        payload['call_id'] = this.callDataId
      }
      this.retrieveScorecardsGroupedDynaLabelData(payload);
    },
    generateScorecardHits() {
      if (this.groupedScorecardDynalabelDataLoadingStatus == 'loaded') {
        // due to the subcategory context at the scorecard level, we need to reset the scorecard call transcript hits each time we open the full call scorecard
        this.transcriptHits = []
        this.generateTranscriptHits()
      } else {
        this.loadScorecardTranscriptHits()
      }
    },
    generateTranscriptHits() {
      if(this.callSearchHitsTranscript[this.callDataId] == undefined && this.callSearchHitsTranscriptLoadingStatus == 'loaded') {
        this.setCallSearchHitsTranscriptLoadingStatus("notLoading")
        this.getDynalabelSearchesForTranscript();
      } else if(this.transcriptHits.length == 0 && this.callSearchHitsTranscriptLoadingStatus == 'loaded' && this.callSearchHitsTranscript[this.callDataId] != undefined) {
        let transcriptHitsRtn = []

        if(this.context == "scorecards") {
          this.groupedScorecardDynalabelData.items.forEach(dynalabel => {
            // if the dynalabel has searches and is applicable
            if((this.contextDynalabelId == 0 || this.contextDynalabelId == dynalabel.id) && (this.dynalabelSearches[dynalabel.id] && this.dynalabelSearches[dynalabel.id].length > 0)) {
              // for each search
              this.dynalabelSearches[dynalabel.id].forEach(search => {
                // if the call has search hits
                if(this.callSearchHitsTranscript[this.callDataId] != null) {
                  this.callSearchHitsTranscript[this.callDataId].offsets.forEach(offset => {
                    if(offset.fkey_search_id == search) {
                      transcriptHitsRtn.push({id: offset.fkey_search_id, offset: offset.offset, title: dynalabel.dynalabelTitle})
                    }
                  })
                }
              })
            }
          })
        } else if(this.context == "insights") {
          this.insightsGroupedDynaLabelData.forEach(dynalabel => {
            // if the dynalabel has searches and is applicable
            if((this.contextDynalabelId == 0 || this.contextDynalabelId == dynalabel.dynalabel_id) && (this.dynalabelSearches[dynalabel.dynalabel_id] && this.dynalabelSearches[dynalabel.dynalabel_id].length > 0)) {
              // for each search
              this.dynalabelSearches[dynalabel.dynalabel_id].forEach(search => {
                // if the call has search hits
                if(this.callSearchHitsTranscript[this.callDataId] != null) {
                  this.callSearchHitsTranscript[this.callDataId].offsets.forEach(offset => {
                    if(offset.fkey_search_id == search) {
                      transcriptHitsRtn.push({id: offset.fkey_search_id, offset: offset.offset, title: dynalabel.title})
                    }
                  })
                }
              })
            }
          })
        }

        // combine duplicates, remove null
        let noDupesTranscriptHits = []
        let alreadyAddedOffsets = []
        transcriptHitsRtn.forEach(transcriptHit => {
          if(alreadyAddedOffsets.includes(transcriptHit.offset)) {
            let indexOfDupe = noDupesTranscriptHits.findIndex(dupe => dupe.offset == transcriptHit.offset)
            noDupesTranscriptHits[indexOfDupe].title += ("\n" + transcriptHit.title)
          } else if(transcriptHit.offset != null){
            noDupesTranscriptHits.push(transcriptHit)
            alreadyAddedOffsets.push(transcriptHit.offset)
          }
        })
        this.transcriptHits = noDupesTranscriptHits
      }
    },
    getDynalabelSearchesForTranscript() {
        if(this.context == "scorecards" && this.groupedScorecardDynalabelDataLoadingStatus == "loaded" && this.groupedScorecardDynalabelData.items) {
          // block will give us ids to get searches
          let dynaIds = []
          this.groupedScorecardDynalabelData.items.forEach((dynalabelScorecard) => {
            dynaIds.push(dynalabelScorecard.id)
          })
          this.callGetDynalabelSearchHits(dynaIds)
        } else if (this.context == "insights" && this.insightsGroupedDynaLabelDataLoadingStatus == "loaded" ) {
          // if dynalabel data is loaded dont do anything. otherwise set it to load
          let dynaIds = []
          this.insightsGroupedDynaLabelData.forEach((dynalabelInsight) => {
            dynaIds.push(dynalabelInsight.dynalabel_id)
          })
          this.callGetDynalabelSearchHits(dynaIds)
        }
    },
    callGetDynalabelSearchHits(dynaIds) {
      if(dynaIds.length != 0) {
        let params = {
          dynalabel_ids: dynaIds
        }
        if(this.selfSignedTranscript) {
          params.selfSigned = this.selfSignedTranscript
          params.callId = this.callId
          params.reckey = this.$route.params.reckey
          params.userid = this.$route.params.userid
        }
        this.getDynalabelSearches(params)
      }
    },
    transcriptDownloadSetter(processStatus) {
      this.transcriptProcessStatus = processStatus
    },
    getDynalabelParametersInsights() {
      let insight_id = this.contextId
      let dynalabel_id = this.contextDynalabelId
      let accountsFilterObject = this.$store.getters["filters/accounts"] || {accounts:[]}
      let agentFilterObject = this.$store.getters["filters/agents"] || {agents:[]}
      let subjectiveFilterObject = this.$store.getters["filters/subjectiveSet"] || {subjectiveSet:[]}
      let params = {
        starts_at: moment(this.$route.query.start_date).format("YYYY-MM-DD"),
        ends_at: moment(this.$route.query.end_date).format("YYYY-MM-DD"),
        insight_id: insight_id,
        dynalabel_id: dynalabel_id,
        agent_ids: agentFilterObject.agents,
        account_ids: accountsFilterObject.accounts,
        subjective: subjectiveFilterObject.subjectiveSet,
        grouping: this.getUnit,
        speaker: this.$route.query.speaker,
        phrase: this.$route.query.phrase,
        presentation: this.presentationInsightsData,
      }
      if(this.selfSignedTranscript) {
        params.selfSigned = this.selfSignedTranscript
        params.callId = this.callDataId
        params.reckey = this.$route.params.reckey
        params.userid = this.$route.params.userid
      }
      return params;
    },
    menuClick(item) {
      switch (item) {
        case "customMetadataClick":
          this.$refs.customMetadata.openDialog()
          break;
        case "notesClick":
          if(this.userData.privileges.notes !== "NONE") {
            if(this.isLegacy) {
              this.$refs.notesEditControlLegacy.toggleDialog()
            } else {
              this.$refs.notesEditControl.toggleDialog()
            }
          }
          break;
        case "tagsClick":
          if(this.userData.privileges.tags !== "NONE") {
            if(this.isLegacy) {
              this.$refs.tagsEditControlLegacy.openDialog()
            } else {
              this.$refs.tagsEditControl.openDialog()
            }
          }
          break;
        case "sendEmailClick":
          if(!this.selfSignedTranscript) {
            // if generating a transcript link we need to get a reckey. only get one if we havent gotten one yet
            if(this.isLegacy) {
              this.$refs.emailControlLegacy.openDialogWithUrl(this.signedTranscriptUrl)
            } else {
              this.$refs.emailControl.openDialogWithUrl(this.signedTranscriptUrl)
            }
          } else {
            if(this.isLegacy) {
              this.$refs.emailControlLegacy.openDialog()
            } else {
              this.$refs.emailControl.openDialog()
            }
          }
          break;
        case "exportTranscriptClick":
          this.$refs.transcriptDownload.exportTranscript()

          break;
        case "openTranscriptInNewTabClick":
          this.$emit('open-transcript-in-new-tab',this.signedTranscriptUrl)
      }
    },
    generateSignedLink() {
      if(this.userDataLoadingStatus=="loaded") {
        let urlContext = "";
        switch (this.context) {
          case "insights":
            urlContext = "?context=insights&context_dynalabel_id=" + this.contextDynalabelId + "&context_agent_id=" + this.contextAgentId + "&context_id=" + this.contextId
                      + "&start_date=" + this.$route.query.start_date + "&end_date=" + this.$route.query.end_date
            break;
          case "scorecards":
            urlContext = "?context=scorecards&context_dynalabel_id=" + this.contextDynalabelId + "&context_agent_id=" + this.contextAgentId + "&context_id=" + this.contextId
                      + "&start_date=" + this.$route.query.start_date + "&end_date=" + this.$route.query.end_date
            break;
        }
        if(this.selfSignedTranscript) {
          this.signedTranscriptUrl = "/transcripts-signed/" + this.callId + '/' + this.$route.params.reckey + '/' + this.$route.params.userid + urlContext
        } else {
          axios
            .post(process.env.VUE_APP_RECKEYS,{
              call_id: this.callId,
              expire: true,
            }).then(response => {
              this.signedTranscriptUrl = "/transcripts-signed/" + this.callId + '/' + response.data.reckey + '/' + this.userData.id + urlContext
            }).catch(error => {
              // if we cant generate a reckey send them to the normal transcript page
              this.transcripts = "/transcripts/" + this.callId + urlContext
            })
        }
      }
    },
    disabledCustomMetadata(disabled) {
      this.customMetadataDisabled = disabled
    },
    getUrl() {
      let url = '/transcripts/' + this.callId
      if(this.selfSignedTranscript) {
        url = '/transcripts-signed/' + this.callId + '/' + this.$route.params.reckey + '/' + this.$route.params.userid
      }
      url = url + '?context=' + this.context + '&context_dynalabel_id=' + this.contextDynalabelId + "&context_agent_id=" + this.contextAgentId + '&context_id=' + this.contextId + "&start_date=" + this.$route.query.start_date + "&end_date=" + this.$route.query.end_date
      return url
    },
    getCallId(callId) {
      return "Call ID: " + callId
    },
    convertToUpperCase(name) {
      return name.toUpperCase()
    },
    getPrintableURL() {
      let url =
        '/scorecards/' + this.$route.params.scorecardId
        + '/agents/' + this.$route.params.agentId
        + '/calls/' + this.callId
        + '/printable-call-scorecard'
        + '?start_date=' + this.$route.query.start_date
        + '&end_date=' + this.$route.query.end_date
      return url
    },
    updateCallNote(e) {
      this.$emit('update-call-note', e)
    },
    updateCallHasTags(e) {
      this.$emit('update-call-has-tags', e)
    },
    updatePlayHeadPosition(e) {
      this.$emit('update-play-head-position', e);
    },
    updateSetPosition(e) {
      this.$emit('update-set-position', e)
    },
    getCallNumber(direction) {
        direction = direction == "in" ? "Inbound" : "Outbound";
        if (direction == "Inbound") {
          return this.formatNumber(direction, this.callerNumber)
        } else {
          return this.formatNumber(direction, this.calledNumber)
        }
    },
    formatNumber: function(direction, number) {
      var num = parsePhoneNumberFromString(String(number), 'US');
      if (num) {
        if (direction == "Inbound") {
          return direction + ' call from ' + num.formatNational() + ' - (' + this.callId + ')';
        } else {
          return direction + ' call to ' + num.formatNational() + ' - (' + this.callId + ')';
        }
      }
    },
    returnSignedPrivileges(privilege) {
      if(this.selfSignedTranscript && privilege !== "NONE") {
        return "READ"
      } else {
        return privilege;
      }
    },
    formatDate: function(date) {
      var displayDate = moment(date);
      if (displayDate.isValid()) {
        return displayDate.format('dddd, MMMM D, YYYY, h:mmA') || '';
      }
    },
    formatAccount: function(name, number) {
      if (name && number) return name + ' (' + number + ')';
    },
    formatClientCallId: function(clientCallId) {
      let rtn_string = "Client Call Identifier: "
      if(clientCallId) {
        return rtn_string + clientCallId
      } else {
        return rtn_string
      }
    },
    makeClassName: function(sentiment) {
      if (sentiment !== null && typeof sentiment !== "undefined") {
        return "svg-sentiment-" + sentiment.replace(/\s+/g, "-").toLowerCase();
      } else {
        return "svg-sentiment-neutral";
      }
    },
    getSearchIds() {
      let rtn = []

      for (const [key, value] of Object.entries(this.dynalabelSearches)) {
        rtn = [...new Set([...value, ...rtn])]
      }
      return rtn
    },
    getSearchHits() {
      if(this.dynalabelSearchesLoadingStatus == 'loaded'){
        let axios_params = {}
        if(this.selfSignedTranscript) {
          axios_params['userid'] = this.$route.params.userid
          axios_params['reckey'] = this.$route.params.reckey
        }

        let searchIds = this.getSearchIds()
        if(searchIds.length > 0) {
          this.retrieveSearchHits({
            call_id: this.callDataId,
            search_ids: searchIds,
            axios_params: axios_params,
            selfSigned: this.selfSignedTranscript,
            transcriptHits: true
          });
        }

      } else {
        // dont need to call retrieve search hits every time
        this.generateTranscriptHits()
      }
    },
    overlayPresent(e) {
      this.overlay = e
    },
  },
  mounted() {
    // get context related info only if context is used
    if(this.context != "") {
      this.setCallSearchHitsTranscriptLoadingStatus("notLoading")
    }
    if (this.userDataLoadingStatus=='loaded') {
      this.loadCallData()
    } else if (this.userDataLoadingStatus!="loaded") {
      let params = {selfSignedTranscript: this.selfSignedTranscript}
      if(this.selfSignedTranscript) {
        params['userid'] = this.$route.params.userid
        params['reckey'] = this.$route.params.reckey
        params['call_id'] = this.callId
      }
      this.retrieveUserData(params)
    }
    this.generateSignedLink()
  },
};
</script>

<style scoped>
  h2.scorecard-name {
    margin-top: 0 !important;
    font-family: "Roboto";
    font-size: 1.0rem;
    padding-left: .8rem;
    color: rgba(1, 106, 175, .9);
    line-height: 1.2rem;
  }

  h3.info-integrated {
    font-family: "Roboto";
    font-size: .9rem;
    padding-left: .8rem;
    line-height: 1.1rem;
  }



  h4 {
    font-size: 1.8rem;
    padding-left: .8rem;
  }

  h4.display-legacy {
    font-size: 1rem;
    margin: 0;
    line-height: .5rem;
    padding: 0rem .25rem;
  }

  .svg-sentiment-info {
    cursor: help;
  }

  .subtitle-integrated {
    font-family: "Roboto";
    font-size: .9rem;
    font-weight: bold;
    line-height: 1.4rem;
    display: inline-block;
    padding: .1rem 1rem;
  }

  .subtitle-legacy {
    font-family: "Roboto";
    font-size: .9rem;
    padding: 0;
  }

  .icon-soup {
    vertical-align: bottom;
    padding: .5rem;
    padding-left: .8rem;
    float:left;
  }

  .icon-soup-legacy {
    -webkit-transform:scale(0.85);
    -moz-transform:scale(0.85);
    -ms-transform:scale(0.85);
    transform:scale(0.85);
    vertical-align: bottom;
    float:left;
    padding-left: .4rem;
  }

  .float-left {
    margin-right: .5rem;
  }

  .svg-sentiment-strong-positive {
    --color-chevron-north-upper: green;
    --color-chevron-north-middle: lightgreen;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-positive {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: green;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-neutral {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-negative {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: orangered;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-strong-negative {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: orange;
    --color-chevron-south-lower: orangered;
  }

  .svg-sentiment-mixed {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgreen;
    --color-chevron-south-middle: orange;
    --color-chevron-south-lower: lightgrey;
  }

  .info-integrated-legacy {
    font-family: "Roboto";
    font-size: .7rem;
    font-weight: bold;
    display: inline-block;
    padding: .1rem .8rem;
  }

  .id-alignment {
    font-size: 1.3rem;
    padding-left: .8rem;
    line-height: 1.5rem;
    padding-top: 1rem;
  }

</style>
