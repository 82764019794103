<template>

  <v-dialog v-model="showScheduledExportsModal" persistent width="60vw">
    <v-card class="pa-md-4 mx-lg-auto">
      <v-card-title class="pt-0 mt-0">
        <h1 class="agent-title">{{ getTitle }}</h1>
        <v-spacer></v-spacer>
        <v-btn x-small text icon color="primary" @click="hideModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="this.createDeliveryError && this.isNew" type="error" class="alert-message">
          {{ "There was an error while creating the delivery. Please try again later." }}
        </v-alert>
        <v-alert v-else-if="this.createDeliveryError && !this.isNew" type="error" class="alert-message">
          {{ "There was an error while saving the delivery. Please try again later." }}
        </v-alert>

        <v-alert v-if="this.deleteDeliveryError" type="error" class="alert-message">
          {{ "There was an error while deleting the delivery. Please try again later." }}
        </v-alert>
        <v-form autocomplete=off ref="theForm" v-model="formIsValid">
          <v-breadcrumbs :items="this.getBreadcrumbs()" :key="this.insightsListDataLoadingStatus" class="pl-0 pt-0"></v-breadcrumbs>
          <p v-if="this.reportSchedule.delivery_type=='Email (Per Agent)'">This delivery will be automatically sent to all agents listed in the Agent tab who have a defined email address.</p>
          <p v-else>This delivery will be automatically sent to the specified recipient(s), according to the schedule below.</p>
          <v-container class="pt-0 pb-0">
            <v-row dense>

              <v-col v-if="this.reportSchedule.delivery_type=='SFTP'">
                <v-text-field
                  v-model="reportSchedule.name"
                  label="Name"
                  required
                  clearable
                  counter="40"
                  :rules="sftpRules.name"
                ></v-text-field>
              </v-col>
              <v-col v-else>
                <v-text-field
                  v-model="reportSchedule.name"
                  label="Name"
                  required
                  clearable
                  counter="40"
                  :rules="formRules.name"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  v-model="reportSchedule.description"
                  label="Description"
                  required
                  clearable
                  counter="255"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="pb-0">
                <setup-tip :section="'srd_info'" style="width: 10%; display: inline-block;"></setup-tip>
                <v-select
                  style="width: 75%; display: inline-block;"
                  v-model="reportSchedule.frequency"
                  :items="frequencies"
                  label="Frequency"
                ></v-select>
              </v-col>
              <v-col cols="1" class="pb-0">
                <v-select
                  class="mr-1"
                  v-model="reportSchedule.delivery_hour"
                  :items="hours"
                  label="Hour"
                ></v-select>
              </v-col>
              <v-col cols="1" class="pb-0">
                <v-select
                  v-model="reportSchedule.ampm"
                  :items="ampms"
                  label="AM/PM"
                ></v-select>
              </v-col>
              <v-col cols="1" class="pb-0">
                <v-select
                  v-model="reportSchedule.timezone"
                  :items="timezones"
                  label="Time Zone"
                ></v-select>
              </v-col>
              <v-col class="pb-0">
                <v-select
                  v-model="reportSchedule.delivery_day"
                  :items="deliverydays"
                  label="Day of Week"
                  :disabled="reportSchedule.frequency!='Weekly'"
                  :messages="(reportSchedule.frequency!='Weekly') ? 'not used by ' + reportSchedule.frequency.toLowerCase() + ' deliveries' : ''"
                ></v-select>
              </v-col>
              <v-col class="pb-0">
                <v-select
                  v-model="reportSchedule.delivery_day_of_month"
                  :items="deliverydaysofmonth"
                  label="Day of Month"
                  :disabled="reportSchedule.frequency!='Monthly'"
                  :messages="(reportSchedule.frequency!='Monthly') ? 'not used by ' + reportSchedule.frequency.toLowerCase() + ' deliveries' : ''"
                ></v-select>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col class="pt-0 pb-0">
                <v-container class="content-message pa-0">
                  <span v-if="reportSchedule.frequency=='Daily'">
                    The contents of this scheduled delivery will include data from the prior day.
                  </span>
                  <span v-else-if="reportSchedule.frequency=='Weekly'">
                    The contents of this scheduled delivery will include data from the seven days prior to selected delivery day.
                  </span>
                  <span v-else-if="reportSchedule.frequency=='Monthly'">
                    The contents of this scheduled delivery will include data from the prior month delivered on the specified day of the following month.
                  </span>
                </v-container>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="2">
                <v-select
                  v-model="reportSchedule.delivery_type"
                  :items="methods"
                  label="Delivery Method"
                  :messages="getDeliveryMethodMessages"
                ></v-select>
              </v-col>

              <template v-if="this.reportSchedule.delivery_type=='Email'">
                <v-col cols="5">
                  <v-container class="d-flex mt-3">
                    <v-text-field
                      ref="addNewRecipient"
                      v-model="newRecipient"
                      label="Add A New Recipient"
                      placeholder="someone@example.com"
                      outlined
                      clearable
                      @keyup="isValidRecipient"
                      @paste="isValidRecipient"
                      :rules="formRules.aRecipient"
                      class="mr-4"
                    ></v-text-field>
                    <v-btn
                      small
                      @click="addRecipient"
                      color="primary"
                      class="mt-3"
                      :disabled="this.newRecipient=='' || false"
                    >Add >></v-btn>
                  </v-container>
                  <v-select
                    ref="recipientListControl"
                    v-model="reportSchedule.email"
                    :items="reportSchedule.email"
                    multiple
                    :rules="formRules.recipientlist"
                    class="hidden"
                  ></v-select>
                </v-col>
                <v-col cols="5">
                  <v-container>
                    <fieldset class="recipient-list">
                      <legend>Recipients</legend>
                      <v-chip
                        small
                        v-for="(recipient, index) in reportSchedule.email"
                        :key="index"
                        class="ma-1"
                        close
                        @click:close="removeRecipient(index)"
                      >{{ recipient }}</v-chip>
                    </fieldset>
                    <span
                      v-if="this.reportSchedule.email.length==0"
                      class="recipient-alert"
                    >At least one recipient is required for email delivery</span>
                  </v-container>
                </v-col>
              </template>

              <!-- email form fields-->
              <template v-if="this.reportSchedule.delivery_type=='Email (Per Agent)'">
                <v-col cols="10">
                  <v-container class="d-flex mt-3">
                    <p class="font-weight-black">This report will be emailed to all agents listed in the Agent tab who have a defined email address.</p>
                  </v-container>
                </v-col>
              </template>

              <!-- sftp form fields-->
              <template v-if="this.reportSchedule.delivery_type=='SFTP'">
                <v-col cols="3" class="mb-12">
                  <v-text-field
                    ref="hostname"
                    v-model="reportSchedule.hostname"
                    label="SFTP Host Name"
                    required
                    :rules="sftpRules.hostname"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    ref="username"
                    v-model="reportSchedule.username"
                    label="Username"
                    required
                    :rules="sftpRules.userpass"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    ref="password"
                    v-model="reportSchedule.password"
                    :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showEye ? 'text' : 'password'"
                    @click:append="showEye = !showEye"
                    label="Password"
                    required
                    :rules="sftpRules.userpass"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-text-field
                    v-model="reportSchedule.port"
                    label="Port"
                    :rules="sftpRules.port"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="reportSchedule.folder"
                    label="Folder"
                    clearable></v-text-field>
                </v-col>
              </template>

            </v-row>

            <!-- emailed deliveries can opt to receive specific files -->
            <v-row v-show="showFileSelectors" dense>
              <v-col><span>Select files for delivery:</span></v-col>
              <v-col v-for="file in getFileOptions" :key="file.key">
                <v-checkbox dense hide-details v-model="reportSchedule.delivery_files[file.key]" class="mt-0 mb-4">
                  <template v-slot:label>
                    <span class="checkboxLabel">{{ properCase(file.key) }}</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <p class="notation">
              <em>
                The dates used below will show the view as it would in its most recent delivery. Actual dates will differ, and be computed at the time of each delivery event.
              </em>
              <br>
              <a :href="reportSchedule.routepath + getDateQuery" target="_BLANK">{{ reportSchedule.routepath }}{{ getDateQuery }}</a>
            </p>
          </v-container>
      </v-form>
      </v-card-text>
      <div class="modal-footer">
        <v-card-actions>
          <v-btn v-if="!isNew" small color="error" @click="deleteSchedule">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="hideModal">Close/Cancel</v-btn>
          <v-btn small color="primary" @click="saveSchedule" :disabled="!formIsValid" :loading="saveLoading">Save</v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <confirm ref="confirm"></confirm>
  </v-dialog>
</template>

<script>

const initialReport = {
  id: null,
  name: '',
  description: '',
  frequency: 'Daily',
  delivery_day_of_month: 1,
  delivery_hour: 7,
  delivery_day: "Monday",
  delivery_files: {detail: true, summary: true, tags: true, custom_metadata: true},
  ampm: 'AM',                   // allows early or late deliveries
  timezone: 'Eastern',          // timezone of delivery
  method: 'Email',              // email or sftp
  email: [],               // list of email recipients
  hostname: '',                 // sftp host name sans-sftp://
  username: '',                 // sftp username
  password: '',                 // sftp password
  port: '',                     // optional port
  folder: '',                   // optional folder
  routepath: "",  // the http path to what is being scheduled
  filters: {},
  typeName: "",
  dynalabelName: "",
  agentName: "",
  delivery_type: "Email",
  phrase: "",
  speaker: "",
  export_path: 3,
};

import Confirm from "@/components/Widgets/Confirm.vue";
import moment from "moment";
import SetupTip from "@/components/SetupTip.vue";
import {mapActions, mapGetters} from "vuex"
import {emailRegex, dnsRegex} from "../../utils/GlobalRegex.js"

export default {
  name: "ScheduledExportsModal",
  props: {
    showScheduledExportsModal: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: true
    },
    target: {
      type: Object,
      default: null
    },
    pdfDelivery: {
      type: Boolean,
      default: false,
    },
    insightOrScorecard: {
      type: String,
    },
    exportPath: {
      type: Number,
      default: 3
    }
  },
  components: {
    Confirm,
    SetupTip
  },
  data() {
    return {
      frequencies: ['Daily','Weekly','Monthly'],
      hours: [1,2,3,4,5,6,7,8,9,10,11,12],
      ampms: ['AM','PM'],
      timezones: ['Eastern','Central','Mountain','Pacific'],
      deliverydays: ['Sunday', 'Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
      deliverydaysofmonth: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
      methods: ['Email','SFTP','Email (Per Agent)'],
      reportSchedule: JSON.parse(JSON.stringify(initialReport)),
      originalReport: {},
      sftpRules: {
        name: [
          val => (val || '').length >= 4 || 'The delivery name is required and must be four characters or more in length',
          val => !(val.includes("/") && this.reportSchedule.delivery_type=='SFTP') || "Delivery name cannot contain '/' when using SFTP"
        ],
        hostname: [
          val => (val || '').length > 0 || 'This field is required',
          val => dnsRegex.test(val) || 'Host name is not valid'
        ],
        userpass: [
          val => (val || '').length > 0 || 'This field is required',
        ],
        port: [
          val => (/^$|^\d{2,5}$/.test(val)) || '2-5 digits'
        ]
      },
      formRules: {
        name: [
          val => (val || '').length >= 4 || 'The delivery name is required and must be four characters or more in length',
        ],
        recipientlist: [
          val => (val || '').length > 0 || 'At least one recipient is required for email delivery'
        ],
        aRecipient: [
          val => {
            if(val) return emailRegex.test(val) || 'Recipient must be a valid email'
            else return true
          }
        ]
      },
      newRecipient: '',
      showEye: false,
      formIsValid: false,
      createDeliveryError: false,
      deleteDeliveryError: false,
      saveLoading: false,
    };
  },
  methods: {
    ...mapActions("deliveries", [
      "createDelivery",
      "deleteDelivery",
      "updateDelivery",
    ]),
    isValidRecipient() {
      let r = this.newRecipient.trim()
      return emailRegex.test(r)
    },
    addRecipient() {
      let r = this.newRecipient.trim()
      if(r!="" && this.isValidRecipient(r)) {
        this.reportSchedule.email.push(r);
        this.newRecipient = "";
      }
    },
    removeRecipient(index) {
      this.reportSchedule.email.splice(index, 1);
    },
    hideModal() {
      this.hideErrors()
      // if not is new return the delivery to what it was
      if(!this.isNew) {
        this.reportSchedule = JSON.parse(JSON.stringify(this.originalReport))
      }
      this.$emit("HIDE_SCHEDULED_EXPORTS_MODAL", false);
    },
    hideErrors() {
      this.createDeliveryError = false
      this.deleteDeliveryError = false
    },
    async saveSchedule() {
      this.hideErrors()
      this.saveLoading = true

      // insight / scorecard filters
      if(this.isNew && this.insightOrScorecard == "insights") {
        this.reportSchedule.filters = this.all
        this.reportSchedule.phrase = this.$route.query.phrase
        this.reportSchedule.speaker = this.$route.query.speaker
      } else if(this.isNew && this.insightOrScorecard == "scorecards") {
        this.reportSchedule.filters = JSON.stringify({"adjusted_calls_filter":this.$store.getters["filters/scorecardAdjustments"]})
      }

      if(this.getPdfDelivery) {
        this.reportSchedule.pdf_delivery = 1;
      } else {
        this.reportSchedule.pdf_delivery = 0;
      }

      if(this.isNew) {
        this.reportSchedule.routepath = window.location.pathname
        let success = await this.createDelivery(this.reportSchedule)

        this.saveLoading = false

        if(!success) {
          this.createDeliveryError = true
        } else {
          this.reportSchedule = {
            ...initialReport
          }
          this.reportSchedule.export_path = this.exportPath
          
          this.reportSchedule.email = []
          this.$emit("HIDE_SCHEDULED_EXPORTS_MODAL", false);
        }
      } else {
        let success = await this.updateDelivery(this.reportSchedule)
        this.saveLoading = false
        if(!success) {
          this.createDeliveryError = true
        } else {
          this.reportSchedule.email = []
          this.$emit("HIDE_SCHEDULED_EXPORTS_MODAL", false);
        }
      }
    },
    async deleteSchedule() {
      this.hideErrors()
      if (await this.$refs.confirm.open('Delete Scheduled Export?', 'About to delete this scheduled export. This action cannot be undone. Are you sure you want to delete it?', { color: 'red' })) {
        // yes
        let deleteSuccess = await this.deleteDelivery(this.reportSchedule.id)
        if(!deleteSuccess) {
          this.deleteDeliveryError = true
        }
      }
      else {
        this.deleteDeliveryError = false
      }
      if(!this.deleteDeliveryError) {
        this.$emit("HIDE_SCHEDULED_EXPORTS_MODAL", false);
      }
    },
    updateReportSchedule() {
      if(this.target) {
        this.reportSchedule = Object.assign({}, this.target)
        this.originalReport = JSON.parse(JSON.stringify(this.reportSchedule))
      }
    },
    getBreadcrumbs() {
      let breadCrumbs = []
      let pathArray = []
      // error loading the breadcrumb data anywhere
      if(this.insightsListDataLoadingStatus == "failed" || this.newScorecardsListLoadingStatus == "failed" || this.dynaLabelsListLoadingStatus == "failed" || this.agentDataLoadingStatus == "failed") {
        breadCrumbs.push({
          text: "There was an error while loading delivery data. Please try again later.",
          disabled: true,
          href: "#",
        })

        return breadCrumbs
      }

      if(!(this.insightsListDataLoadingStatus == "loaded" && this.newScorecardsListLoadingStatus == "loaded" && this.dynaLabelsListLoadingStatus == "loaded" && this.agentDataLoadingStatus == "loaded")) {
        breadCrumbs.push({
          text: "Loading...",
          disabled: true,
          href: "#",
        })

        return breadCrumbs
      }

      // different for new ones vs old ones
      if(this.isNew) {
        this.reportSchedule.routepath = window.location.pathname
        pathArray = window.location.pathname.split("/")
      } else {
        pathArray = this.reportSchedule.routepath.split("/")
      }

      // insight / scorecard
      if((this.isNew && this.insightOrScorecard == "insights") || (!this.isNew && this.reportSchedule.type == 1)) {
        if(typeof pathArray[2] === 'undefined' || pathArray[2] == 0) {
          breadCrumbs.push({
            text: "Insight: All Insights",
            disabled: true,
            href: '#',
          })
        } else {
          let typeString = ""
          typeString = this.insightsListData.find(function (entry) {if(entry.id == pathArray[2]) return entry.title })
          if(typeString != undefined) {
            typeString = typeString.title
          } else {
            typeString = "Undefined Insight"
          }
          breadCrumbs.push({
            text: "Insight: " + typeString,
            disabled: true,
            href: '#',
          })
        }
      } else if ((this.isNew && this.insightOrScorecard == "scorecards") || (!this.isNew && this.reportSchedule.type == 0)) {

        if(typeof pathArray[2] === 'undefined' || pathArray[2] == 0) {
          breadCrumbs.push({
            text: "Scorecard: All Scorecards",
            disabled: true,
            href: '#',
          })
        } else {
          let typeString = ""
          typeString = this.newScorecardsList.find(function (entry) {if(entry.id == pathArray[2]) return entry.title })
          if(typeString != undefined) {
            typeString = typeString.title
          } else {
            typeString = "Undefined Scorecard"
          }
          breadCrumbs.push({
            text: "Scorecard: " + typeString,
            disabled: true,
            href: '#',
          })
        }
      } else {
        breadCrumbs.push({
          text: "There was an error while loading breadcrumbs",
          disabled: true,
          href: '#',
        })
      }

      // dynalabels
      if(this.reportSchedule.export_path > 1) {
        if(pathArray[4] == 0) {
          breadCrumbs.push({
            text: "Dynamic Label: All Dynamic Labels",
            disabled: true,
            href: '#',
          })
        } else if(typeof pathArray[4] !== 'undefined' ) {
          let dynaString = ""
          dynaString = this.dynaLabelsList.find(function (entry) {if(entry.id == pathArray[4]) return entry.title })
          if(dynaString != undefined) {
            dynaString = dynaString.title
          } else {
            dynaString = "Undefined Dynamic Label"
          }
          breadCrumbs.push({
            text: "Dynamic Label: " + dynaString,
            disabled: true,
            href: '#',
          })
        }
      } else if(this.reportSchedule.export_path == 1) {
        breadCrumbs.push({
          text: "Dynamic Label",
          disabled: true,
          href: '#',
        })
      }

      // agents
      if(this.reportSchedule.export_path > 2) {
        if(pathArray[6] == 0) {
          breadCrumbs.push({
            text: "Agent: All Agents",
            disabled: true,
            href: '#',
          })
        } else if(typeof pathArray[6] !== 'undefined'){
          let agentString = ""
          agentString = this.agentData.find(function (entry) {if(entry.id == pathArray[6]) return entry.name })
          if(agentString != undefined) {
            agentString = agentString.name
          } else {
            agentString = "Undefined Agent"
          }
          breadCrumbs.push({
            text: "Agent: " + agentString,
            disabled: true,
            href: '#',
          })
        }
      } else if(this.reportSchedule.export_path == 2) {
        breadCrumbs.push({
          text: "Agent",
          disabled: true,
          href: '#',
        })
      }

      // final bit to say if its a data delivery or a scorecard pdf
      if(this.reportSchedule.export_path == 3) {
        if(this.getPdfDelivery) {
          breadCrumbs.push({
            text: "Scorecard",
            disabled: true,
            href: '#',
          })
        } else if(typeof pathArray[6] !== 'undefined'){
          breadCrumbs.push({
            text: "Calls",
            disabled: true,
            href: '#',
          })
        }
      }

      return breadCrumbs
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    properCase(str) {
      return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
    },
    adjustDropDowns() {
      if(this.showScheduledExportsModal) {
        if(this.isNew) {
          this.reportSchedule.export_path = this.exportPath
        }
        let agentId = Number(this.$route.params.agentId)
        this.methods = ['Email']
        if(this.isNew) {
          if(!this.pdfDelivery) this.methods.push('SFTP')
          // if we're looking at an agent, we must disable "all agent"
          if(agentId == 0) {
            this.methods.push('Email (Per Agent)')
          }
          this.reportSchedule.delivery_type = this.methods[0]
        } else {
          if(!this.reportSchedule.pdf_delivery) this.methods.push('SFTP')
          if(this.reportSchedule.agent_id == 0) {
            this.methods.push('Email (Per Agent)')
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('filters', [
      'all',
    ]),
    ...mapGetters('resources', [
      'agentData',
      'agentDataLoadingStatus',
    ]),
    ...mapGetters('dynaLabels', [
      'dynaLabelsList',
      'dynaLabelsListLoadingStatus',
    ]),
    ...mapGetters('insights', [
      'insightsListData',
      'insightsListDataLoadingStatus',
    ]),
    ...mapGetters('scorecardsV2', [
      'newScorecardsList',
      'newScorecardsListLoadingStatus',
    ]),
    showFileSelectors() {
      if(this.reportSchedule.delivery_type=='SFTP' || this.getPdfDelivery) return false
      return true
    },
    getDeliveryMethodMessages() {
      let msg = ''
      // let glue = ''
      if(this.getPdfDelivery) {
        msg += 'SFTP is not available for scorecard PDF.'
        // glue = ' '
      }
      // something else
      return msg
    },
    getFileOptions() {
      let fileObject = Object.entries(this.reportSchedule.delivery_files).map(([key, value]) => ({
        key: key,
        value: value
      }))

      if(this.reportSchedule.export_path != 3) {
        fileObject = fileObject.filter(x => x.key == "summary" || x.key == "detail")
      } 

      return fileObject
    },
    getPdfDelivery() {
      return this.pdfDelivery || Boolean(this.reportSchedule.pdf_delivery)
    },
    getTitle() {
      return (this.isNew) ? 'Schedule Report Delivery' : 'Scheduled Report Delivery'
    },
    getDateQuery() {
      let adHoc = ""

      // if the delivery definition is not new check the ad hoc filters set in the definition
      // if the delivery definition is new and the user has ad hoc filters selected use those
      // otherwise dont include them
      if(!this.isNew && (this.reportSchedule.phrase != "" && this.reportSchedule.phrase != undefined)) {
        adHoc += "&phrase=" + this.reportSchedule.phrase
      } else if(this.isNew && this.$route.query.phrase != undefined){
        adHoc += "&phrase=" + this.$route.query.phrase
      }

      if(!this.isNew &&  (this.reportSchedule.speaker != "" && this.reportSchedule.speaker != undefined)) {
        adHoc += "&speaker=" + this.reportSchedule.speaker
      } else if(this.isNew && this.$route.query.speaker != undefined){
        adHoc += "&speaker=" + this.$route.query.speaker
      }

      let startDate = ''
      let endDate = ''
      let isoToday = moment().isoWeekday()
      let isoDeliveryDay = moment().day(this.reportSchedule.delivery_day).isoWeekday()
      switch(this.reportSchedule.frequency){
        case "Daily":
          startDate = moment().subtract(1, 'day').format('YYYY-MM-DD')
          endDate = moment().subtract(1, 'day').format('YYYY-MM-DD')
          break
        case "Weekly":
          if(isoToday<isoDeliveryDay) {
            // rewind a week for the last valid range with data
            startDate = moment().day(this.reportSchedule.delivery_day).subtract(2, 'week').format('YYYY-MM-DD')
            endDate = moment().day(this.reportSchedule.delivery_day).subtract(1, 'week').format('YYYY-MM-DD')
          } else {
            startDate = moment().day(this.reportSchedule.delivery_day).subtract(1, 'week').format('YYYY-MM-DD')
            endDate = moment().day(this.reportSchedule.delivery_day).subtract(1, 'day').format('YYYY-MM-DD')
          }
          break
        case "Monthly":
          startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
          endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
          break
      }

      return `?start_date=${startDate}&end_date=${endDate}${adHoc}`
    },
  },

  watch: {
    target: 'updateReportSchedule',
    showScheduledExportsModal: 'adjustDropDowns'
  },
  mounted() {
    if(this.isNew) {
      this.reportSchedule.export_path = this.exportPath
    }
  }
};
</script>
<style scoped>

  ::v-deep .v-text-field__slot input#schedule-hour {
    text-align: center !important;
  }

  .hidden {
    display: none;
  }

  .recipient-alert {
    font-size: .75rem;
  }

  .recipient-list {
    min-height: 4rem;
  }

  .content-message {
    font-size:.75rem;
    font-style: italic;
    font-weight: 700;
  }

  .notation {
    color: rgba(0, 0, 0, .5);
    font-size: .75rem;
  }

  .checkboxLabel {
    color: black;
    font-weight: bold;
    font-size: 1rem;
  }

</style>

