<template>
  <v-menu offset-y bottom right open-on-hover close-delay="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab small color="primary" v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item v-if="!disableExports" link @click="buttonClick('downloadExport')">
        <v-list-item-icon class="grey--text text--darken-1 mr-2">
          <custom-icon name="download-export"></custom-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="grey--text text--darken-1">Download Report</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-icon class="grey--text text--lighten-1 mr-2">
          <custom-icon name="download-export"></custom-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="grey--text text--lighten-1">Download Report</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- TODO: commented out until we implement a way to email exports directly from the UI
      <v-list-item link @click="$emit('exportClick', 'emailExport')">
        <v-list-item-icon class="grey--text text--darken-1 mr-2">
          <custom-icon name="email-export"></custom-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="grey--text text--darken-1">Email Report</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->
      <v-list-item v-if="!disableDeliveries" link @click="buttonClick('scheduleExport')">
        <v-list-item-icon class="grey--text text--darken-1 mr-2">
          <custom-icon name="schedule-export"></custom-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="grey--text text--darken-1">Schedule Report Delivery</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-icon class="grey--text text--lighten-1 mr-2">
          <custom-icon name="schedule-export"></custom-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="grey--text text--lighten-1">Schedule Report Delivery</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    disableDeliveries: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableExports: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  methods: {
    buttonClick(button) {
      this.$emit('exportClick', button)
    }
  }
}
</script>
