<template>
  <v-content style="height: calc(100%); max-height: calc(100%);" id="my-content">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
      <v-row style="height: 100vh; max-height: calc(100%);">
        <v-col cols="2" class="pb-12">
      <v-navigation-drawer
            width="auto"
            style="height: 100%; max-height: calc(100%); padding: 0 0 20px 10px;"
          >
        <v-list nav dense>
          <v-subheader>SETUP FAQ MENU</v-subheader>
              <v-list-item-group
              v-model="selectedItem"
              color="primary"
              mandatory
              >
                <v-list-item>
                  <v-list-item-title>Dynamic Labels</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Access Roles</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Insight Definitions</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Scorecard Definitions</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="cfOnlyUser">
                  <v-list-item-title>Coaching Definitions</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      </v-col>
      <v-col v-show="selectedItem == 0" class="pb-12">
        <f-a-q-card :items = "dynaDefItems" :title = "'Dynamic Labels'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 1" class="pb-12">
        <f-a-q-card :items = "accessRoleDefItems" :title = "'Access Roles'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 2" class="pb-12">
        <f-a-q-card :items = "insightDefItems" :title = "'Insight Definitions'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 3" class="pb-12">
        <f-a-q-card :items = "scorecardDefItems" :title = "'Scorecard Definitions'"></f-a-q-card>
      </v-col>
      <v-col v-show="selectedItem == 4 && cfOnlyUser" class="pb-12">
        <f-a-q-card :items = "coachingDefItems" :title = "'Coaching Definitions'"></f-a-q-card>
      </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>

  import FAQCard from "@/components/FAQCard.vue";
  import {mapGetters} from "vuex"

export default{
  components: {
      FAQCard,
    },

  data () {
		return {
      selectedItem: parseInt(this.$route.params.pageid) || 0,
		  dynaDefItems: [
        { question: "What is a dynamic label?",
          answer: "A dynamic label allows you to group or categorize a set of calls by specific call criteria. Dynamic labels are then associated with insights and scorecards. <br /><br />" +
                  "Example: You can create a dynamic label named “New Agents” that is defined with the appropriate agent picklist selection. You can also create a separate dynamic label named “Existing Agents” that is defined with the balance of the agent picklist selection. Refer to FAQs related to Insight and Scorecard Definitions to learn more about how to link them to existing dynamic labels.",
        },
        { question: "Are there any recommended or suggested best practices related to dynamic labels?",
          answer: "It all depends on your calls of interest. Administrators can create labels to identify teams, new vs. existing agents, call direction, call types or reasons, dispositions, transactions, sub-clients, transfers, holds, wrap-up codes, first call resolution and/or marketing information. Advanced users can achieve their preferred level of granularity – i.e., new agents and transfers, new agents and escalations, new agents and first call resolution, the sales team with missed phrases, etc."
        },
        { question: "Why am I unable to gain access to all dynamic label options? Why do some of them appear in gray?",
          answer: "Only dynamic label fields with 1-200 picklist options are shown, excluding agents which are unlimited. Numeric dynamic label fields must have data populated other than empty or zero across all available calls. Contact your CallFinder analyst if you are interested in populating additional dynamic label fields as part of your customized integration."
        },
        { question: "How are dynamic label picklist options derived?",
          answer: "The unique list of populated metadata information across all available calls is presented."
        },
        { question: " Can I create a dynamic label to find calls that are not identified in my metadata? ",
          answer: "If there are identifiable phrases that indicate a particular call type, you can work with your assigned analyst to create a new search. A dynamic label can be tied to the search results, either hits or non-hits."
        },
        { question: "Why is there a processing indicator appearing within my dynamic label?",
          answer: "Dynamic labels are “dynamic.” If your dynamic label is tied to a search, and a change is made to the underlying search definition, you will be temporarily restricted from making a change to that dynamic label."
        },
        { question: "When I publish a change to an existing dynamic label or create a new one, will the definition apply to new or historical calls?",
          answer: "Both new and historical calls will be reassessed against a new dynamic label definition when it is published."
        },
        { question: "Why am I unable to delete an existing dynamic label?",
          answer: "A dynamic label must be removed from all Insight and Scorecard Definitions before it can be deleted."
        },
        { question: "Why are new dynamic label properties not being reflected in old calls?",
          answer: "New field/label properties will only be reflected from the date new information was added/populated as part of your customized integration."
        },
        { question: "When should I use AND versus OR within or between my dynamic label definition boxes?",
          answer: "If <strong>AND</strong> is selected, each condition must evaluate to true in order for a call to be tagged with the dynamic label.<br/>If <strong>OR</strong> is selected, one or more conditions must evaluate to true in order for a call to be tagged with the dynamic label.<br /><br />" +
                  "<em>Example 1:</em><br />" +
            "Direction includes inbound<br/>" +
            "<strong>AND</strong><br/>" +
            "Agent includes Ted Jackson, William Phillips<br/>" +
            "<p style='font-size: .8rem; margin-top: 1rem; line-height: 1.2rem;'>" +
            "Call A is an inbound call for Ted Jackson<br/>" +
            "Direction = inbound -> TRUE <br/>" +
            "Agent = Ted Jackson, William Phillips -> TRUE<br/>" +
            "Since both conditions evaluate to true, Call A <strong>would</strong> be tagged with the dynamic label.<br/>" +
            "<br/>" +
            "Call B is an inbound call for Anna MacDonald<br/>" +
            "Direction = inbound -> TRUE <br/>" +
            "Agent = Ted Jackson, William Phillips -> FALSE<br/>" +
            "Since both conditions do not evaluate to true, Call B <strong>would not</strong> be tagged with the dynamic label.<br/>" +
            "<br/>" +
            "Call C is an outbound call for Anna MacDonald<br/>" +
            "Direction = inbound -> FALSE<br/>" +
            "Agent = Ted Jackson, William Phillips -> FALSE<br/>" +
            "Since both conditions do not evaluate to true, Call C <strong>would not</strong> be tagged with the dynamic label.</p><br/>" +
            "<em>Example 2:</em><br />" +
              "Direction includes inbound<br/>" +
            "<strong>OR</strong><br/>" +
            "Agent includes Ted Jackson, William Phillips<br/>" +
            "<p style='font-size: .8rem; margin-top: 1rem; line-height: 1.2rem;'>" +
            "Call A is an inbound call for Ted Jackson<br/>" +
            "Direction = inbound -> TRUE <br/>" +
            "Agent = Ted Jackson, William Phillips -> TRUE<br/>" +
            "Since one or more conditions evaluate to true, Call A <strong>would</strong> be tagged with the dynamic label.<br/>" +
            "<br/>" +
            "Call B is an inbound call for Anna MacDonald<br/>" +
            "Direction = inbound -> TRUE <br/>" +
            "Agent = Ted Jackson, William Phillips -> FALSE<br/>" +
            "Since one or more conditions evaluate to true, Call B <strong>would</strong> be tagged with the dynamic label.<br/>" +
            "<br/>" +
            "Call C is an outbound call for Anna MacDonald<br/>" +
            "Direction = inbound -> FALSE<br/>" +
            "Agent = Ted Jackson, William Phillips -> FALSE<br/>" +
            "Since both conditions do not evaluate to true, Call C <strong>would not</strong> be tagged with the dynamic label.</p><br/>" +
            "<em>Example 3:</em><br />" +
              "Direction excludes inbound<br/>" +
            "<strong>AND</strong><br/>" +
            "Agent excludes Ted Jackson, William Phillips<br/>" +
            "<p style='font-size: .8rem; margin-top: 1rem; line-height: 1.2rem;'>" +
            "Call A is an inbound call for Ted Jackson<br/>" +
            "Direction <> inbound -> FALSE <br/>" +
            "Agent <> Ted Jackson, William Phillips -> FALSE<br/>" +
            "Since both conditions do not evaluate to true, Call A <strong>would not</strong> be tagged with the dynamic label.<br/>" +
            "<br/>" +
            "Call B is an inbound call for Anna MacDonald<br/>" +
            "Direction <> inbound -> FALSE <br/>" +
            "Agent <> Ted Jackson, William Phillips -> FALSE<br/>" +
            "Since both conditions do not evaluate to true, Call B <strong>would not</strong> be tagged with the dynamic label.<br/>" +
            "<br/>" +
            "Call C is an outbound call for Anna MacDonald<br/>" +
            "Direction <> inbound -> TRUE<br/>" +
            "Agent <> Ted Jackson, William Phillips -> TRUE<br/>" +
            "Since both conditions evaluate to true, Call C <strong>would</strong> be tagged with the dynamic label.</p><br/>" +
            "<em>Example 4:</em><br />" +
            "Direction excludes inbound<br/>" +
            "<strong>OR</strong><br/>" +
            "Agent excludes Ted Jackson, William Phillips<br/>" +
            "<p style='font-size: .8rem; margin-top: 1rem; line-height: 1.2rem;'>" +
            "Call A is an inbound call for Ted Jackson<br/>" +
            "Direction <> inbound -> FALSE <br/>" +
            "Agent <> Ted Jackson, William Phillips -> FALSE<br/>" +
            "Since both conditions do not evaluate to true, Call A <strong>would not</strong> be tagged with the dynamic label.<br/>" +
            "<br/>" +
            "Call B is an inbound call for Anna MacDonald<br/>" +
            "Direction <> inbound -> FALSE <br/>" +
            "Agent <> Ted Jackson, William Phillips -> TRUE<br/>" +
            "Since one or more conditions evaluate to true, Call B <strong>would</strong> be tagged with the dynamic label.<br/>" +
            "<br/>" +
            "Call C is an outbound call for Anna MacDonald<br/>" +
            "Direction <> inbound -> TRUE<br/>" +
            "Agent <> Ted Jackson, William Phillips -> TRUE<br/>" +
            "Since one or more conditions evaluate to true, Call C <strong>would</strong> be tagged with the dynamic label.</p><br/>"
        },
      ],
      accessRoleDefItems: [
        { question: "What is an access role?",
          answer: "An access role allows you to group or categorize your internal or third-party users. <br /><br /> Example: If each QA team lead should have call visibility restricted to a separate set of calls, create an access role identifying each unique access group."
        },
        { question: "Are there any recommended or suggested best practices related to access roles?",
          answer: "It all depends on your corporate hierarchy. Access roles can be customized to identify your executive team, different business units, directors, QA team leads, and supervisors. CallFinder recommends the creation of an access role defined to your adminstrator exclusively. This will allow your administrator to create and publish new insight(s) and scorecard definition(s) for sole visibility and testing. The drafted insight(s) and scorecard(s) can subsequently be shared with other access roles once approved."
        },
        { question: "How are access roles used in the platform?",
          answer: "Each insight or scorecard definition is ultimately shared with an access role so you can control user visibility."
        },
        { question: " If I make a change to the users included within an access role, what happens to access role shares specified within Insight and Scorecard Definitions?",
          answer: "If you remove a user from an access role, they will no longer have access to any insights or scorecards, which are shared to the access role. If you add a user to an access role, they will automatically gain access to any insights or scorecards which are already shared with the access role."
        },
        { question: "Why am I unable to delete an existing access role?",
          answer: "An access role must be removed from all Insight and Scorecard Definitions before it can be deleted."
        },
      ],
      insightDefItems: [
        { question: "What is an insight definition?",
          answer: "Insight Definitions allow you to easily pair dynamic labels with access roles. <br /> <br /> Example: If one QA team lead is responsible for coaching both new agents and existing agents for Team A, you can select two separate dynamic labels which identify “New Agents” and “Existing Agents” for Team A and share with the access role tied to the user or users which should have access to Team A's calls."
        },
        { question: "Are there any recommended or suggested best practices related to insight definitions?",
          answer: "CallFinder recommends tying more than one dynamic label to each insight definition as it allows a user to see differences between dynamic labels or any desired call groups at a glance, while continuing to allow users to navigate efficiently. <br /> <br /> For example, instead of tying an insight definition to one dynamic label for new agents, consider more granular options – new agent transfer calls and new agent non-transfer calls."
        },
        { question: "If I make a change to the users included within an access role tied to an insight definition, will the insight output automatically reflect the change?",
          answer: "Yes, the insight definition output automatically reflects any changes to the access role."
        },
        { question: "If I make a change to a dynamic label tied to an insight definition, will the insight output automatically reflect the change once the dynamic label change is processed?",
          answer: "Yes, the insight definition output automatically reflects any changes to the insight defined dynamic label."
        },
        { question: "Does it take time to process my insight definition?",
          answer: "The insight definition does not require processing time. However, the dynamic labels included within your insight definition require processing. Therefore, your insight counts may change after your dynamic label change is fully processed."
        },
        { question: "Why do some, but not all, of my insight definitions produce a dynamic label summary section within the output?",
          answer: "If more than one dynamic label is selected within your insight definition, it will automatically include a dynamic label summary."
        },
      ],
      scorecardDefItems: [
        { question: "What is a Scorecard definition?",
          answer: "Scorecard Definitions allow you to connect dynamic labels with access roles and customized scoring rubrics.  Your customized scorecard can include an unlimited number of categories (and affiliated subcategories) with automatic (Acuity clients only) and/or manual scoring options. <br /><br />" +
                  "Example: If one QA team is responsible for coaching both new agents and existing agents for Team A, you can select two separate dynamic labels which identify “New Agents” and “Existing Agents” for Team A and share with the access role tied to the user or users which should have access to Team A's calls. The scorecard categories can be customized to mirror your existing manual scorecard structure or layout."
        },
        { question: "How many categories or subcategories can be on one scorecard?",
          answer: "A scorecard can have up to 40 categories and 20 subcategories as a maximum."
        },
        { question: "Are there any recommended or suggested best practices related to scorecard definitions?",
          answer: "CallFinder recommends tying more than one dynamic label to each scorecard definition as it allows a user to see differences between the dynamic labels or any desired call groups at a glance, while continuing to allow users to navigate efficiently.  For example, instead of defining a scorecard to one dynamic label for New Agents, consider including multiple dynamic labels within the definition, such as New Agents and another dynamic label tied to Existing Agents. <br /><br />" +
                  "CallFinder recommends including manual only categories for non-speech related requirements.  Oftentimes, existing manual only scorecard categories may include post call actions, such as whether the agent dispositioned the call correctly, whether they entered a note, etc.  CallFinder offers the ability to add one or more manual only categories and related subcategories so users can continue to get a comprehensive view for a portion of selected calls."
        },
        { question: "If I make a change to the users included within an access role tied to a scorecard definition, will the scorecard output automatically reflect the change?",
          answer: "Yes, the scorecard definition output will automatically reflect any changes made to a scorecard-defined access role."
        },
        { question: "If I make a change to a dynamic label tied to a scorecard definition, will the scorecard output automatically reflect the change once the dynamic label change is processed?",
          answer: "Yes, the scorecard definition output will automatically reflect any changes made to a scorecard-defined dynamic label."
        },
        { question: "Why are the updates to my scorecard definition taking a long time to process?",
          answer: "Any change to the scorecard definition, other than the scorecard name, description or access role requires processing.  In addition, scorecard definitions are automatically reprocessed each time a change is made to a related dynamic label or automated category search.  Processing time will vary depending on the count of historical calls."
        },
        { question: "If I add or remove a category from an existing scorecard, will it affect historical call scores?",
          answer: "Unless you take special precautions (see # x below), historical call scores will be adjusted when categories are added or removed from a scorecard definition."
        },
        { question: "What is the difference between the Dynamic Label Selections option located at the top of the scorecard definition and the Category Dynamic Label option within each Category?",
          answer: "The Dynamic Label Selections option located at the top of the scorecard definition determines which calls will be scored as part of your definition.  The Category Dynamic Label option specifies whether a particular category applies when scoring each call. <br /><br />" +
                  "For example, you may have new agent and existing agent groups handling calls for both incoming prospect and clients calls.  For prospect calls, no verification is required.  For client calls, verification is required. <br /><br />" +
                  "The Dynamic Label Selection option should include predefined dynamic labels for “New Agents” and “Existing Agents”.  The Verification Category Dynamic Label should be assigned to a predefined Dynamic Label for “Client Call”."
        },
        { question: "What is the difference between the Redistribute Weight vs. Score 100% under the Non-applicable category scoring option?",
          answer: "If the Redistribute Weight option is selected, a call with a mix of applicable and non-applicable categories will only be scored according to the category weights and scoring rules associated with the applicable categories. (Category weights for non-applicable categories will not contribute to the overall call score.) <br /><br />" +
                  "If the Score 100% option is selected, this same call will be scored according to all category weights and scoring rules.  The non-applicable categories will be automatically granted a score of 100%. (Category weights for the non-applicable categories will contribute to the overall call score.) <br /><br />" +
                  "When manually calculating scores, changes to a scorecard structure typically do not affect historical call scores.   With CallFinder, historical call scores are automatically recalculated based on the new scorecard structure.  Selecting the Redistribute Weight option provides the most long-term flexibility; especially when you consider the possibility of scorecard structure changes in the future."
        },
        { question: "How can I add a new scorecard category without altering existing historical scores?",
          answer: "CallFinder recommends creating all scorecards with the non-applicable category scoring option set to Redistribute Weight.  When a new category is needed, create a dynamic label tied to call date. <br /> <br />" +
                  "For example, if you are adding a category effective January 1, create a dynamic label defined with call date > 12/31.  Following add the new category to the scorecard and set the category dynamic label to the dynamic label defined with call date > 12/31.  If the added category only applies to a portion of calls in addition to the call date selection, be sure to make a consolidated label representing the appropriate combination.  (The category dynamic label can be cleared 90 days later, once all of your historical calls age out of the platform.)"
        },
        { question: "How can I delete a new scorecard category without altering existing historical scores?",
          answer: "CallFinder recommends creating all scorecards with the Non-applicable category scoring option set to Redistribute Weight.  When an existing category is no longer needed, create a dynamic label tied to call date. <br /> <br />" +
                  "January 1, create a dynamic label defined with call date < 1/1.  Following update the category dynamic label selection to the dynamic label defined with a call date < 1/1.  You can also drag and drop the phased-out category to the bottom of the scorecard.  It can be removed altogether 90 days later, once your historical calls age out of the platform."
        },
        { question: "How can I change the order of the categories?",
          answer: "Before attempting to change the order of your categories, please be sure to be in a collapsed view across the entire scorecard.  Each category within your scorecard can be moved up or down by grabbing the top of it and dragging it to your desired location. "
        },
        { question: "How can I change the order of subcategories within a category or across different categories?",
          answer: "Subcategories are not able to be moved at this time. "
        },
        { question: "What is the recommended best practice for creating a manual only scorecard?",
          answer: "CallFinder recommends selecting the “Non Applicable Category Default” as the category dynamic label for each and every category.  This will allow users to select calls to manually score and immediately see the calculated results without having to filter to adjusted calls."
        },
        { question: "What is the recommended best practice for identifying all reviewed calls, especially when you consider some do not require any adjustments? ",
          answer: "CallFinder recommends creating a dynamic label defined to exclude all calls (i.e. create a “Not Applicable” dynamic label defined with direction <> inbound or outbound.  Create a “Reviewed” Category with a category weight of 0, a category dynamic label = “Not Applicable” and a manual subcategory = checked.  Instruct your users to always adjust the category to applicable regardless of whether other adjustments are made.  In order to gain visibility into the adjusted calls only, make the appropriate selection within your filters."
        },
        { question: "Why am I unable to make adjustments to a call scorecard or exclude a call from a scorecard? ",
          answer: "Users must be authorized by their administrator before they can make call scorecard adjustments or exclude calls from a scorecard."
        },
      ],
      coachingDefItems: [
      
      ],
    }
  },
  computed: {
    ...mapGetters("users", [
      "cfOnlyUser",
    ]),
    setRoute() {
      this.selectedItem = 2;
      return this.selectedItem;
    }
  },
  methods: {
  }
}

</script>

<style>
  .verticalTab {
    justify-content: left !important;
    padding-right: 7rem;
    height: 44px;
  }

</style>
