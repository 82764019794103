<template>
  <v-app v-if="!isPDFGenerator()" id="callfinder">

    <v-app-bar app dark class="cf-background nav-element" height="128">

      <v-toolbar-title class="nav-element">
        <router-link :to="{ name: 'signin', query: { page: 'signin_form' } }" @click="this.stopSpinny">
          <img src="@/assets/2021-CallFinder-Logo-WEB-White-Alt.svg" height="130" alt="">
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div style="width:40%;">If you have any questions or problems regarding your account please email <a href="mailto:customerservice@mycallfinder.com" style="color:#fff;">customerservice@mycallfinder.com</a> or call 800-639-1700.</div>

    </v-app-bar>

    <v-content>
      <v-row fluid class="fill-height" align="center" justify="center">

        <!-- New user registration form -->
        <v-col v-if="getPage == 'activate'" cols="4" class="pl-12 pt-0 pr-12 pb-0">

          <h1>New User Registration - Enter your Email</h1>
          <p>Please enter your email address to activate your user profile. We will send a verification message and instructions on how to complete the process.</p>

          <form @submit.prevent
            id="new_user_registration_form"
            @submit="this.newUserRegistration"
          >

            <v-alert v-if="authenticationErrorMessage" type="error">
              {{ authenticationErrorMessage }}
            </v-alert>

            <v-text-field
              outlined
              v-model="email"
              label="New User Email"
              placeholder="you@email.com"
              required
            ></v-text-field>

            <v-layout align-center justify-center>
              <v-btn width="250" color="accent darken-3" type="submit" :loading="this.signingIn" :disabled="this.csrfIsLoading">Request User Registration</v-btn>
            </v-layout>

          </form>

        </v-col>

        <!-- Request password reset form -->
        <v-col v-else-if="getPage == 'forgot'" cols="4" class="pl-12 pt-0 pr-12 pb-0">

          <h1>Forgot Your Password?</h1>
          <p>To reset your password, please enter the email address associated with your account profile. We will send a verification message and instructions on how to complete the process.</p>

          <form @submit.prevent
            id="reset_password_form"
            @submit="this.forgotYourPassword"
          >
            <v-alert v-if="authenticationErrorMessage" type="error">
              {{ authenticationErrorMessage }}
            </v-alert>

            <v-text-field
              outlined
              v-model="email"
              label="Your Email"
              placeholder="you@email.com"
              required
              id="email"
            ></v-text-field>

            <v-layout align-center justify-center>
              <v-btn width="250" type="submit" color="accent darken-3" :loading="this.signingIn" :disabled="this.csrfIsLoading">Request Password Reset</v-btn>
            </v-layout>

          </form>

        </v-col>

        <!-- CSRF Token Invalid / Password Reset Success Page -->
        <v-col v-else-if="getPage == 'CSRFTokenInvalid' || getPage == 'resetSuccess'" cols="4" class="pl-12 pt-0 pr-12 pb-0">

          <h1 v-if="getPage == 'CSRFTokenInvalid'">Session Timed Out</h1>
          <h1 v-else-if="getPage == 'resetSuccess'">Successfully reset password</h1>

          <p v-if="getPage == 'CSRFTokenInvalid'">Please click the button to go back to Sign-In</p>
          <p v-else-if="getPage == 'resetSuccess'">Please click the button to go back to the sign-in page</p>

          <v-layout align-center justify-center>
            <v-btn width="250" @click="this.pageReset" color="accent darken-3" :loading="this.signingIn" :disabled="this.csrfIsLoading">Back to Sign-in</v-btn>
          </v-layout>
        </v-col>

        <!-- Check Your Email Page fyp / ftl -->
        <v-col v-else-if="getPage == 'checkEmailFyp' || getPage == 'checkEmailFtl'" cols="4" class="pl-12 pt-0 pr-12 pb-0">

          <h1>Please check your email</h1>
          <p v-if="getPage == 'checkEmailFyp'">If there is a user profile associated with the provided email address, you'll receive an email with instructions on how to reset your password</p>
          <p v-else-if="getPage == 'checkEmailFtl'">If there is a user profile associated with the provided email address, you'll receive an email with instructions on how to set your password</p>
          <!-- Commented out button. It doesnt really make sense to allow them to go back to the login page.
          <v-layout align-center justify-center>
            <v-btn width="250" :to="{ name: 'signin', query: { page: 'signin_form' } }" color="accent darken-3" :loading="this.signingIn">Back to Sign-in</v-btn>
          </v-layout>
          -->

        </v-col>

        <!-- Password set Form -->
        <v-col v-else-if="getPage == 'fyp' || getPage == 'ftl'" cols="4" class="pl-12 pt-0 pr-12 pb-0">

          <h1>Set your Password</h1>
          <p>Your password must be 8 to 20 characters long and consist of upper and lowercase letters, numbers, with optional special characters.</p>

          <form @submit.prevent
            id="set_new_password_fyp"
            @submit="this.setPassword"
          >
            <v-alert v-if="authenticationErrorMessage" type="error">
              {{ authenticationErrorMessage }}
            </v-alert>
            <v-text-field
              outlined
              v-model="passwordReset1"
              label="New Password"
              placeholder="new password"
              required
              :type="showPW ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPW = !showPW"
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="passwordReset2"
              label="Re-Enter Your Password"
              placeholder="re-enter your password"
              required
              :type="showPW ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPW = !showPW"
              autocomplete="off"
            ></v-text-field>
            <v-layout align-center justify-center>
              <v-btn width="200" type="submit" color="accent darken-3" :loading="this.signingIn" :disabled="this.csrfIsLoading">Set Your Password</v-btn>
            </v-layout>
          </form>

        </v-col>

        <!-- Signin form -->
        <v-col v-else cols="4" class="pl-12 pt-0 pr-12 pb-0">
          <h1>Welcome to CallFinder<sup>&reg;</sup></h1>
          <p>Please sign in&hellip;</p>
          <form @submit.prevent
            id="signin_form"
            @submit="this.signMeIn"
          >
            <v-alert v-if="authenticationErrorMessage" type="error">
              {{ authenticationErrorMessage }}
            </v-alert>
            <v-text-field
              outlined
              v-model="username"
              label="Username"
              placeholder="username"
              required
              prepend-icon="mdi-account"
              id="username"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="password"
              label="Password"
              placeholder="password"
              required
              :type="showPW ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPW = !showPW"
              autocomplete="off"
              id="password"
            ></v-text-field>

            <v-layout align-center justify-center>
              <v-btn width="200" type="submit" color="accent darken-3" :loading="this.signingIn" :disabled="this.csrfIsLoading">Sign In</v-btn>
            </v-layout>

            <v-layout align-center justify-center>
              <v-btn class="ma-2 no-text-transform" text id="to_forgot_password"
                     :to="{ name: 'signin', query: { page: 'forgot' } }">Forgot Your Password?
              </v-btn>
            </v-layout>

            <v-layout align-center justify-center>
              <v-checkbox
                :label="`Remember me on this computer`"
              ></v-checkbox>
            </v-layout>

          </form>

          <v-layout align-center justify-center>
            <span class="caption">
              First time login? <a href="/signin?page=activate">Set your password &raquo;</a>
            </span>
          </v-layout>

        </v-col>

        <v-col cols="8" class="pa-0">

          <div class="d-flex fill-height" :style="getBackgroundImage()"></div>

        </v-col>

      </v-row>
    </v-content>


    <v-footer app class="nav-element" id="appFooter">
      <v-row>
        <v-col cols="12">
          <v-row no-gutters align="center" justify="center">
            <v-btn class="mx-2 no-text-transform" color="white" small text rounded
                   title="CallFinder® Home Page"
                   href="https://www.mycallfinder.com">Copyright &copy; 2019, CallFinder® 1-800-639-1700
            </v-btn>
            <v-btn class="mx-2" color="white" small text rounded
                   href="/privacy-policy">Privacy Policy
            </v-btn>
            <v-btn class="mx-2" color="white" small text rounded
                   href="mailto:customerservice@mycallfinder.com">Email Customer Service
            </v-btn>
            <v-btn class="mx-2" color="white" small text rounded
                   href="https://findmycalls.com/software_license.html">Software License Agreement
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>

  </v-app>
  <p v-else>PDF generation failed.</p>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import {emailRegex, passwordRegex} from "../../utils/GlobalRegex.js"

export default {
  name: "signin",
  data: function() {
    return {
      email: "",
      username: "",
      password: "",
      passwordReset1: "",
      passwordReset2: "",
      authKey: "",
      errormessage: "",
      showPW: false,
      backgroundImageHeight: '100 vh',
      backgroundImages: [
        'iMac_Desk_Scorecard_Row.jpg',
        'Macbook_Transparent_Scorecard_Row.jpg',
        'Office_Group_Laptop_Foreground_Agent_Insights.jpg',
        'Office_Group_Laptop_Foreground_Dynamic_Labels_Home_Screen.jpg',
        'Office_Group_Laptop_Foreground_Scorecard_Row.jpg',
        'Office_Group_Laptop_Foreground_Scorecard_Tiles.jpg',
        'Two_Women_Macbook_Agent_Insights.jpg',
        'Two_Women_Macbook_Scorecard_Tiles.jpg',
        'Woman_Analyzing_Data_Scorecard_Row.jpg',
      ],
      csrfIsLoading: false,
      signingIn: false,
      mfaLoading: false,
      csrfToken: "",
      userToken: "",
      prevPath: "",
      source: "",
      key: "",
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "isAuthenticated",
      "updateCSRF",
      "toCSRFInvalid",
      "goToCheckEmailFtl",
      "goToCheckEmailFyp",
      "goToPasswordSuccess",
      "goToSigninPage",
      "signInComplete",
      "signOutComplete",
      "authenticationErrorMessage",
      "newCSRFToken",
      "mfaImage",
      "mfaStatus",
      "mfaCheckedAndValid",
      "getUserToken",
    ]),
    ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),
    getPage() {
      return this.$route.query.page || ''
    }
  },
  methods: {
    ...mapActions("authentication", [
      "setSignInComplete",
      "setSignOutComplete",
      "setIsAuthenticated",
      "setUpdateCSRF",
      "setUserToken",
      "setToCSRFInvalid",
      "setGoToCheckEmailFtl",
      "setGoToCheckEmailFyp",
      "setGoToPasswordSuccess",
      "setGoToSigninPage",
      "setAuthenticationErrorMessage",
      "validateAndEmail",
      "validateAndPassword",
      "signIn",
      "signOut",
      "getMfaImage",
      "setMfaCheckedAndValid",
      "checkMfaSecret",
      "setToken",
      "deAuthConsumer",
    ]),
    ...mapActions("users", [
      "retrieveUserData",
      "retrieveAuthorizedUserListData",
      "resetUserData",
    ]),
    resetError() {
      this.setAuthenticationErrorMessage('')
    },
    signMeOut() {
      this.deAuthConsumer()
      this.resetUserData()
      this.csrfIsLoading = true
      this.csrfToken = this.getStringById("csrf_token");
      this.userToken = this.getStringById("user_token");
      this.prevPath = this.getStringById("prev_path");
      this.key = this.getStringById("key");

      // if just logged out reload to get token.
      if(this.csrfToken == "") {
        this.toSignin(true);
      } else {
        this.enableButtons()
      }

      this.stopTimer()
      this.stopSpinny()
      this.signOut()

      if (this.key != "") {
        this.setToken({key: this.key, csrfToken: this.csrfToken, type: this.$route.query.page})
        this.csrfIsLoading = true;
      }
    },
    signMeIn() {
      this.startSpinny()
      this.signIn({username: this.username, password: this.password, csrfToken: this.csrfToken});
    },
    pageReset() {
      //reload the page. depends how we want to do it though
      this.csrfIsLoading = true;
      this.signingIn = true;
      //reload the page
      this.toSignin(false);
    },
    toSignin(useHash) {
      let route = ''
      if(typeof this.prevPath == "undefined" || String(this.prevPath).substring(0,7) == "/signin"){
        route = "/insights"
      } else{
        route = this.prevPath
        if (useHash) route += window.location.hash
      }
      window.location.href = "/signin" + "?prev_path=" + String(route);
    },
    newUserRegistration() {
      this.startSpinny()
      if(this.validateEmail()) {
        this.source = "FTL"
        this.validateAndEmail({email: this.email, csrfToken: this.csrfToken, callSource: this.source});
      } else {
        this.setAuthenticationErrorMessage("'" + this.email + "' is not a valid email address.")
        this.stopSpinny();
      }
    },
    forgotYourPassword() {
      this.startSpinny()
      if(this.validateEmail()) {
        this.source = "FYP"
        this.validateAndEmail({email: this.email, csrfToken: this.csrfToken, callSource: this.source});
      } else {
        this.setAuthenticationErrorMessage("'" + this.email + "' is not a valid email address.")
        this.stopSpinny();
      }
    },
    validateEmail() {
      if (emailRegex.test(this.email)){
        return (true)
      }
      return (false)
    },
    setPassword() {
      this.startSpinny()
      // validate the password
      if(this.checkPassword()){
        this.userToken = this.getStringById("user_token");
        this.validateAndPassword({userToken: this.userToken, csrfToken: this.csrfToken, password: this.passwordReset1})
      } else {
        // bad password. error should be displayed automagically
        this.stopSpinny();
      }
    },
    checkPassword(){
      let errormessage = ''

      let validPassword = true;

      if(this.passwordReset1 != this.passwordReset2) {
        validPassword = false
        errormessage = "Passwords do not match"
      } else if(this.passwordReset1.length > 20) {
        validPassword = false
        errormessage = "Password was over the maximum length allowed."
      } else if(this.passwordReset1.length < 8) {
        validPassword = false
        errormessage = "Password was under the minimum length allowed."
      } else if (!(passwordRegex.test(this.passwordReset1))) {
        validPassword = false
        errormessage = "Password had characters in it that were not allowed."
      } else if(!this.validPasswordString(this.passwordReset1)) {
        validPassword = false
        errormessage = "Password does not contain the proper mix of characters."
      }
      if(!validPassword){
        this.setAuthenticationErrorMessage(errormessage);
      }
      return validPassword;
    },
    validPasswordString(pw) {
      let lcMatches = Boolean(pw.match(/[a-z]/g))
      let ucMatches = Boolean(pw.match(/[A-Z]/g))
      let numMatches = Boolean(pw.match(/[0-9]/g))
      return (lcMatches && ucMatches && numMatches)
    },
    stopSpinny() {
      if(this.authenticationErrorMessage != "") {
        this.signingIn = false
      }
    },
    startSpinny() {
      this.signingIn = true
      this.setAuthenticationErrorMessage("");
    },
    enableButtons() {
      this.csrfIsLoading = false
    },
    goToCSRFInvalid() {
      if(this.toCSRFInvalid) {
        this.setToCSRFInvalid(false)
        //token timed out
        this.$router.push("/signin?page=CSRFTokenInvalid").catch(() => {})
        this.stopSpinny()
        this.enableButtons();
      }
    },
    toCheckEmailFtl(){
      if(this.goToCheckEmailFtl) {
        this.setGoToCheckEmailFtl(false);
        // go to page=checkEmailFtl
        this.$router.push("/signin?page=checkEmailFtl").catch(() => {})
        this.signingIn = false;
      }
    },
    toCheckEmailFyp(){
      if(this.goToCheckEmailFyp) {
        this.setGoToCheckEmailFyp(false);
        // go to page=checkEmailFyp
        this.$router.push("/signin?page=checkEmailFyp").catch(() => {})
        this.signingIn = false;
      }
    },
    toPasswordSuccess(){
      if(this.goToPasswordSuccess) {
        this.stopSpinny()
        this.signingIn = false;
        this.$router.replace("/signin?page=resetSuccess").catch(() => {})
      }
    },
    toSigninPage() {
      if(this.goToSigninPage) {
        this.setGoToSigninPage(false);
        this.$router.push("/signin?page=signin").catch(() => {})
        this.enableButtons();
      }
    },
    updateCSRFToken() {
      if(this.updateCSRF) {
        this.setUpdateCSRF(false)
        this.csrfToken = this.getStringById("csrf_token");
        this.enableButtons();
      }
    },
    updateUserToken() {
      if(this.setUserToken) {
        this.setUserToken(false)
        this.userToken = this.getStringById("user_token");
        this.enableButtons()
      }
    },
    advanceRoute() {
      let routeTo = '/'

      if(this.$route.query.prev_path != "") {
        this.prevPath = this.$route.query.prev_path
      }
      // logic for sending the user back to the page they were on
      if(this.signInComplete) {
        if(typeof this.prevPath == "undefined" || String(this.prevPath).substring(0,7) == "/signin" || String(this.prevPath).substring(0,8) == "/mfapage"){
          routeTo = "/insights"
        } else if(String(this.prevPath).substring(0,1) == "\\") {
          routeTo = "/" + String(this.prevPath).substring(1)
        } else if(String(this.prevPath).substring(0,1) != "/")  {
          routeTo = "/" + this.prevPath
        } else {
          routeTo = this.prevPath
        }
        this.setSignInComplete(false)

        // make sure there is a slash in the route
        if(routeTo.substring(0,1) != "/" && routeTo.substring(0,1) != "\\" ) {
          routeTo = "/" + routeTo;
        }

        // make sure the path is relative-only
        if (new RegExp('^([a-z]+://|//)', 'i').test(this.prevPath)) {
          routeTo = this.prevPath = '/';
        }

        // get query from prev path.

        let query={};
        if(typeof this.prevPath == "undefined") {
          this.prevPath = ""
        }

        if(this.prevPath != "") {
          let prevPathNoHash = this.prevPath.split('#')[0]
          let prevPathSplit = prevPathNoHash.split('?')
          if(prevPathSplit.length > 1) {
            let queryParams = prevPathSplit[1].split('&')
            queryParams.forEach((element) => {
              let element_arr = element.split('=')
              query[element_arr[0]] = element_arr[1]
            })
          }
        }

        if(this.mfaStatus == "off") {
          this.retrieveUserData()
          this.resetTimer()
          this.csrfToken = ""
          this.$router.push({ path: routeTo, query: query}).catch(() => {})
          this.stopSpinny();
        } else if(this.mfaStatus == "setup"){
          this.getMfaImage({username: this.username, password: this.password, csrfToken: this.newCSRFToken});
        } else if (this.mfaStatus == "on"){
          // go to mfa
          this.toMfaPage();
        }
      }
    },
    getBackgroundImage() {
      let d = new Date()
      let index = Math.floor(d.getSeconds()/(60/this.backgroundImages.length))
      return {
        "background-image": `url(${require(`@/assets/img/backgrounds/${this.backgroundImages[index]}`)})`,
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "height": this.backgroundImageHeight,
        "overflow": "hidden"
      }
    },
    setBackgroundImageHeight() {
      this.backgroundImageHeight = (window.innerHeight - 190) + 'px'
    },
    getStringById(id) {
      let element = document.getElementById(id);
      if(element != null) {
        return element.value;
      } else {
        return "";
      }
    },
    toMfaPage() {
      let data = {
          username: this.username,
          password: this.password,
          csrfToken: this.csrfToken,
          prevPath: this.prevPath,
          mfaStatus: this.mfaStatus,
          mfaImage: this.mfaImage,
      }
      this.setSignInComplete(false);
      this.$router.push({
        path: "/mfapage",
        query: {data: data}
      }).catch(() => {})
      this.stopSpinny();
    },
    isPDFGenerator() {
     return /HeadlessChrome/i.test(navigator.userAgent)
    }
  },
  mounted() {
    this.signMeOut()
    window.addEventListener('resize', () =>
      this.setBackgroundImageHeight()
    )
    this.setBackgroundImageHeight();
    this.csrfToken = this.getStringById("csrf_token");
    this.userToken = this.getStringById("user_token");
    this.key = this.getStringById("key");
  },
  watch: {
    authenticationErrorMessage: 'stopSpinny',
    signInComplete: 'advanceRoute',
    updateCSRF: 'updateCSRFToken',
    toCSRFInvalid: 'goToCSRFInvalid',
    goToCheckEmailFtl: 'toCheckEmailFtl',
    goToCheckEmailFyp: 'toCheckEmailFyp',
    goToPasswordSuccess: 'toPasswordSuccess',
    mfaImage: 'toMfaPage',
    goToSigninPage: 'toSigninPage',
    setUserToken: 'updateUserToken',
  }
};
</script>

<style scoped>

  .text-right {
    text-align: right;
  }

  .form-label {
    display: block;
    margin-bottom: 1em;
  }

  .form-label > .form-control {
    margin-top: 0.5em;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    line-height: 1.5;
    border: 1px solid #ddd;
  }

  .nav-element {

  }

  .v-btn.no-text-transform {
    text-transform:none !important;
  }

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

</style>
