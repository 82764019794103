var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.userData.privileges.proxyAccess || this.userData.id != this.userData.csrep_userid)?_c('div',[_c('v-dialog',{attrs:{"scrollable":"","transition":"dialog-bottom-transition","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-search")]),_c('v-btn',{staticClass:"ml-6 caption info small",attrs:{"elevation":"0","href":_vm.getCsiLink(),"target":"_blank"}},[_vm._v("CSI"),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("\n        mdi-open-in-new\n    ")])],1)]}}],null,false,2443054000),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"data-table-toolbar",attrs:{"dark":"","flat":""}},[_c('h2',[_vm._v("Find A User")])]),_c('v-card-title',[_c('v-text-field',{attrs:{"id":"proxySearch","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticClass:"pa-4",staticStyle:{"height":"430px"}},[_c('div',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.getProxyUserList(),"page":_vm.page,"show-selected":"","loading":_vm.searchLoading,"loading-text":"Loading... Please wait","footer-props":{
                    'items-per-page-options': _vm.itemsPerPageList
                }},on:{"input":_vm.searchForProxyUsers,"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [(item.blocked)?_c('tr',{class:_vm.selectedRow.indexOf(item.userid)>-1?'selected-disabled-blocked-row':'disabled-blocked-row',on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_vm._v(_vm._s(item.username)+" "),_c('em',[_vm._v("(blocked)")])]),_c('td',[_vm._v(_vm._s(item.userid))]),_c('td',[_vm._v(_vm._s(item.priv_group))]),_c('td',[_vm._v(_vm._s(item.title))])]):(item.disabled)?_c('tr',{class:_vm.selectedRow.indexOf(item.userid)>-1?'selected-disabled-blocked-row':'disabled-blocked-row',on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_vm._v(_vm._s(item.username)+" "),_c('em',[_vm._v("(disabled)")])]),_c('td',[_vm._v(_vm._s(item.userid))]),_c('td',[_vm._v(_vm._s(item.priv_group))]),_c('td',[_vm._v(_vm._s(item.title))])]):_c('tr',{class:_vm.selectedRow.indexOf(item.userid)>-1?'selected-row':'normal-row',on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_vm._v(_vm._s(item.username))]),_c('td',[_vm._v(_vm._s(item.userid))]),_c('td',[_vm._v(_vm._s(item.priv_group))]),_c('td',[_vm._v(_vm._s(item.title))])])]}}],null,false,4083493913)})],1),(_vm.proxyErrorMessage != '')?_c('v-alert',{attrs:{"value":true,"type":"error"}},[_vm._v("\n                "+_vm._s(_vm.proxyErrorMessage)+"\n            ")]):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-spacer'),_c('v-btn',{attrs:{"id":"cancelBtn","color":"primary","small":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"id":"proxyBtn","color":"primary","small":"","loading":_vm.proxyLoading,"disabled":this.selectedRow.length < 1},on:{"click":_vm.changeUser}},[_vm._v("Proxy")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }