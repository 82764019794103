import axios from "@/utils/AxiosInstance.js"
import CacheHandler from "@/utils/CacheHandler";
import router from "../../router";
import moment from "moment";
import { store } from "@/store/index.js";

export const coachingDefinitions = {
  namespaced: true,
  state: {

    coachingDefinitionsListItemLoadingErrorStatus: false,
    coachingDefinitionsListItemLoadingError: "",
    coachingDefinitionsListItemLoadingStatus: "notLoading",
    coachingDefinitionsListItem: [],

    coachingDefinitionsListLoadingErrorStatus: false,
    coachingDefinitionsListLoadingError: "",
    coachingDefinitionsListLoadingStatus: "notLoading",
    coachingDefinitionsList: [],

  },
  getters: {
    coachingDefinitionsListItemLoadingErrorStatus: state => state.coachingDefinitionsListItemLoadingErrorStatus,
    coachingDefinitionsListItemLoadingError: state => state.coachingDefinitionsListItemLoadingError,
    coachingDefinitionsListItemLoadingStatus: state => state.coachingDefinitionsListItemLoadingStatus,
    coachingDefinitionsListItem: state => state.coachingDefinitionsListItem,

    coachingDefinitionsListLoadingErrorStatus: state => state.coachingDefinitionsListLoadingErrorStatus,
    coachingDefinitionsListLoadingError: state => state.coachingDefinitionsListLoadingError,
    coachingDefinitionsListLoadingStatus: state => state.coachingDefinitionsListLoadingStatus,
    coachingDefinitionsList: state => state.coachingDefinitionsList,

  },
  mutations: {

    SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR_STATUS(state, status) {
      state.coachingDefinitionsListItemLoadingErrorStatus = status;
    },
    SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR(state, error) {
      state.coachingDefinitionsListItemLoadingError = error;
    },
    SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_STATUS(state, status) {
      state.coachingDefinitionsListItemLoadingStatus = status;
    },
    SET_COACHING_DEFINITIONS_LIST_ITEM(state, coachingDefinitionsListItem) {
      state.coachingDefinitionsListItem = coachingDefinitionsListItem;
    },

    SET_COACHING_DEFINITIONS_LIST_LOADING_ERROR_STATUS(state, status) {
      state.coachingDefinitionsListLoadingErrorStatus = status;
    },
    SET_COACHING_DEFINITIONS_LIST_LOADING_ERROR(state, error) {
      state.coachingDefinitionsListLoadingError = error;
    },
    SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS(state, status) {
      state.coachingDefinitionsListLoadingStatus = status;
    },
    SET_COACHING_DEFINITIONS_LIST(state, coachingDefinitionsList) {
      state.coachingDefinitionsList = coachingDefinitionsList;
    },

    UPDATE_COACHING_DEFINITIONS_LIST_ITEM(state, definition) {
      for (const obj of state.coachingDefinitionsList) {
        if (obj.id === definition.id) {
          obj.title = definition.title
          obj.description = definition.description
          obj.status = definition.status
          obj.scorecards = definition.scorecards
          obj.draft = definition.draft
          obj.published = definition.published
          obj.media_type = definition.media_type
          break;
        }
      }
    },

  },
  actions: {

     // save draft
     updateCoachingDefinition(context, definition) {

        //in case the original key exists without a timestamp...
        CacheHandler.removeKeyIfExists('coaching_definition_' + String(definition.id))
  
        let key = 'coaching_definition_' + String(definition.id) + '.' + moment().unix()
        let oldKey = CacheHandler.setCacheKeyFromStore(definition.id, 'coaching_definition_');
  
        definition.draft = true
  
        // remove the draft copy, if it exists
        CacheHandler.removeKeyIfExists(oldKey)
  
        // save it
        CacheHandler.setItem(key, JSON.stringify(definition))
  
        context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM", definition);
        context.commit("UPDATE_COACHING_DEFINITIONS_LIST_ITEM", definition);
  
      },

    retrieveCoachingDefinitionsList(context) {
        context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", "loading")
        context.commit("SET_COACHING_DEFINITIONS_LIST", [])
        // initialize empty list
        let coaching_draft_list = []
        let coaching_not_draft_list = []

        // get local drafts
        let draft_keys = []
        let coaching_def = {}
        for (const property in localStorage) {
            let userId = store.getters["users/userData"].id
            if (property.slice(0, property.lastIndexOf("_") + 1) == userId + '_coaching_definition_') {
            //check to see if the key has expired
            if (CacheHandler.isExpired(property.substring(property.indexOf("_") + 1))) continue;
                coaching_def = JSON.parse(CacheHandler.getItem(property.slice(property.indexOf("_") + 1)))
                coaching_def.draft = true
                coaching_draft_list.push(coaching_def)
                draft_keys.push(Number(coaching_def.id))
            }
        }
        coaching_draft_list.sort((a, b) => a.title > b.title && 1 || -1)

      axios
        .get(process.env.VUE_APP_COACHING_DEFINITIONS)
        .then(response => {
          let coachingDefinitionList = response.data.definitions

          // get non drafts
          coaching_not_draft_list =
          coachingDefinitionList.filter(label => !(draft_keys.includes(label.id))).map(obj => ({
              "id": obj.id,
              "user_id": obj.user_id,
              "title": obj.title,
              "description": obj.description,
              "status": obj.status,
              "scorecards": obj.scorecards,
              "created_at": obj.created_at,
              "updated_at": obj.updated_at,
              "media_type": obj.media_type,
              "draft": false,
              "published": true
            }))

          // pack the final list of drafts and non drafts and sort
          let final_list = coaching_draft_list.concat(coaching_not_draft_list)
          final_list.sort((a, b) => a.title > b.title && 1 || -1)

          context.commit("SET_COACHING_DEFINITIONS_LIST", final_list)
          context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", false)
          context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_ERROR", "")
          context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", "loaded")
        })
        .catch(function (error) {

          context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", "failed");
          context.commit("SET_COACHING_DEFINITIONS_LIST", []);
          context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_ERROR", 'retrieveCoachingDefinitionsList(): ' + error_message)
        })
    },

    retrieveCoachingEditorListItem(context, coaching_id) {

        let key = CacheHandler.setCacheKeyFromStore(coaching_id, 'coaching_definition_')

        //check original key so we do not ruin current drafts a user might have...
        if (CacheHandler.getItem("coaching_definition_" + coaching_id) !== null) {
            let coaching_def = JSON.parse(CacheHandler.getItem("coaching_definition_" + coaching_id))
            context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM", coaching_def);
            context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_STATUS", "loaded");
            context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR", '');
            context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR_STATUS", false);
        //check new drafts with a timestamp on the key
        } else if (key != null && CacheHandler.getItem(key) !== null) {
            let coaching_def = JSON.parse(CacheHandler.getItem(key))
            context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM", coaching_def);
            context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_STATUS", "loaded");
            context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR", '');
            context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR_STATUS", false);
        } else {
            if (context.getters.coachingDefinitionsListItem.published == undefined || context.getters.coachingDefinitionsListItem.published != false) {
                axios
                .get(process.env.VUE_APP_COACHING_DEFINITIONS + coaching_id)
                .then(response => {
                    context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM", response.data.definition);
                    context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR_STATUS", false);
                    context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR", "");
                    context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_STATUS", "loaded");
                })
                .catch(function (error) {
                    context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_STATUS", "failed");
                    context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM", {});
                    context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR_STATUS", true);
                    let error_message = JSON.stringify(error)
                    if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
                    if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
                    context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_ERROR", 'retrieveCoachingEditorListItem(): ' + error_message)
                });
            }
        }
    },
    cancelCoachingDefinitionEdits(context, coachingId) {
        //just in case the original key exists
        CacheHandler.removeKeyIfExists('coaching_definition_' + String(coachingId))
  
        // get a reference to the draft
        let key = CacheHandler.setCacheKeyFromStore(coachingId, 'coaching_definition_');
        
        // remove the draft
        CacheHandler.removeKeyIfExists(key)
  
    },
    setCoachingListLoadingStatus(context, status) {
      context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", status)
      if (status=='loading') {
        context.commit("SET_COACHING_DEFINITIONS_LIST", [])
      }
    },
    deleteCoachingDefinition(context, coaching_id) {
        let key = CacheHandler.setCacheKeyFromStore(coaching_id, 'coaching_definition_')
        CacheHandler.removeKeyIfExists(key)

        context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM", "loading")
        context.commit("SET_COACHING_DEFINITIONS_LIST", "loading")
  
        axios
          .delete(process.env.VUE_APP_COACHING_DEFINITIONS + coaching_id)
          .then(() => {
            // delete the object from localStorage if it exists
            context.dispatch('retrieveCoachingDefinitionsList')
          })
          .catch(function (error) {
            context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", "failed");
            context.commit("SET_COACHING_DEFINITIONS_LIST", {});
            context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);
            let error_message = JSON.stringify(error)
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
            context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", 'deleteCoachingDefinition(): ' + error_message)
          });
    },
    publishCoachingDefinition(context, params) {

        // check if published is false
        let key = CacheHandler.setCacheKeyFromStore(params.id, 'coaching_definition_')
        let originalKey = 'coaching_definition_' + String(params.id)
        if (!params.published) {
          axios
            .post(process.env.VUE_APP_COACHING_DEFINITIONS, params)
            .then(response => {
              if (response !== undefined) {
                if (response.status >= 400) {
                  alert('The new coaching definition was not saved. The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
                } else {
                    let coachingDefinitionsList = context.getters.coachingDefinitionsList
                    for (let i = 0; i < coachingDefinitionsList.length; i++) {
                        if (!coachingDefinitionsList[i].published) coachingDefinitionsList[i].published = true
                    }

                    CacheHandler.removeKeyIfExists(key)
                    CacheHandler.removeKeyIfExists(originalKey)
                    router.replace('/setup/coaching-definitions/' + response.data.definition_id).catch(err => { })
                }
              }
            }).catch(function (error) {
                context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", "failed");
                context.commit("SET_COACHING_DEFINITIONS_LIST", []);
                context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);
           
                let error_message = JSON.stringify(error)
                if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
                if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`

                context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", 'publishCoachingDefinition(): ' + error_message)
                CacheHandler.removeKeyIfExists(key)
                CacheHandler.removeKeyIfExists(originalKey)
            });
        } else {
          axios
            .put(process.env.VUE_APP_COACHING_DEFINITIONS + params.id, params)
            .then((response) => {
                CacheHandler.removeKeyIfExists(key)
                CacheHandler.removeKeyIfExists(originalKey)
                context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", "loading")
                context.dispatch('retrieveCoachingDefinitionsList')
            }).catch(function (error) {
                context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", "failed");
                context.commit("SET_COACHING_DEFINITIONS_LIST", []);
                context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);
           
                let error_message = JSON.stringify(error)
                if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
                if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`

                context.commit("SET_COACHING_DEFINITIONS_LIST_LOADING_STATUS", 'publishCoachingDefinition(): ' + error_message)

                CacheHandler.removeKeyIfExists(key)
                CacheHandler.removeKeyIfExists(originalKey)
            });
        }
      },
 
    cloneCoachingDefinition(context, params) {

        let coachingDefinitionsList = context.getters.coachingDefinitionsList
        let definition = Object.assign({}, params.definition)
        let definitionTitle = definition.title
  
        //get titles from definition list - taken from backend
        let titles = []
        coachingDefinitionsList.forEach((definition, index) => {
          titles.push(definition.title)
        })
  
        for (let idx = 1; idx <= 100; idx++) {
          // figure out clone number
          definitionTitle = definitionTitle + ' - clone' + (idx == 1 ? '' : ' ' + idx);
          if (!titles.includes(definitionTitle)) {
              break;
          }
        }
  
        definition.id = params.id
        definition.published = false
        definition.draft = true
        definition.user_id = params.user_id
        definition.title = definitionTitle
        definition.created_at = moment().format('YYYY-MM-D hh:mm:ss')
        definition.updated_at = moment().format('YYYY-MM-D hh:mm:ss')
        coachingDefinitionsList.push(definition)
        coachingDefinitionsList.sort((a, b) => a.title > b.title && 1 || -1)
  
        // store it locally
        CacheHandler.setItem('coaching_definition_' + params.id + '.' + moment().unix(), JSON.stringify(definition))
        context.commit("SET_COACHING_DEFINITIONS_LIST", coachingDefinitionsList);
        context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM", definition);
        context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_STATUS", "loading");
    },
    createNewCoachingDefinition(context, params) {

      // make an axios call to create a new "empty" access role
      let coachingDefinitionsList = context.getters.coachingDefinitionsList
      let newLabelCount = coachingDefinitionsList.filter(label => label.title.slice(0, 23) == "New Coaching Definition").length
      let titleSuffix = (newLabelCount > 0) ? " (copy " + (newLabelCount + 1) + ")" : ""
      let did = Date.now()
      let ISO = (timeStamp = did) => { return new Date(timeStamp - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T') }
      let dt = ISO()
      let coachingDefinition = {
        id: params.id,
        scorecards: [],
        user_id: params.user_id,
        status: 1,
        published: false,
        title: "New Coaching Definition" + titleSuffix,
        description: "",
        media_type: "Call Recording",
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }

      coachingDefinitionsList.push(coachingDefinition)
      coachingDefinitionsList = coachingDefinitionsList.sort((a, b) => a.title > b.title && 1 || -1)

      CacheHandler.setItem('coaching_definition_' + params.id + '.' + moment().unix(), JSON.stringify(coachingDefinition))

      context.commit("SET_COACHING_DEFINITIONS_LIST", coachingDefinitionsList);
      context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM", coachingDefinition);
      context.commit("SET_COACHING_DEFINITIONS_LIST_ITEM_LOADING_STATUS", "loading");
    }
  }
}
