export default {
  methods: {
    regexUrlCheck(content) {
      //regex taken from: https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/
      //the regex is referenced here: https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url

      //   strings that will pass

      //   http://www.foufos.gr
      //   https://www.foufos.gr
      //   http://foufos.gr
      //   http://www.foufos.gr/kino
      //   http://werer.gr
      //   www.foufos.gr
      //   www.mp3.com
      //   www.t.co
      //   http://t.co
      //   http://www.t.co
      //   https://www.t.co
      //   www.aa.com
      //   http://aa.com
      //   http://www.aa.com
      //   https://www.aa.com

      //   strings that will fail

      //   www.foufos
      //   www.foufos-.gr
      //   www.-foufos.gr
      //   foufos.gr
      //   http://www.foufos
      //   http://foufos
      //   www.mp3#.com

      //   ex LF url: https://apps.usw2.pure.cloud/screen-recording-ui/#/interactions/bf2f2dd9-d6b1-4ea6-8a81-fea11a75d76f/screen-recordings?timezone=America%2FLos_Angeles

      //original regex: (https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})

      //altered regex to match on the entire string
      return /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i.test(
        content
      );
    },
    checkValueProtocol(value) {
      return (value.startsWith("http://") || value.startsWith("https://")) ? value : "https://" + value
    }
  }
};
