<template>
  <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
    <v-row>
      <v-col
        cols="11"
        class="blue--text text--darken-4 py-0 ml-1 mr-3"
        align-self="end"
      >
        {{ description }}
      </v-col>
      <v-col class="pa-0" style="text-align: right;">
        <v-btn
          fab
          small
          dark
          color="primary"
          href="/setup-faq/1"
          target="_blank"
          title="Click for Setup Help and FAQs"
        >
          <v-icon dark>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <span v-if="isExpanded">
        <v-col class="hintText py-0 ml-1 mr-3">
          {{ expandMessage }}
        </v-col>
      </span>
    </v-row>
    <v-row>
      <v-col class="pt-0 ml-1 mr-3">
        <v-btn text x-small color="primary" @click="toggleHints">
          <span v-if="isExpanded">
            hide example
            <v-icon small right>mdi-chevron-up</v-icon>
          </span>
          <span v-else>
            show example
            <v-icon small right>mdi-chevron-down</v-icon>
          </span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="okToRender">
      <v-col cols="6">
        <v-text-field
          id="accessRoleNameTxt"
          ref="titleField"
          v-model="accessRoleInfo.title"
          outlined
          counter
          :disabled="textFieldsDisabled"
          :maxlength="maxNameLength"
          hint=" Enter a name which categorizes a specific group of users and be easily identifiable when you create insights and scorecards. <br />
                  The access role name is only visible to you as the advanced user."
          label="Access Role Name"
          placeholder="Enter your access role name"
          :rules="titleRules"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          id="accessDescriptionTxt"
          v-model="accessRoleInfo.description"
          outlined
          counter
          :disabled="textFieldsDisabled"
          :maxlength="maxDescriptionLength"
          hint="(Optional) Enter a brief description to represent your access role."
          label="Description"
          placeholder="Describe your access role"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- access role definition -->
    <v-row v-if="okToRender">
      <!-- access role component -->
      <v-col cols="12" class="d-flex flex-column">
        <access-role-definition
          :userInfo="users"
          :cancelEdits="cancelAll"
          @updateUserSelections="updateUserSelections"
          @setInfo="setInfo"
          @updateInfo="updateRole"
          @updatesCancelled="updatesCancelled"
          :accessRoleId="Number(accessRoleId)"
        ></access-role-definition>
      </v-col>
    </v-row>

    <!-- buttons for saving, deleting, etc. - the visual layout is guided by UI/UX eye-tracking research -->
    <v-row v-if="okToRender">
      <v-col cols="12" class="d-flex">
        <v-btn
          id="publishBtn"
          color="primary"
          class="ma-2"
          :disabled="canPublish"
          @click="publishRole"
          >Publish</v-btn
        >
        <v-btn
          v-if="!usingModal"
          id="cloneBtn"
          color="blue-grey"
          class="ma-2 white--text"
          :disabled="canCloneOrDelete"
          @click="cloneRole"
          >Clone</v-btn
        >
        <v-btn
          id="cancelBtn"
          color="normal"
          class="ma-2"
          :disabled="canCancel"
          @click="cancelEdits"
          >Cancel Edits</v-btn
        >
        <div class="d-inline caption mt-4 ml-4">
          Created: {{ formatDate(accessRoleInfo.created_at) }} &mdash; Updated:
          {{ formatDate(accessRoleInfo.updated_at) }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!usingModal"
          id="deleteBtn"
          color="error"
          class="ma-2"
          :disabled="canDelete || deleteDisabled"
          @click="deleteRole"
          >Delete</v-btn
        >

        <h5
          class="d-inline  mt-4 ml-4 footnote"
          v-if="usingModal && !canPublish && insightsImpacted.length > 0"
        >
          <sup class="footnote">*</sup>These insight definitions will be
          affected by your change:
          {{ formatAffectedInsights(insightsImpacted) }}
        </h5>
      </v-col>
    </v-row>
    <v-row v-if="okToRender" class="mr-2">
      <v-spacer></v-spacer>
      <div v-if="deleteDisabled">
        <delete-disabled
          :title="'Access Role'"
          :problemOneTitle="'Scorecard Definitions'"
          :problemTwoTitle="'Insight Definitions'"
          :problemOneList="associatedScorecards"
          :problemTwoList="associatedInsights"
        ></delete-disabled>
      </div>
    </v-row>
    <confirm id="confirmBtn" ref="confirm"></confirm>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import accessRoleDefinition from "@/components/AccessRoles/AccessRoleDefinition.vue";
import confirm from "@/components/Widgets/Confirm.vue";
import DefinitionLoadingStatus from "./DefinitionLoadingStatus.vue";
import DeleteDisabled from "../../components/Widgets/DeleteDisabled.vue";
import moment from "moment";

export default {
  name: "AccessRolesPage",
  props: {
    accessRoleId: String,
    description: String,
    expandMessage: String,
    usingModal: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    textFieldsDisabled() {
      return (
        this.accessRolesListLoadingStatus != "loaded" ||
        this.accessRolesListLoadingStatus != "loaded" ||
        this.accessRoleDataLoadingStatus != "loaded"
      );
    },
    ...mapGetters("accessRoles", [
      "accessRolesList",
      "accessRolesListLoadingStatus",
      "accessRolesListLoadingError",
      "accessRolesListLoadingErrorStatus",
      "accessRoleData",
      "accessRoleDataLoadingStatus",
      "accessRoleDataLoadingError",
      "accessRoleDataLoadingErrorStatus",
      "authorizedUserList",
      "authorizedUserListLoadingStatus",
      "authorizedUserListLoadingError",
      "authorizedUserListLoadingErrorStatus",

      "associatedInsights",
      "associatedScorecards"
    ]),
    ...mapGetters("insights", ["insightsDefinitionsList"]),
    ...mapGetters("users", [
      "userDataListLoadingStatus",
      "userDataListLoadingError",
      "userDataListLoadingErrorStatus",
      "userDataList",
      "userData",
      "userDataLoadingStatus"
    ]),
    titleFieldIsValid() {
      if (this.isMounted) return !this.$refs.titleField.hasError;
      else return false;
    },
    canDelete() {
      return (!this.accessRoleInfo.published && this.accessRoleInfo.cancel);
    },
    deleteDisabled() {
      return (
        this.associatedInsights.length > 0 ||
        this.associatedScorecards.length > 0
      );
    },
    okToRender() {
      return Boolean(
        typeof this.accessRoleInfo === "object" && this.accessRoleInfo !== null
      );
    },
    canCloneOrDelete() {
      return !(
        this.accessRoleInfo.published &&
        this.usersSelected.length > 0 &&
        !this.accessRoleInfo.cancel
      );
    },
    canCancel() {
      return this.accessRoleInfo.published && !this.accessRoleInfo.cancel;
    },
    canPublish() {
      return !(
        this.accessRoleInfo.cancel &&
        this.titleFieldIsValid &&
        this.usersSelected.length > 0
      );
    }
  },
  data() {
    return {
      isExpanded: false,
      accessRoleInfo: {},
      accessRoleInfoOrig: {},
      maxNameLength: 120,
      maxDescriptionLength: 255,
      usersSelected: [],
      users: [],
      cancelAll: false,
      insightsImpacted: [],
      newId: false,
      publishing: false,
      insightIds: [],
      titleRules: [
        value => !!value || "A unique name is required.",
        value => {
          if (
            typeof this.accessRoleInfo === "object" &&
            this.accessRoleInfo.title != undefined
          ) {
            return (
              !this.accessRolesList.some(item => {
                return (
                  item.id != this.accessRoleId &&
                  item.title.trim().toUpperCase() == value.trim().toUpperCase()
                );
              }) || "Names must be unique."
            );
          } else return "";
        }
      ],
      isMounted: false
    };
  },
  components: {
    accessRoleDefinition,
    confirm,
    DefinitionLoadingStatus,
    DeleteDisabled
  },
  methods: {
    ...mapActions({
      retrieveAccessRolesList: "accessRoles/retrieveAccessRolesList",
      retrieveAuthorizedUserListData: "users/retrieveAuthorizedUserListData",
      retrieveAccessRoleData: "accessRoles/retrieveAccessRoleData",
      publishAccessRole: "accessRoles/publishAccessRole",
      cloneAccessRole: "accessRoles/cloneAccessRole",
      deleteAccessRole: "accessRoles/deleteAccessRole",
      updateAccessRole: "accessRoles/updateAccessRole",
    }),
    toggleHints() {
      this.isExpanded = !this.isExpanded;
    },
    formatDate(time) {
      return moment(time).format("l LT");
    },
    setUsers() {
      this.newId = true;
      this.setInfo();
      this.users = this.accessRoleInfo.users;
    },
    setInfo() {
      this.accessRoleInfo = this.accessRolesList.find(accessRole => {
        return String(accessRole.id) == String(this.accessRoleId);
      });
    },
    formatAffectedInsights(impacted) {
      return impacted.join(", ");
    },
    getAffectedInsights() {
      let insightDefinitionId = this.$route.params.insightDefinitionId;
      for (let i = 0; i < this.insightsDefinitionsList.length; i++) {
        if (this.insightsDefinitionsList[i].id != insightDefinitionId) {
          for (
            let x = 0;
            x < this.insightsDefinitionsList[i].accessroles.length;
            x++
          ) {
            let accessRole = this.insightsDefinitionsList[i].accessroles[x];
            if (accessRole.accessrole_id == this.accessRoleId) {
              if (
                !this.insightIds.includes(this.insightsDefinitionsList[i].id)
              ) {
                this.insightIds.push(this.insightsDefinitionsList[i].id);
                this.insightsImpacted.push(
                  this.insightsDefinitionsList[i].title +
                    " (" +
                    this.insightsDefinitionsList[i].id +
                    ")"
                );
              }
            }
          }
        }
      }
    },
    updateUserSelections(users) {
      this.usersSelected = users.map(x => x.id);
    },
    updatesCancelled() {
      this.cancelAll = false;
    },
    publishRole() {
      let params = this.accessRoleInfo;
      params.users = this.usersSelected;
      this.publishAccessRole(params);
      this.publishing = true;
    },
    cloneRole() {
      this.$emit("updateAccessRoleObject", false);
      let params = {
        user_id: this.userData.id,
        id: this.accessRoleInfo.id,
        users: this.accessRoleInfo.users
      };
      this.cloneAccessRole(params);
    },
    async cancelEdits() {
      if (
        await this.$refs.confirm.open(
          "Cancel Edits?",
          "About to cancel edits and/or remove draft. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        // yes
        this.cancelAll = true;
        let published = this.accessRoleInfo.published;
        let id = this.accessRoleInfo.id;

        if (!published) {
          this.$emit("updateAccessRoleObject", false);
          this.$router.push({ path: "/setup/access-roles/" }).catch(err => {});
          setTimeout(function() {
            if (this.$vuetify) this.$vuetify.goTo(0);
          }, 1500);
        } else {
          this.publishing = false;
          this.retrieveAccessRolesList();
          this.$emit("openMenu", "accessRoles");
          this.$emit("selectTarget", { type: "accessRoles", id: id });
        }
      }
    },
    updateRole() {
      this.updateAccessRole(this.accessRoleInfo);
    },
    checkForChanges() {
      if (!this.publishing) {
        if (
          !(
            JSON.stringify(this.accessRoleInfo) ==
            JSON.stringify(this.accessRoleInfoOrig)
          )
        ) {
          this.updateRole();
        }
      }
    },
    checkForChangesInData() {
      if (this.accessRoleInfo.title != undefined) {
        if (
          this.accessRoleInfo.title != this.accessRoleData.title ||
          this.accessRoleInfo.description != this.accessRoleData.description
        ) {
          this.accessRoleInfo = Object.assign({}, this.accessRoleData);
        }
      }
    },
    async deleteRole() {
      if (
        await this.$refs.confirm.open(
          "Delete this access role",
          "This action cannot be undone. Are you sure you want to delete?",
          { color: "red" }
        )
      ) {
        this.deleteAccessRole(this.accessRoleInfo.id);
        this.$router.push({ path: "/setup/access-roles" }).catch(err => {});
        setTimeout(function() {
          if (this.$vuetify) this.$vuetify.goTo(0);
        }, 1500);
      }
    }
  },
  watch: {
    accessRolesList: "setInfo",
    accessRoleId: "setUsers"
  },
  beforeUpdate() {
    if (this.newId) {
      if (typeof this.accessRoleInfo === "object")
        this.accessRoleInfoOrig = JSON.parse(
          JSON.stringify(this.accessRoleInfo)
        );
    }
    this.checkForChanges();
    this.users = this.accessRoleInfo.users;
  },
  updated() {
    this.newId = false;
    this.publishing = false;
    this.setInfo();
  },
  mounted() {
    this.isMounted = true;
    this.checkForChangesInData();
    this.retrieveAuthorizedUserListData(this.userData.id);
    this.setInfo();
    if (typeof this.accessRoleInfo === "object")
      this.accessRoleInfoOrig = JSON.parse(JSON.stringify(this.accessRoleInfo));
    this.setUsers();
    this.newId = false;

    if (!this.usingModal) {
      this.$emit("openMenu", "accessRoles");
      this.$emit("selectTarget", {
        type: "accessRoles",
        id: this.accessRoleId,
        preventProp: true
      });
    } else {
      this.getAffectedInsights();
    }
  }
};
</script>

<style scoped>
sup.footnote {
  vertical-align: baseline;
  position: relative;
  top: 0rem;
  font-size: 1rem;
  color: rgba(179, 63, 38, 1);
}

h5.footnote sup.footnote {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
}
.hintText {
  color: rgb(25, 118, 210);
  font-size: 0.875rem;
  line-height: 1.5rem !important;
  padding-left: 1.3rem !important;
}
</style>
